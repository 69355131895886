import { createStyles, makeStyles } from '@material-ui/core';
import { awLightPurple, darkBlue } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  addSection: {
    fontSize: '18px',
    color: darkBlue,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontFamily: 'Poppins-Regular',
    marginTop: '15px',
    paddingTop: '0px',
    border: '0px',
    backgroundColor: awLightPurple,
    position: 'relative',
    bottom: '8px',
    cursor: 'pointer',
    display: 'inline-block',
    right: '6px',
    width: '250px',
  },
  addIcon: {
    float: 'left',
    fontSize: '26px',
    color: darkBlue,
    fontWeight: 'bold',
    fontStyle: 'normal',
    marginRight: '7px',
    marginBottom: '1px',
  },
  buttonText: {
    float: 'left',
  },
  modal: {
    display: 'flex',
    width: '540px',
    height: '150px',
    padding: '10px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  modalButtonPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: '33%',
  },
  input: {
    height: '0.9rem',
    paddingBottom: '0.5rem',
  },
  buttonContainer: {
    width: '150px',
  },
}));

export default useStyles;
