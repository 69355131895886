/* eslint-disable max-len */
import React, { createContext, useState, useMemo } from 'react';
import { useAuth, useFetch, usePathVariable } from 'src/common/customHooks';
import ICompanyEmployee from 'src/common/interfaces/ICompanyEmployee';
import ICompanyProfile from 'src/common/interfaces/ICompanyProfile';
import IEditCompanyInfo from 'src/common/interfaces/IEditCompanyInfo';
import IInviteMember from 'src/common/interfaces/IInviteMember';
import { fetchCompanyProfile, getEmployeeInfo } from 'src/utils/apiUtils';

interface ICompanyContext {
  ifOpenInviteMemberPanel: boolean
  setIfOpenInviteMemberPanel:React.Dispatch<React.SetStateAction<boolean>>
  isEditPanelOpen: boolean
  setEditPanelOpen:React.Dispatch<React.SetStateAction<boolean>>
  inviteMemberInfo:IInviteMember
  setInviteMemberInfo: React.Dispatch<React.SetStateAction<{
    name: string;
    email: string;
    title: string;
  }>>
  companyInfo: ICompanyProfile
  editCompanyInfo: IEditCompanyInfo
  setEditCompanyInfo: React.Dispatch<React.SetStateAction<{
    companyId: string,
    name: string,
    description: string,
  }>>
  companyId:string;
  companyName: string
  setCompanyName:React.Dispatch<React.SetStateAction<string>>
  companyDescription: string
  setCompanyDescription:React.Dispatch<React.SetStateAction<string>>
  companyEmployees: ICompanyEmployee[]
  isCompanyManager:boolean
}

export const CompanyContext = createContext({} as ICompanyContext);
export function CompanyContextProvider({ children }: { children: JSX.Element[] | JSX.Element }) {
  const [ifOpenInviteMemberPanel, setIfOpenInviteMemberPanel] = useState(false);
  const [isEditPanelOpen, setEditPanelOpen] = useState(false);

  const [inviteMemberInfo, setInviteMemberInfo] = useState({
    name: '',
    email: '',
    title: '',
  });

  const [editCompanyInfo, setEditCompanyInfo] = useState({
    companyId: '',
    name: '',
    description: '',
  });

  const [companyName, setCompanyName] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const { isCompanyManager } = useAuth();
  const { companyId } = usePathVariable();
  const { data: companyEmployees } = useFetch(getEmployeeInfo, [companyId], [] as ICompanyEmployee[]);
  const { data: companyInfo } = useFetch(fetchCompanyProfile, [companyId], {} as ICompanyProfile);

  const companyContext = useMemo(
    () => ({
      companyInfo,
      editCompanyInfo,
      setEditCompanyInfo,
      ifOpenInviteMemberPanel,
      setIfOpenInviteMemberPanel,
      inviteMemberInfo,
      setInviteMemberInfo,
      isCompanyManager,
      companyId,
      companyName,
      setCompanyName,
      companyDescription,
      setCompanyDescription,
      companyEmployees,
      isEditPanelOpen,
      setEditPanelOpen,
    }),
    [companyInfo, editCompanyInfo, setEditCompanyInfo,
      ifOpenInviteMemberPanel, setIfOpenInviteMemberPanel,
      inviteMemberInfo, setInviteMemberInfo,
      isCompanyManager, companyId,
      companyName, setCompanyName,
      companyDescription, setCompanyDescription,
      companyEmployees, isEditPanelOpen, setEditPanelOpen],
  );

  return (
    <CompanyContext.Provider value={companyContext}>
      {children}
    </CompanyContext.Provider>
  );
}
