import React, { useEffect, useContext, useState } from 'react';
import { usePathVariable } from 'src/common/customHooks';
import Header from 'src/components/Header/Header';
import { CompanyContext } from 'src/context/CompanyContext';
import { getCompanyInfo } from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';
import ICompanyProfile from 'src/common/interfaces/ICompanyProfile';

function CompanyPageHeader() {
  const { companyId } = usePathVariable();
  const { companyName, setCompanyName, setCompanyDescription } = useContext(CompanyContext);

  const [companyInfo, setCompanyInfo] = useState<ICompanyProfile>({
    companyId: '',
    name: '',
    description: '',
  });

  const fetchCompanyInfo = async () => {
    const { status, data } = await getCompanyInfo(companyId);
    if (status === StatusCodes.OK) {
      setCompanyInfo(data);
    }
  };
  const { name, description } = companyInfo;
  useEffect(() => {
    fetchCompanyInfo();
    setCompanyName(name);
    setCompanyDescription(description);
  }, [name, description]);

  return <Header title={companyName} isCompanyPage isInnerProjectPage={false} otherProjects={[]} />;
}

export default CompanyPageHeader;
