import React from 'react';
import useStyles from './PopupWindow.style';
import CreateNewProjectPopup from './CreateNewProjectPopup/CreateNewProjectPopup';
import CreateBlankProjectPopup from './CreateBlankProjectPopup/CreateBlankProjectPopup';

interface PopupWindowProps {
  toggleDisplayPopup: boolean;
  togglePopup: any;
  showBlankProject: boolean;
  toggleShowBlankProject: any;
}

function PopupWindow({ toggleDisplayPopup, togglePopup, showBlankProject, toggleShowBlankProject }: PopupWindowProps) {
  const classes = useStyles();

  return (
    <div className={toggleDisplayPopup ? classes.popup_container_display : classes.content_hidden}>
      <div className={classes.popup}>
        <CreateNewProjectPopup
          showBlankProject={showBlankProject}
          togglePopup={togglePopup}
          toggleShowBlankProject={toggleShowBlankProject}
        />

        <CreateBlankProjectPopup
          showBlankProject={showBlankProject}
          toggleShowBlankProject={toggleShowBlankProject}
          togglePopup={togglePopup}
        />
      </div>
    </div>
  );
}

export default PopupWindow;
