import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import Loading from 'src/components/Loading/Loading';
import IContributionActivities from 'src/common/interfaces/Dto/Todo/TodoItem/IContributionActivities';
import useStyles from './ContributionActivities.style';
import ContributionActivity from './components/ContributionActivity/ContributionActivity';

const convertDataToMap = (data: { [key: string]: IContributionActivities }) => {
  const contributionTodoItems = new Map();
  if (data) {
    Object.entries(data).forEach(([key, value]) => {
      const AbbrKey = key.charAt(0) + key.substring(1, 3).toLowerCase();
      contributionTodoItems.set(AbbrKey, value);
    });
  }
  return contributionTodoItems;
};

function ContributionActivities({ contributionTodoItemsListData, loading, chooseDay }: any) {
  const contributionTodoItemsListDataMap = useMemo(() => convertDataToMap(contributionTodoItemsListData), [
    contributionTodoItemsListData,
  ]);
  const taskArray = contributionTodoItemsListDataMap.get(chooseDay);
  const classes = useStyles();
  return (
    <Box className={loading ? classes.emptyContainer : classes.container}>
      {loading && <Loading />}
      <h2 className={classes.title}>Contribution activity</h2>
      <ContributionActivity taskArray={taskArray} />
    </Box>
  );
}

export default ContributionActivities;
