import React from 'react';
import logo from 'src/assets/svgs/logoWithoutTexts.svg';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import useStyles from './ForbiddenPage.style';

function ForbiddenPage() {
  const styles = useStyles();
  const history = useHistory();
  const handleClick = () => {
    history.push(`/companies/${localStorage.getItem('companyId')}`);
  };

  return (
    <>
      <div className={styles.logoBox}><img src={logo} className="img" alt="logo" /></div>
      <div className={styles.forbiddenContainner}>
        <div className={styles.forbiddenBackImg} />
        <div className={styles.forbInfoLine1}>Oops... You don&apos;t have access permissions</div>
        <div className={styles.forbInfoLine2}>
          Failed to access this page, please contact your adminstrator for permission
        </div>
        <div className={styles.backBbuttonBox}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={styles.backBbutton}
            onClick={handleClick}
            data-testid="test-backBtn"

          >
            Back to Dashboard
          </Button>
        </div>
      </div>
    </>
  );
}

export default ForbiddenPage;
