import * as React from 'react';
import Avatar, { ConfigProvider } from 'react-avatar';

interface IUserProfileIcon {
  name: any
  size: string
  textSizeRatio: number
}

function UserProfileIcon({
  name,
  size,
  textSizeRatio,
}: IUserProfileIcon) {
  // @ts-ignore
  const avatar = <Avatar maxInitials={3} name={name} round size={size} textSizeRatio={textSizeRatio} />;
  return (
    <ConfigProvider>
      { avatar }
    </ConfigProvider>
  );
}

export default UserProfileIcon;
