import React, { useContext } from 'react';
import moment from 'moment';
import { useFetch } from 'src/common/customHooks';
import { ScheduleContext } from 'src/context/ScheduleContext';
import IEvent from 'src/common/interfaces/Dto/Event/IEvent';
import Loading from 'src/components/Loading/Loading';
import { getEventList } from 'src/utils/apiUtils';

import useStyles from '../../Schedules.style';

function EventList() {
  const classes = useStyles();
  const { companyId, projectId, targetDate, listLatestUpdatedTime } = useContext(ScheduleContext);
  const currentDate = moment(targetDate);
  const startOfDay = currentDate.startOf('day').toISOString();

  const { data: eventItems, loading } = useFetch(
    getEventList,
    [companyId, projectId, startOfDay],
    [] as IEvent[],
    [companyId, projectId, startOfDay, listLatestUpdatedTime],
  );

  return (
    loading
      ? (<Loading />)
      : (
        <div className={classes.eventListContainer}>
          <div className={classes.eventTime}>{currentDate.format('ddd, MMM D')}</div>
          {
            eventItems?.length
              ? (
                <ul>
                  {eventItems.map((event) => <li key={event.id}>{event.title}</li>)}
                </ul>
              )
              : (<div className={classes.eventContext}>Nothing is on the schedule</div>)
          }
        </div>
      )
  );
}
export default EventList;
