import React from 'react';
import { Typography, Grid, Hidden } from '@material-ui/core';
import { Link } from 'react-router-dom';
import resetPassword from 'src/assets/svgs/resetPassword.svg';
import useStyles from './PasswordResetPage.style';
import logo from '../../assets/svgs/logoWithoutTexts.svg';
import Footer from '../Footer/Footer';
import PasswordResetForm from './components/PasswordResetForm';

function PasswordResetPage() {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      className={classes.background}
    >
      <Grid item md={5} className={classes.form}>
        <Link to="/">
          <img src={logo} className="img" alt="logo" />
        </Link>
        <Typography className={classes.title}>
          Set a password
        </Typography>
        <PasswordResetForm />
        <Footer isPublicPage />
      </Grid>
      <Hidden smDown>
        <Grid item md={7} container className={classes.img_container}>
          <img src={resetPassword} alt="resetPassword" className={classes.background_img} />
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default PasswordResetPage;
