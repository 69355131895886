/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Button,
  Typography,
  SvgIcon,
  IconButton,
  InputLabel,
  FormControl,
  FormHelperText,
  Dialog,
} from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { Link } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { backButton } from 'src/assets/buttons';
import { useCode } from 'src/common/customHooks';
import passwordDialog from 'src/assets/svgs/passwordDialog.svg';
import WarningMessage from 'src/components/Login/components/WarningMessage/WarningMessage';
import useStyles from './PasswordResetForm.style';
import formConfig from './config';
import { usePasswordResetForm, useExtraFormConf, useInputControl } from './hooks';

function PasswordRecoveryForm() {
  const classes = useStyles();
  const { code } = useCode();

  const { initialValues, validationPasswordSchema, handleOnSubmit, warning, opened, history } = usePasswordResetForm(code);
  const { getErrorMsg } = useExtraFormConf();
  const { values, getEndAdornment, handlePasswordChange } = useInputControl();
  const { passwordInput, confirmPasswordInput, passwordFormControl } = formConfig;

  return (
    <>
      <Dialog aria-labelledby="customized-dialog-title" open={opened}>
        <div className={classes.dialog_content}>
          <img src={passwordDialog} className={classes.img} alt="passwordDialog" />
          <Typography align="center" className={classes.change_password}>
            Your password has been changed!
          </Typography>
          <Button
            type="button"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button_area}
            onClick={() => { history.push('/login'); }}
          >
            Log in
          </Button>
          <Button
            className={classes.button_area}
            type="button"
            fullWidth
            onClick={() => { history.push('/login'); }}
          >
            Not Now
          </Button>
        </div>
      </Dialog>
      {warning.shown ? <WarningMessage content={warning.message} /> : null}
      <Typography className={classes.label}>
        Password must be at least 8 characters long and contains at least one one-letter character
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationPasswordSchema}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field as={FormControl} {...passwordFormControl}>
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                {...passwordInput}
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handlePasswordChange('password')}
              />
              <FormHelperText>{getErrorMsg('password')}</FormHelperText>
            </Field>
            <Field as={FormControl} {...passwordFormControl}>
              <InputLabel htmlFor="outlined-adornment-password">Confirm password</InputLabel>
              <OutlinedInput
                {...confirmPasswordInput}
                type={values.showPassword ? 'text' : 'password'}
                value={values.confirmPassword}
                onChange={handlePasswordChange('confirmPassword')}
                endAdornment={getEndAdornment()}
              />
              <FormHelperText>{getErrorMsg('confirmPassword')}</FormHelperText>
            </Field>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.send_button}
              disabled={isSubmitting}
            >
              Reset Password
            </Button>
            <Link to="/login">
              <IconButton
                type="submit"
                className={classes.back_button}
              >
                <SvgIcon
                  component={backButton}
                  viewBox="0 0 48 48"
                  fontSize="large"
                />
              </IconButton>
            </Link>
          </Form>
        )}
      </Formik>
    </>
  );
}
export default PasswordRecoveryForm;
