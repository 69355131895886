import React, { useState } from 'react';
import * as apiUtils from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';
import { Avatar, Button, Toolbar, Grid, TextField } from '@material-ui/core';
import Selector from 'src/components/Selector';
import { stringAvatar, stringToColor } from 'src/common/functions/toDoPageHeaderFunction';
// import IProjectMember from 'src/common/interfaces/IProjectMember';
import useStyles from './MessageForm.style';

interface PropsType {
  projectId: string;
  companyId: string;
}
function MessageForm(props: PropsType) {
  const { projectId, companyId } = props;
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [titleErr, setTitleErr] = useState(false);
  const [textErr, setTextErr] = useState(false);
  const [text, setText] = useState('');
  const [titleErrMessage, setTitleErrMessage] = useState('');
  const [textErrMessage, setTextErrMessage] = useState('');
  const [categoryId, setCategoryId] = useState(null);
  const posterUserId = localStorage.getItem('userId');
  // const [html] = useState('');
  // const [finalSelectedList] = useState<IProjectMember[]>([] as IProjectMember[]);
  // const subscribersIdsArray = finalSelectedList.map((x: any) => x.id);
  // const subscribersIds = subscribersIdsArray.length === 0 ? null : subscribersIdsArray.join();
  const posterUserFullName = localStorage.getItem('name');
  const submitMessage = async (messageTitle: string, messageCategoryId: string | null) => {
    const resp = await apiUtils.createMessage({ projectId, companyId, messageCategoryId, messageTitle, posterUserId, text });
    if (resp.status === StatusCodes.OK) {
      window.location.reload();
    }
  };
  const createMessage = () => {
    const titleInfo = title.trim().length === 0 ? 'Untitled' : title;
    const categoryIdInfo = categoryId === null ? null : categoryId;
    submitMessage(titleInfo, categoryIdInfo);
  };
  const handleMessageTitleErr = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = event.target.value;
    if (val.length <= 255) {
      setTitle(val);
      setTitleErr(false);
      setTitleErrMessage('');
    } else {
      setTitleErr(true);
      setTitleErrMessage('Maximum input is 255 characters');
    }
  };
  const handleMessageContentErr = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = event.target.value;
    if (val.length <= 10000) {
      setText(val);
      setTextErr(false);
      setTextErrMessage('');
    } else {
      setTextErr(true);
      setTextErrMessage('Maximum input is 10000 characters');
    }
  };

  return (
    <Toolbar className={classes.row}>
      <Grid item className={classes.category} xs={2}>
        <Selector projectId={projectId} setCategoryId={setCategoryId} companyId={companyId} />
      </Grid>
      <Grid item className={classes.title} xs={3}>
        <TextField
          error={titleErr}
          value={title}
          label={titleErr ? 'Error' : ''}
          helperText={titleErrMessage}
          InputProps={{ 'aria-label': 'message title' }}
          className={classes.textFiled}
          placeholder="Type a title:"
          onChange={(event) => handleMessageTitleErr(event)}
          name="message title"
        />

      </Grid>
      <Grid item className={classes.message} xs={5}>
        <TextField
          error={textErr}
          value={text}
          label={textErr ? 'Error' : ''}
          helperText={textErrMessage}
          InputProps={{ 'aria-label': 'message text' }}
          className={classes.textFiled}
          placeholder="Write message content:"
          onChange={(event) => handleMessageContentErr(event)}
          name="message text"
        />
      </Grid>
      <Grid item className={classes.publishedBy} xs={2}>
        <Avatar
          style={{ backgroundColor: stringToColor(posterUserFullName) }}
          className={classes.avatar}
        >
          {stringAvatar(posterUserFullName)}
        </Avatar>
      </Grid>
      <Grid item className={classes.publishedTime} xs={2}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.add}
          disabled={titleErr || textErr}
          onClick={() => {
            createMessage();
          }}
        >
          Post this message
        </Button>
      </Grid>
    </Toolbar>
  );
}
export default MessageForm;
