/* eslint-disable jsx-a11y/no-autofocus */
import React, { ChangeEvent, FocusEvent, useContext, useState } from 'react';
import { TodoContext } from 'src/context/TodoContext';
import { IconButton } from '@material-ui/core';
import * as apiUtils from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './TodoListForm.style';

interface PropsType {
  companyId: string;
  projectId: string;
  setIsAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

function TodoListForm(props: PropsType) {
  const classes = useStyles();
  const { companyId, projectId, setIsAdded } = props;
  const [titleErr, setTitleErr] = useState(false);
  const [requestErr, setRequestErr] = useState(false);
  const [title, setTitle] = useState('');
  const { setUpdate } = useContext(TodoContext);

  const submitTodoList = async (todoListTitle: string) => {
    try {
      const resp = await apiUtils.createTodoList({ companyId, projectId, todoListTitle: todoListTitle.trim() });
      if (resp.status === StatusCodes.OK) {
        setUpdate((prevState) => prevState + 1);
        setIsAdded(false);
      }
    } catch (error: any) {
      if (error.response?.status === StatusCodes.BAD_REQUEST) {
        setRequestErr(true);
      }
    }
  };

  const createTodoList = () => {
    if (title.length === 0) {
      submitTodoList('Untitled section');
    } else if (!titleErr) {
      submitTodoList(title);
    }
  };

  const handleTitleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (val.length <= 255) {
      setTitle(val);
      setTitleErr(false);
    } else {
      setTitleErr(true);
    }
  };

  const handleOnBlur = (event:FocusEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value;
    if (val.length === 0) {
      setIsAdded(false);
    }
  };

  return (
    <>
      {requestErr && (
        <div className={classes.err} data-testid="warning-request">
          Error: 400. Add section failed.
        </div>
      )}

      {titleErr && (
      <div className={classes.err} data-testid="warning-length-exceed">
        At most 255 characters.
      </div>
      )}

      <div className={classes.todolist_add}>
        <ArrowDropDownIcon
          data-testid="button-arrow"
          className={classes.arrow}
          onMouseDown={createTodoList}
        />
        <input
          autoFocus
          className={classes.add_name}
          placeholder=" New section"
          onChange={handleTitleChange}
          onBlur={handleOnBlur}
          value={title}
        />
        <IconButton
          size="small"
          component="span"
          onMouseDown={createTodoList}
        >
          <AddIcon className={classes.add_icon} data-testid="button-add" />
        </IconButton>
      </div>
    </>
  );
}

export default TodoListForm;
