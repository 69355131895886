import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function Loading() {
  return (
    <div>
      <CircularProgress />
    </div>
  );
}

export default Loading;
