import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { darkBlue, deepBlue, blueGrey, darkGrey, grey, black } from 'src/app/color';

const useStyles = makeStyles((theme: Theme) => createStyles({
  project_item_container: {
    width: '255px',
    display: 'inline-block',
    padding: '1rem',
  },
  link: {
    color: darkGrey,
  },
  info: {
    height: '258px',
    borderRadius: '10px',
    boxShadow: '0 5px 5px 0 lightgrey, 0 5px 5px 0 lightgrey',
    backgroundColor: 'white',
    color: deepBlue,
    fontWeight: 'bolder',
  },
  header: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: '10px 10px 0px 20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  name: {
    maxWidth: '150px',
    marginTop: '15px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Poppins-Bold',
    fontSize: '18px',
    fontWeight: 'bolder',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '2.33',
    letterSpacing: '-0.36px',
    textAlign: 'left',
    color: darkBlue,
  },
  description: {
    height: '40px',
    margin: '12px 10px 20px 20px',
    fontWeight: 'lighter',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontQeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.28px',
    textAlign: 'left',
    color: blueGrey,
  },
  progress: {
    margin: '5px 20px 12px 20px',
    color: grey,
    display: 'flex',
    justifyContent: 'space-between',
  },
  line: {
    marginLeft: '20px',
    marginRight: '20px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    color: black,
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '41px 20px 0px 20px',
  },
  icon: {
    width: '80px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    color: darkGrey,
  },
  projectUserName: {
    display: 'flex',
    flexDirection: 'row',
  },
  item: {
    marginRight: '-3px',
  },
}));

export default useStyles;
