import React from 'react';
import { Grid } from '@material-ui/core';
import { useFetch, usePathVariable } from 'src/common/customHooks';
import { fetchCardTodoItemLists } from 'src/utils/apiUtils';
import ITaskCardItem from 'src/common/interfaces/ITaskCardItem';
import Loading from 'src/components/Loading/Loading';
import TaskCard from './components/TaskCard/TaskCard';
import useStyles from './TaskCards.style';

function TaskCards() {
  const classes = useStyles();
  const cardTitles = ['Up-coming', 'Expiring', 'Overdue'];
  const { companyId } = usePathVariable();
  const userId = localStorage.getItem('userId');

  const { data: todoItemLists, loading } = useFetch(
    fetchCardTodoItemLists,
    [companyId, userId],
    [] as ITaskCardItem[][],
  );
  const taskCards = todoItemLists.map((itemList, index) => ({
    title: cardTitles[index],
    taskCardItems: itemList,
  }));

  return (
    <Grid container className={loading ? classes.emptyContainer : classes.container}>
      {loading ? (
        <Loading />
      ) : (
        taskCards.map(({ title, taskCardItems }) => (
          <Grid key={title} item>
            <TaskCard title={title} taskCardItems={taskCardItems} />
          </Grid>
        ))
      )}
    </Grid>
  );
}

export default TaskCards;
