import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { awLightPurple, blueGrey, darkBlue } from 'src/app/color';

const useStyles = makeStyles((theme: Theme) => createStyles({
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: awLightPurple,
    height: '200vh',
    width: '329px',
    borderRadius: '5px',
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    backgroundColor: blueGrey,
    height: '40px',
    width: '100%',
  },
  sectionHeaderTitle: {
    flexGrow: 1,
    fontSize: '18px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    textAlign: 'left',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '10px',
    color: darkBlue,
    '&:hover': {
      cursor: 'pointer',
    },
    maxWidth: '500px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
    },
  },
  sectionAddButton: {
    marginLeft: '15px',
    marginRight: '-5px',
    color: darkBlue,
  },
  moreIcon: {
    float: 'left',
    display: 'inline-block',
    color: darkBlue,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textAlign: 'left',
    padding: '5px',
    height: '200px',
  },
}));

export default useStyles;
