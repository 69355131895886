import { createStyles, makeStyles } from '@material-ui/core/styles';
import { lightRed } from 'src/app/color';

export default makeStyles(() => createStyles({
  container: {
    width: '90%',
    display: 'flex',
    // margin: '0 10px 0 10px',
    justifyContent: 'space-between',
    borderBottomStyle: 'solid',
    borderBottomColor: lightRed,
  },
  create: {
    color: 'grey',
  },
  messageCategoryContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));
