import { makeStyles } from '@material-ui/core';
import { awPurple, darkBlue } from 'src/app/color';

interface PropsType {
  dayOfWeek: string,
  percentage: number,
}

const useStyles = makeStyles(() => ({
  contribution: {
    width: '33.2px',
  },
  dayOfWeek: {
    fontFamily: 'Poppins-regular',
    fontSize: '14px',
    lineHeight: 3,
    color: darkBlue,
    textAlign: 'center',
  },
  numberOfContributionsWrapper: {
    height: '112px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  numberOfContributions: (props: PropsType) => ({
    backgroundColor: 'rgba(163, 174, 208, 0.7)',
    width: '100%',
    height: `${props.percentage === 0 ? 5 : props.percentage * 112}px`,
    content: '',
    borderRadius: '8px',
    border: 'transparent',
    '&:hover': {
      backgroundColor: awPurple,
    },
    '&#clicked': {
      backgroundColor: awPurple,
    },
  }),
}));

export default useStyles;
