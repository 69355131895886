import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { addNewCompanyMember } from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';
import refreshJwt from 'src/common/functions/refreshJwt';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CompanyInvitation() {
  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    const acceptCompanyInvitation = async () => {
      const companyInvitationCode = query.get('code');
      if (!companyInvitationCode) {
        history.push('/login');
      }
      try {
        const { status, data } = await addNewCompanyMember(companyInvitationCode);
        if (status === StatusCodes.OK) {
          await refreshJwt();
          localStorage.setItem('companyId', data.toString());
          history.push('/');
        }
      } catch (error: any) {
        localStorage.clear();
        history.push('/');
      }
    };
    acceptCompanyInvitation();
  }, [query, history]);
  return (
    /* eslint-disable */
    <div></div>
  );
}

export default CompanyInvitation;
