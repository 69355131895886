import { useParams } from 'react-router-dom';

interface IPathVariable {
  companyId: string,
  projectId: string,
  messageId: string,
  todolistId: string,
  todoItemId: string
}

export const usePathVariable = () => useParams<IPathVariable>();
