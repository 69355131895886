import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { lightRed, darkStoneGrey, tomatoRed } from 'src/app/color';

const useSignupErrorMessageStyles = (active: boolean, hasLink: boolean) => makeStyles((theme: Theme) => {
  let errorDisplay: string = 'none';
  let linkDisplay: string = 'none';
  if (active) {
    errorDisplay = 'flex';
  }
  if (hasLink) {
    linkDisplay = 'block';
  }

  return createStyles({
    warning: {
      margin: '20px 0',
      padding: '3%',
      borderRadius: '10px',
      border: 'solid 2px #eb5757',
      backgroundColor: lightRed,
      display: errorDisplay,
      alignItems: 'center',
    },
    warning_message: {
      fontSize: '0.875rem',
      fontWeight: 500,
      marginLeft: '12px',
      textAlign: 'left',
      color: darkStoneGrey,
      [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: '1rem',
      },
    },
    link: {
      display: linkDisplay,
      fontFamily: 'Poppins-Regular',
      fontSize: '0.875rem',
      fontWeight: 500,
      textAlign: 'left',
      color: tomatoRed,
      marginLeft: '6px',
      textDecoration: 'underline',
      [theme.breakpoints.between('lg', 'xl')]: {
        fontSize: '1rem',
      },
    },
  });
})();

export default useSignupErrorMessageStyles;
