import React from 'react';
import ProjectsPageContent from './components/ProjectsPageContent/ProjectsPageContent';
import ProjectsPageHeader from './components/ProjectsPageHeader/ProjectsPageHeader';

function ProjectsPage() {
  return (
    <>
      <ProjectsPageHeader />
      <ProjectsPageContent />
    </>
  );
}

export default ProjectsPage;
