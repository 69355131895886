import { useEffect, useState } from 'react';
import { getCompanyInfo } from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';

const useUserLastLoginCompanyInfo = (companyId: string | null) => {
  const [companyInfo, setCompanyInfo] = useState({
    companyName: '',
    description: '',
  });
  useEffect(() => {
    const fetchLastLoginCompanyInfo = async () => {
      const {
        status,
        data,
      } = await getCompanyInfo(companyId);
      if (status === StatusCodes.OK) {
        setCompanyInfo({
          companyName: data.name,
          description: data.description,
        });
      }
    };
    fetchLastLoginCompanyInfo();
  }, [companyId]);
  return companyInfo;
};
export default useUserLastLoginCompanyInfo;
