import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import { useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { usePathVariable } from 'src/common/customHooks';
import IProject from 'src/common/interfaces/IProject';
import useStyles from './ProjectSelector.style';

interface Props {
  title: string | undefined,
  otherProjects: IProject[],
}

function ProjectSelector({ title, otherProjects }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const { companyId } = usePathVariable();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitch = (projectItem: IProject) => {
    history.push(`/companies/${companyId}/projects/${projectItem.id}`);
    handleClose();
  };
  otherProjects.sort((a, b) => a.name.localeCompare(b.name, 'zh'));

  return (
    <div className={classes.project_switch}>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        data-testid="project-selector"
        onClick={anchorEl ? handleClose : handleOpen}
        className={classes.project_switch_button}
        endIcon={anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        <Typography className={classes.title} variant="inherit" noWrap>
          {title}
        </Typography>
      </Button>
      {otherProjects.length === 0 ? null : (
        <Menu
          id="customized-menu"
          data-testid="menu"
          className={anchorEl ? classes.menuShow : classes.menuExit}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: -100,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: -100,
          }}
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={1}
        >
          {
            otherProjects.map((projectItem: IProject) => (
              <MenuItem key={projectItem.id} onClick={() => handleSwitch(projectItem)}>
                <Typography variant="inherit" noWrap>
                  {projectItem.name}
                </Typography>
              </MenuItem>
            ))
          }
        </Menu>
      )}
    </div>
  );
}
export default ProjectSelector;
