import React, { useState } from 'react';
import { Button, createStyles, makeStyles, Container } from '@material-ui/core';
import { white } from 'src/app/color';
import styles from './PeopleList.module.scss';
import PeopleItem from './components/PeopleItem/PeopleItem';
import InvitationForm from './components/InvitationForm/InvitationForm';

const useStyles = makeStyles(() => createStyles({
  button: {
    width: '120px',
    color: white,
    fontSize: '0.8rem',
    borderRadius: '30px',
    textTransform: 'capitalize',
  },
}));

interface Props {
  employeeInfo: Employee[],
  isCompanyOwner: boolean,
}
interface Employee {
  name: string,
  title: string,
  email: string
}

function PeopleList({ employeeInfo, isCompanyOwner }: Props) {
  const peopleListClasses = useStyles();
  const [active, setActive] = useState(false);

  const onShow = () => {
    setActive(true);
  };

  const onClose = () => {
    setActive(false);
  };

  const addPeopleButton = () => (
    isCompanyOwner
      ? (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={peopleListClasses.button}
          onClick={onShow}
        >
          + Add people
        </Button>
      )
      : (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={peopleListClasses.button}
          disabled
        >
          + Add people
        </Button>
      )
  );

  employeeInfo.sort((a, b) => a.name.localeCompare(b.name, 'zh'));

  const employeeDisplay = employeeInfo ? employeeInfo.map((e: Employee) => <PeopleItem key={e.email} employee={e} />)
    : <div>no employee</div>;
  return (
    <Container>
      <div className={styles.peopleList}>
        <div className={styles.wrapper}>
          <div className={styles.button}>
            {addPeopleButton()}
          </div>
        </div>
        {active ? <InvitationForm closeForm={onClose} /> : null}
        <div className={styles.bottomContent}>
          {employeeDisplay}
        </div>
      </div>
    </Container>
  );
}

export default PeopleList;
