/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Input } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TimePicker from '@mui/lab/TimePicker';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import { createEvent } from 'src/utils/apiUtils';
import { ScheduleContext } from 'src/context/ScheduleContext';
import { StatusCodes } from 'http-status-codes';
import useStyles from '../../Schedules.style';

function EventEditForm() {
  const classes = useStyles();
  const { companyId, projectId, targetDate, setShowEventEditForm, setlistLatestUpdatedTime } = useContext(ScheduleContext);
  const isAllDayEvent = false;
  const title = 'test creation';
  const notes = 'I am so lazy...';
  const startDateTime = new Date();
  const endDateTime = startDateTime.setDate(startDateTime.getDate() + 1);

  const [startDate, setStartDate] = useState<Date | null>(targetDate);
  const [startTime, setStartTime] = useState<Date | null>(targetDate);
  const [endDate, setEndDate] = useState<Date | null>(targetDate);
  const [endTime, setEndTime] = useState<Date | null>(targetDate);

  const submitForm = async () => {
    const { status } = await createEvent(
      companyId,
      projectId,
      title,
      notes,
      isAllDayEvent,
      startDateTime.toString(),
      endDateTime.toString(),
    );

    if (status === StatusCodes.OK) {
      setlistLatestUpdatedTime(new Date());
    }
  };

  const [repeat, setRepeat] = useState('');
  const repeatChange = (event: SelectChangeEvent) => {
    setRepeat(event.target.value);
  };

  /* eslint-disable */
  const renderInput = React.useMemo(() => function (params: JSX.IntrinsicAttributes & TextFieldProps) {
    return <TextField {...params} />;
  }, []);
  /* eslint-enable */

  return (
    <div>
      <div className={classes.addEventName}>
        <TextField InputProps={{ disableUnderline: true }} fullWidth placeholder="Type the name of event" variant="standard" />
      </div>
      <div className={classes.addEventContext}>
        <div className={classes.eventItem}>
          <div className={classes.eventItemTitle}>All Day?</div>
          <Checkbox defaultChecked color="secondary" />
        </div>
        <div className={classes.eventItem}>
          <div className={classes.eventItemTitle}>Start</div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              // renderInput={(params) => <TextField {...params} />}
              renderInput={renderInput}
            />
            <TimePicker
              value={startTime}
              onChange={(newValue) => {
                setStartTime(newValue);
              }}
              // renderInput={(params) => <TextField {...params} />}
              renderInput={renderInput}
            />
          </LocalizationProvider>
        </div>
        <div className={classes.eventItem}>
          <div className={classes.eventItemTitle}>End</div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              // renderInput={(params) => <TextField {...params} />}
              renderInput={renderInput}
            />
            <TimePicker
              value={endTime}
              onChange={(newValue) => {
                setEndTime(newValue);
              }}
              // renderInput={(params) => <TextField {...params} />}
              renderInput={renderInput}
            />
          </LocalizationProvider>
        </div>
        <div className={classes.eventItem}>
          <div className={classes.eventItemTitle}>Repeat</div>
          <Select
            value={repeat}
            onChange={repeatChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="">Don&#39;t Repeat</MenuItem>
            <MenuItem value={1}>Repeat</MenuItem>
          </Select>
        </div>
        <div className={classes.eventItem}>
          <div className={classes.eventItemTitle}>With</div>
          <Button
            className={classes.eventAssginees}
            variant="outlined"
            startIcon={<Avatar sx={{ bgcolor: deepOrange[500] }}>S</Avatar>}
          >
            Sally
          </Button>
        </div>
        <div className={classes.eventItem}>
          <div className={classes.eventItemTitle}>Notes</div>
          <div className={classes.eventNotesInput}>
            <Input fullWidth placeholder="Add extra details or attach a file..." />
          </div>
        </div>
      </div>
      <div className={classes.addEventBtnsContainer}>
        <button type="submit" className={classes.postEventBtn} onClick={() => submitForm()}>Post this event</button>
        <button type="submit" className={classes.postCancelBtn} onClick={() => setShowEventEditForm(false)}>Cancel</button>
      </div>
    </div>
  );
}
export default EventEditForm;
