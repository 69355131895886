import React, { useMemo, useRef } from 'react';
import { Button, Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MenuItem from '@material-ui/core/MenuItem';
import IUserCompanyInfo from 'src/common/interfaces/IUserCompanyInfo';
import useStyles from './CompanySelector.style';
import useUserCompanyList from './hooks/useUserCompanyList';
import useUserLastLoginCompanyInfo from './hooks/useUserLastLoginCompanyInfo';
import useSelect from './hooks/useSelect';

interface PropsType {
  email: string | null;
}
function CompanySelector(props: PropsType) {
  const { email } = props;
  const classes = useStyles();
  const userLastLoginCompanyId = localStorage.getItem('companyId');
  const userLastLoginCompanyInfo = useUserLastLoginCompanyInfo(userLastLoginCompanyId);
  const userCompanyList = useUserCompanyList(email);
  const anchorRef = useRef<HTMLDivElement>(null);
  const {
    open,
    handleOpen,
    handleClose,
    handleSwitch,
  } = useSelect();
  const userOtherCompanies = useMemo(() => userCompanyList.filter((e) => e.id.toString() !== userLastLoginCompanyId), [userCompanyList]);
  const menuItem = useMemo(() => userOtherCompanies.map((company:IUserCompanyInfo) => (
    <MenuItem key={company.id} onClick={() => handleSwitch(company)}>
      <Typography variant="inherit" noWrap>
        {company.name}
      </Typography>
    </MenuItem>
  )), [userOtherCompanies]);

  return (
    <div ref={anchorRef} className={classes.company_switch}>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        data-testid="company-selector"
        onClick={open ? handleClose : handleOpen}
        className={classes.company_switch_button}
        endIcon={open && userOtherCompanies.length ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        disabled={!userOtherCompanies.length}
      >
        <Typography className={classes.title} variant="inherit" noWrap>
          {userLastLoginCompanyInfo.companyName}
        </Typography>
      </Button>
      {userOtherCompanies.length ? (
        <Menu
          id="customized-menu"
          data-testid="menu"
          className={classes.menu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          anchorEl={anchorRef.current}
          open={open}
          onClose={handleClose}
          elevation={1}
        >
          {menuItem}
        </Menu>
      ) : null}
    </div>
  );
}
export default CompanySelector;
