import React, { useEffect, useState } from 'react';
import { Box, Divider, Paper, Typography } from '@material-ui/core';
import ITaskCard from 'src/common/interfaces/ITaskCard';
import upcoming from 'src/assets/svgs/Up-coming.svg';
import expiring from 'src/assets/svgs/Expiring.svg';
import overdue from 'src/assets/svgs/Overdue.svg';
import useStyles from './TaskCard.style';
import TaskCardItem from './components/TaskCardItem/TaskCardItem';

interface IEmptyContent {
  title: string;
  img: string;
  text: string;
}

function TaskCard({ title, taskCardItems }: ITaskCard) {
  const classes = useStyles();
  const [emptyImg, setEmptyImg] = useState(upcoming);
  const [emptyText, setEmptyText] = useState('None');
  const contents: IEmptyContent[] = [
    {
      title: 'Up-coming!!',
      img: upcoming,
      text: 'No Up-coming work',
    },
    {
      title: 'Expiring',
      img: expiring,
      text: 'Great, No Expiring work',
    },
    {
      title: 'Overdue',
      img: overdue,
      text: 'No Overdue work',
    },
  ];

  useEffect(() => {
    contents.forEach((item) => {
      if (item.title === title) {
        setEmptyImg(item.img);
        setEmptyText(item.text);
      }
    });
  }, []);

  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography component="div">
        <Box className={classes.title}>{title}</Box>
      </Typography>
      {taskCardItems.length !== 0 ? (
        <Box className={classes.itemsWrapper}>
          {taskCardItems.map(({ todoItemId, description, projectTitle, dueDate }, index) => (
            <Box key={todoItemId}>
              <TaskCardItem title={title} taskName={description} projectName={projectTitle} dueDate={dueDate} />
              {index !== taskCardItems.length - 1 && <Divider className={classes.divider} />}
            </Box>
          ))}
        </Box>
      ) : (
        <Box className={classes.emptyItemWrapper}>
          <Box>
            <img src={emptyImg} alt="Img" />
          </Box>
          <Typography className={classes.emptyText}>{emptyText}</Typography>
        </Box>
      )}
    </Paper>
  );
}

export default TaskCard;
