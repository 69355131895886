import React from 'react';
import {
  Typography,
  Grid,
  Hidden,
} from '@material-ui/core';
import {
  Link,
} from 'react-router-dom';
import welcome from 'src/assets/svgs/welcome.svg';
import logo from '../../assets/svgs/logoWithoutTexts.svg';
import LoginForm from './components/LoginForm/LoginForm';
import useStyles from './Login.style';
import Footer from '../Footer/Footer';

function Login() {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      className={classes.background}
    >
      <Grid item md={5} className={classes.form}>
        <Link to="/">
          <img src={logo} className="img" alt="logo" />
        </Link>
        <Typography className={classes.title}>
          Log in to Async Working
        </Typography>
        <LoginForm />
        <Footer isPublicPage />
      </Grid>
      <Hidden smDown>
        <Grid item md={7} container className={classes.img_container}>
          <img src={welcome} alt="welcome" className={classes.img} />
        </Grid>
      </Hidden>
    </Grid>
  );
}
export default Login;
