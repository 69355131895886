import {
  makeStyles,
} from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { white } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  popup_container_display: {
    top: '80px',
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    zIndex: 10,
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(196, 196, 196, 0.26)',
  },
  content_hidden: {
    display: 'none',
  },
  popup: {
    width: '35rem',
    background: white,
    boxShadow: '0 5px 20px rgb(0 0 0 / 35%)',
    borderRadius: '0.8rem',
    padding: '2rem',
  },
}));

export default useStyles;
