import {
  makeStyles,
} from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { blueGrey, darkBlue, lightGrey, white } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  popup_container_display: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    zIndex: 10,
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(196, 196, 196, 0.26)',
  },
  content_hidden: {
    display: 'none',
  },
  popup: {
    width: '35rem',
    maxWidth: '90vw',
    background: white,
    boxShadow: '0 5px 20px rgb(0 0 0 / 35%)',
    maxHeight: '90vh',
    borderRadius: '0.8rem',
    padding: '2rem',
  },

  close_icon: {
    color: darkBlue,
  },
  popup_header: {
    padding: '4rem 0',
    position: 'relative',
  },
  close_btn: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'none',
    textDecoration: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  popup_title: {
    color: darkBlue,
    display: 'block',
    textAlign: 'center',
    fontFamily: 'Poppins-Regular',
    fontSize: '36px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.78,
    letterSpacing: '-0.72px',
  },
  popup_subtitle: {
    color: darkBlue,
    display: 'block',
    textAlign: 'center',
    marginTop: '1rem',
    fontFamily: 'Poppins-Regular',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.56,
    letterSpacing: '-0.36px',
  },
  popup_content: {
    display: 'flex',
    marginBottom: '4rem',
  },
  popup_content_button_left: {
    background: 'none',
    textDecoration: 'none',
    border: 'none',
    cursor: 'pointer',
    left: 'auto',
    right: 'auto',
    height: '164px',
    width: '176px',
    padding: 0,
  },
  popup_content_button_right: {
    background: 'none',
    textDecoration: 'none',
    border: 'solid 2px #a3aed0',
    borderRadius: '20px',
    cursor: 'pointer',
    left: 'auto',
    right: 'auto',
    height: '164px',
    width: '176px',
  },
  blank_project_icon: {
    height: '164px',
    width: '176px',
  },
  template_icon: {
  },
  popup_content_section: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '1.5rem',
    paddingTop: '1.5rem',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: lightGrey,
      borderRadius: '20px',
    },
  },
  content_label: {
    marginTop: '1rem',
    color: darkBlue,
    fontFamily: 'Poppins-Regular',
    fontSize: '18px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.56,
    letterSpacing: '-0.36px',
  },
  content_description: {
    color: blueGrey,
    fontFamily: 'Poppins-Regular',
    fontSize: '14px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: '-0.28px',
  },
}));

export default useStyles;
