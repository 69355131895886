import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './TodoSetHeader.style';

interface PropsType {
  setAdd: React.Dispatch<React.SetStateAction<boolean>>;
  isProjectMember: boolean;
  isCompanyManager: boolean;
}

/* eslint-disable */
function TodoSetHeader(props : PropsType) {
  const { setAdd, isProjectMember, isCompanyManager } = props;
  const styles = useStyles();

  const addNewList = () => {
    setAdd(true);
  };

  return (
    <>
      {(isProjectMember || isCompanyManager) && (
        <button
          type="button"
          className={styles.addSection}
          onClick={addNewList}
        >
          <AddIcon className={styles.addIcon} />
          <span>
            Add section
          </span>
        </button>
      )}
    </>
  );
}
/* eslint-enabled */
export default TodoSetHeader;
