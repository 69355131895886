import { makeStyles, createStyles } from '@material-ui/core/styles';
import { blueGrey } from 'src/app/color';

const useStyles = (value:string, lineHeight:string) => makeStyles(() => createStyles({
  progressBar: {
    width: '100%',
    borderRadius: lineHeight,
    backgroundColor: blueGrey,
    height: lineHeight,
  },
  progress: {
    width: value,
    borderRadius: lineHeight,
    height: lineHeight,
    animation: '$progessBar 1500ms',
  },
  '@keyframes progessBar': {
    '0%': {
      width: '0%',
    },
    '100%': {
      width: value,
    },
  },
}))();

export default useStyles;
