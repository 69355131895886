const awPurple = '#7d589f';
const awDarkPurple = '#5f11cb';
const awDeepPurple = '#4d296f';
const awLightPurple = '#F4EDFE';
const salsifyGrassGreen = '#2ab782';
const tiffanyGreen = '#56cfd4';
const tomatoRed = '#f44336';
const lightRed = '#fcebea';
const darkBlue = '#353d68';
const skyBlue = '#0E86D4';
const lightSkyBlue = '#a4d2ff';
const darkSkyBlue = '#055C9D';
const deepBlue = '#002649';
const deepDarkBlue = '#1b2559';
const lightBlue = '#f5fafa';
const blueGrey = '#a3aed0';
const darkGrey = '#6b6b6b';
const lightGrey = '#f3f2f7';
const stoneGrey = '#4a5568';
const darkStoneGrey = '#283c46';
const lightYellow = '#fffae5';
const warmYellow = '#ffeb99';
const orange = '#f8991f';
const redPink = '#c6496f';

const red = 'red';
const white = 'white';
const black = 'black';
const green = 'green';
const blue = 'blue';
const yellow = 'yellow';
const grey = 'grey';

export {
  awPurple,
  awDarkPurple,
  awDeepPurple,
  awLightPurple,
  salsifyGrassGreen,
  tiffanyGreen,
  tomatoRed,
  lightRed,
  darkBlue,
  skyBlue,
  lightSkyBlue,
  darkSkyBlue,
  deepBlue,
  deepDarkBlue,
  lightBlue,
  blueGrey,
  darkGrey,
  lightGrey,
  stoneGrey,
  darkStoneGrey,
  lightYellow,
  warmYellow,
  orange,
  redPink,
  red,
  white,
  black,
  green,
  blue,
  yellow,
  grey,
};
