import React, { memo } from 'react';
import useStyles from './Dotline.style';

interface PropsType {
  numberOfContributions: number,
  percentage: number,
}

function Dotline(props: PropsType) {
  const { numberOfContributions, percentage } = props;
  const classes = useStyles({ numberOfContributions, percentage });

  return (
    <>
      <div className={classes.dotline} />
      <div className={classes.numberOfTasks}>
        {numberOfContributions}
        {' '}
        tasks
      </div>
    </>
  );
}

export default memo(Dotline);
