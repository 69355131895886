/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Link,
  Typography,
  Grid,
} from '@material-ui/core';
import warningIcon from 'src/assets/svgs/warningIcon.svg';
import { useHistory, useLocation } from 'react-router-dom';
import useStyles from './ResendMessage.style';

function ResendMessage() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const handleClick = () => {
    history.push('/activation', { path: location.pathname });
  };
  return (
    <Grid container className={classes.resend}>
      <Grid item container alignItems="center">
        <Grid item className={classes.warningIcon}>
          <img src={warningIcon} alt="warningIcon" />
        </Grid>
        <Grid item>
          <Typography className={classes.resend_message}>Please activate your account.</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Link
          component="button"
          onClick={handleClick}
          className={classes.resend_link}
        >
          Click here to resend verification email
        </Link>
      </Grid>
    </Grid>
  );
}

export default ResendMessage;
