import React, { useState } from 'react';
import { Fab, Avatar, ClickAwayListener } from '@material-ui/core';
import compare from 'src/common/functions/compare';
import IProjectMember from 'src/common/interfaces/IProjectMember';
import { stringAvatar, stringToColor } from 'src/common/functions/toDoPageHeaderFunction';
import AddIcon from '@material-ui/icons/Add';
import { AvatarGroup } from '@material-ui/lab';
import AssigneeMenu from 'src/components/AssigneeMenu/AssigneeMenu';
import useStyles from './Assignees.styles';

interface AssigneesProps {
  subscribersIds: string;
  assignees: IProjectMember[];
  projectMembers: IProjectMember[];
  inputRef: React.MutableRefObject<HTMLInputElement | undefined>;
  setNewTodoItem: Function;
}

function Assignees({ subscribersIds, assignees, projectMembers, inputRef, setNewTodoItem }: AssigneesProps) {
  const classes = useStyles();
  const [isAssigneesOpen, setIsAssigneesOpen] = useState(false);
  const [onMouseOver] = useState(false);
  const handleClickAssignees = () => {
    setIsAssigneesOpen(!isAssigneesOpen);
  };
  const handleBlur = () => {
    if (onMouseOver) {
      if (!inputRef.current?.value) {
        inputRef.current?.focus();
      }
      return;
    }
    setNewTodoItem();
  };

  const handleClickAwayAssignees = () => {
    setIsAssigneesOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAwayAssignees}>
      <div className={classes.assignees}>
        {subscribersIds && assignees ? (
          <div className={classes.assigneesWrapper}>
            <Fab aria-label="addAssignees" className={classes.fab} onClick={handleClickAssignees} onBlur={handleBlur}>
              <AddIcon className={classes.add} />
            </Fab>
            <button type="button" onClick={handleClickAssignees} className={classes.assigneesBtn}>
              <AvatarGroup max={2} classes={{ avatar: classes.avatar }}>
                {assignees.sort(compare).map((assignee) => (
                  <Avatar key={assignee.id} style={{ backgroundColor: stringToColor(assignee.name) }}>
                    {stringAvatar(assignee.name)}
                  </Avatar>
                ))}
              </AvatarGroup>
            </button>
          </div>
        ) : (
          <Fab aria-label="addAssignees" className={classes.fab} onClick={handleClickAssignees} onBlur={handleBlur}>
            <AddIcon className={classes.add} />
          </Fab>
        )}
        {isAssigneesOpen && (
          <AssigneeMenu
            projectMembers={projectMembers}
            assignees={assignees}
            assigneesIds={subscribersIds}
            setNewTodoItem={setNewTodoItem}
            setIsAssigneesOpen={setIsAssigneesOpen}
          />
        )}
      </div>
    </ClickAwayListener>
  );
}

export default Assignees;
