// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import IUserCompanyInfo from 'src/common/interfaces/IUserCompanyInfo';

const useSelect = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const handleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSwitch = async (company: IUserCompanyInfo) => {
    localStorage.setItem('companyId', company.id);
    setOpen(false);
    history.push('/');
  };
  return {
    open,
    handleOpen,
    handleClose,
    handleSwitch,
  };
};
export default useSelect;
