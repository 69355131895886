import React from 'react';
import PriorityLabel from './components/PriorityLabel';
import useStyles from './PriorityOption.style';

interface PropsType {
  priority: string;
  onClick: () => void;
  select: string;
  disabled: boolean;
}

function PriorityOption(props: PropsType) {
  const classes = useStyles();
  const { priority, onClick, select, disabled } = props;

  return (
    <button
      className={classes.optionBtn}
      type="button"
      onClick={onClick}
      id={priority === select ? 'Selected' : ''}
    >
      <PriorityLabel priority={priority} disabled={disabled} />
    </button>
  );
}

export default PriorityOption;
