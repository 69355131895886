import React, { createContext } from 'react';

interface IScheduleContext {
  companyId: string,
  projectId: string,
  targetDate: Date,
  setTargetDate: React.Dispatch<React.SetStateAction<Date>>
  showEventEditForm: boolean,
  setShowEventEditForm: React.Dispatch<React.SetStateAction<boolean>>,
  listLatestUpdatedTime: Date,
  setlistLatestUpdatedTime: React.Dispatch<React.SetStateAction<Date>>
}

export const ScheduleContext = createContext({} as IScheduleContext);
