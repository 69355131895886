import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute, PublicRoute, FirstLoginRoute } from 'src/components/CustomRoutes/CustomRoutes';
import DashBoard from 'src/components/DashBoard/DashBoard';
import Signup from 'src/components/Signup/Signup';
import PasswordRecovery from 'src/components/PasswordRecovery';
import ForbiddenPage from 'src/pages/components/ForbiddenPage/ForbiddenPage';
import EmailActivationPage from 'src/components/Signup/components/EmailActivationPage/EmailActivationPage';
import InvitationRegisterPage from 'src/components/InvitationRegister/InvitationRegisterPage';
import FirstLoginContainer from 'src/components/FirstLogin/FirstLoginContainer';
import NotFoundPage from 'src/pages/components/NotFoundPage/NotFoundPage';
import VerifiedPage from 'src/components/Signup/components/VerifiedPage/VerifiedPage';
import PasswordResetPage from 'src/components/PasswordResetPage';
import Login from 'src/components/Login/Login';
import CompanyInvitation from 'src/components/CompanyInvitation/CompanyInvitation';
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <PublicRoute exact path="/" component={Login} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/signup" component={Signup} />
        <PublicRoute path="/activation" component={EmailActivationPage} />
        <PublicRoute path="/verifylink" component={VerifiedPage} />
        <PrivateRoute path="/forbidden" component={ForbiddenPage} />
        <PrivateRoute path="/companies/:companyId(\d+)" component={DashBoard} />
        <Route path="/company-invitations/info" component={CompanyInvitation} />
        <FirstLoginRoute path="/firstlogin" component={FirstLoginContainer} />
        <Route path="/reset-password" component={PasswordResetPage} />
        <PublicRoute path="/recovery/:step" component={PasswordRecovery} />
        <Route path="/invitations/info" component={InvitationRegisterPage} />
        <Redirect path="/companies/null" to="/firstlogin" />
        <PublicRoute component={NotFoundPage} />
      </Switch>
    </ThemeProvider>
  );
}

export default App;
