import { createStyles, makeStyles } from '@material-ui/core/styles';
import { awDeepPurple, darkGrey } from 'src/app/color';

export default makeStyles(() => createStyles({
  buttonContainer: {
    flexWrap: 'nowrap',
    marginTop: '1rem',
  },
  signup_button: {
    textTransform: 'initial',
    top: '-3px',
    fontSize: '0.875rem',
  },
  back_button: {
    right: '12px',
  },
  next_button: {
    left: '11px',
  },
  login_link: {
    fontFamily: 'Poppins-Medium',
    fontSize: '0.875rem',
    textAlign: 'left',
    color: awDeepPurple,
    fontWeight: 550,
  },
  account: {
    fontFamily: 'Poppins-Medium',
    fontSize: '0.875rem',
    textAlign: 'left',
    color: darkGrey,
    fontWeight: 550,
  },
}));
