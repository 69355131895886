import React, { useCallback, useState } from 'react';
import {
  InputAdornment,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface IValues {
  showPassword: boolean;
  password: string;
  confirmPassword: string;
}

const useInputControl = () => {
  const [values, setValues] = useState<IValues>({
    showPassword: false,
    password: '',
    confirmPassword: '',
  });

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = useCallback(() => {
    setValues({ ...values, showPassword: !values.showPassword });
  }, [values]);

  const getEndAdornment = useCallback(() => (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        edge="end"
      >
        {values.showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  ), [handleClickShowPassword, values.showPassword]);

  return { getEndAdornment, values, setValues, handlePasswordChange, handleClickShowPassword };
};

export default useInputControl;
