import { makeStyles } from '@material-ui/core/styles';
import { darkGrey } from 'src/app/color';

const useStyles = makeStyles({
  root: {
    minWidth: 250,
    maxWidth: '90%',
    marginRight: 10,
    boxShadow: 'none',
  },
  card_content: {
    padding: '0px',
    '&:last-child': {
      paddingBottom: 19,
    },
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  project_task_one_line: {
    marginTop: 16,
    maxWidth: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  project_name: {
    marginRight: 4,
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontSize: 14,
    color: darkGrey,
    fontFamily: 'Poppins-Bold',
  },
  task_name: {
    fontFamily: 'Poppins-Medium',
    fontSize: 14,
    color: 'rgba(89,89,89,1)',
  },
  date: {
    color: ' #acafb9',
    width: 80,
    height: 12,
    opacity: 0.8,
    fontFamily: 'Poppins-Medium',
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    marginTop: 2.5,
    lineHeight: 1,
    letterSpacing: 0.3,
  },
  overdue_date: {
    color: ' #f50707',
    width: 80,
    height: 12,
    opacity: 0.5,
    fontFamily: 'Poppins-Bold',
    fontSize: 12,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    marginTop: 2.5,
    lineHeight: 1,
    letterSpacing: 0.3,
  },
});

export default useStyles;
