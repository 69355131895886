import { createStyles, makeStyles } from '@material-ui/core/styles';
import { darkBlue, stoneGrey, lightBlue, white, awPurple } from 'src/app/color';

export default makeStyles(() => createStyles({

  popup: {
    display: 'flex',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    backgroundColor: 'rgba(0,0,0,0.4)',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 10,
  },

  invite_window: {
    width: '623px',
    flexGrow: 0,
    background: white,
    borderRadius: '0.8rem',
    boxShadow: '0 5px 20px rgb(0 0 0 / 35%)',
    maxHeight: '90vh',
    maxWidth: '90vw',
    marginTop: '90px',
  },

  invite_header: {
    padding: '45px 35px 15.5px 35px',
    position: 'relative',
    borderBottom: '0.5px solid #a3aed0',
  },

  close_btn: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    background: 'none',
    textDecoration: 'none',
    border: 'none',
    cursor: 'pointer',
  },

  close_icon: {
    color: darkBlue,
    width: '26px',
    height: '28px',
  },

  invite_title: {
    color: darkBlue,
    display: 'block',
    fontFamily: 'Poppins-Regular',
    fontSize: '24px',
    fontWeight: 600,
    fontStretch: 'normalize',
    fontStyle: 'normal',
    lineHeight: 1.75,
    letterSpacing: '-0.48px',
    flexGrow: 0,
  },

  invite_content: {
    borderBottom: '1px solid #a3aed0',
    paddingTop: '27px',
    paddingBottom: '10px',
  },

  invite_form_title: {
    color: stoneGrey,
    fontFamily: 'Poppins-Regular',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normalize',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginBottom: '13px',
  },

  search_bar: {
    padding: '0 32px',
  },

  selected_member_display: {
    overflowY: 'auto',
    maxWidth: '650px',
    maxHeight: '295px',
    marginTop: '10px',
  },

  invite_fonter: {
    padding: '25px 35px',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  share_btn: {
    background: awPurple,
    textDecoration: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '0.5rem 1.3rem',
    borderRadius: '5px',
    fontFamily: 'Poppins-Regular',
    fontSize: '12px',
    fontWeight: 500,
    fontStretch: 'normalize',
    fontStyle: 'normal',
    lineHeight: 1.67,
    textAlign: 'center',
    color: lightBlue,
  },

  selector: {
    fontSize: '14px',
    fontFamily: 'Poppins-Regular',
  },

  member_options: {
    position: 'absolute',
    background: lightBlue,
    width: '558px',
    borderRadius: '0 0 10px 10px',
    overflow: 'hidden',
    overflowY: 'auto',
    maxHeight: '305px',
  },

  searchBar_focused: {
    backgroundColor: lightBlue,
  },

}));
