import React from 'react';
import { Grid, Toolbar } from '@material-ui/core';
import useStyles from './MessageHeader.style';

function MessageHeader() {
  const classes = useStyles();
  return (
    <Toolbar variant="dense" className={classes.rowHeader}>
      <Grid item className={classes.header_Category} xs={2}>Category</Grid>
      <Grid item className={classes.header_Title} xs={3}>Title</Grid>
      <Grid item className={classes.header_Message} xs={5}>Message</Grid>
      <Grid item className={classes.header_Published_By} xs={2}>Published by</Grid>
      <Grid item className={classes.header_Published_Time} xs={2}>Published time</Grid>
    </Toolbar>
  );
}

export default MessageHeader;
