import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  mainPaper: {
    marginLeft: 40,
    marginRight: 40,
  },
}));

export default useStyles;
