/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import { useStyles, BootstrapInput, MenuProps } from './DisplaySelectors.style';

function DisplaySelectors() {
  const classes = useStyles();
  const [projectType, setProjectType] = useState('Public');
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProjectType(event.target.value as string);
  };
  const [toggle, setToggle] = useState(false);

  const displayToggle = () => {
    setToggle(!toggle);
  };

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="flex-end"
      className={classes.selectors_box}
    >
      <FormControl>
        <Select
          data-testid="project-type-selector"
          className={classes.selector}
          value={projectType}
          onChange={handleChange}
          input={<BootstrapInput />}
          MenuProps={MenuProps}
        >
          <MenuItem value="Public">Public</MenuItem>
          <MenuItem value="Private">Private</MenuItem>
        </Select>
      </FormControl>

      <button
        type="button"
        data-testid="display-switch"
        className={classes.display_toggle_btns}
        onClick={displayToggle}
      >
        <div className={toggle ? classes.toggle_avtive : classes.toggle_btn}>
          <DashboardRoundedIcon className={toggle ? classes.toggle_icon_default : classes.toggle_icon_primary} />
        </div>

        <div className={toggle ? classes.toggle_btn : classes.toggle_avtive}>
          <FormatListBulletedRoundedIcon className={toggle ? classes.toggle_icon_primary : classes.toggle_icon_default} />
        </div>
      </button>
    </Box>
  );
}

export default DisplaySelectors;
