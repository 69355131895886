import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from 'react-router';
import useStyles from './Logout.style';

function Logout() {
  const classes = useStyles();

  const history = useHistory();
  const handleLogout = () => {
    localStorage.clear();
    history.push('/login');
  };

  return (
    <ListItem
      button
      onClick={handleLogout}
      classes={{
        button: classes.logout,
      }}
    >
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Log Out" classes={{ primary: classes.listText }} />
    </ListItem>
  );
}

export default Logout;
