import { makeStyles } from '@material-ui/core/styles';
import { darkGrey } from 'src/app/color';

const useStyles = makeStyles(() => ({
  root: {
    height: 300,
    minWidth: 250,
    maxWidth: '90%',
    marginRight: 10,
    marginTop: '18px',
    marginBottom: 'auto',
    borderRadius: '5%',
    boxShadow: 'none',
  },
  badge: {
    height: 50,
    width: 30,
  },
  title: {
    marginBottom: 30,
  },
  date: {
    color: darkGrey,
    marginTop: 30,
  },
  textField: {
    width: 320,
    maxWidth: '90%',
  },
}));

export default useStyles;
