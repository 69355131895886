import React from 'react';
import {
  Button,
  Grid,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useStyles from './EmailCheckingPage.style';

function EmailCheckingPage() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <h3 className={classes.header}>Please check your email</h3>
      <p className={classes.notification_top}>
        If can’t find password resent email, please check your spam filter.
      </p>
      <div className={classes.notification_email}>
        {localStorage.getItem('email')}
      </div>
      <p className={classes.notification_bottom}>
        We sent a recovery link to you at
      </p>
      <Grid container justifyContent="space-between" alignItems="baseline">
        <Grid item>
          <Button
            className={classes.button_resend}
            onClick={() => { history.push('/recovery/email-input-page'); }}
          >
            Resend Email
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.button_login}
            onClick={() => { history.push('/login'); }}
          >
            Log in Now
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default EmailCheckingPage;
