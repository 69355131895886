import CreateIcon from '@material-ui/icons/Create';
import { SideBarData } from 'src/components/SideBar/SideBar';
import getRolesFromJwt from 'src/common/functions/getRolesFromJwt';

const getCompanySideBarData = (companyId: string) => {
  const checkIsCompanyOwener = () => {
    const roles = getRolesFromJwt();
    return roles.includes(`Company Manager,${companyId}`);
  };

  const CompanySideBarData:SideBarData[] = [
    {
      key: 1,
      title: 'Edit company name, description, type',
      path: `/companies/${companyId}/edit`,
      // eslint-disable-next-line react/react-in-jsx-scope
      icon: <CreateIcon className="sideBarIcon" />,
      isSectionOwner: checkIsCompanyOwener(),
    },
  ];
  return CompanySideBarData;
};

export default getCompanySideBarData;
