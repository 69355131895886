import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { darkBlue, skyBlue, darkSkyBlue, white } from 'src/app/color';

const useStyles = makeStyles((theme: Theme) => createStyles({
  companyInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },
  companyName: {
    display: 'flex',
    alignItems: 'center',
    height: '100px',
    fontWeight: 'bold',
    fontSize: '28px',
    color: darkBlue,
    wordBreak: 'break-all',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
  },
  companyInfo: {
    width: '80%',
    height: '360px',
    marginLeft: '20%',
    marginRight: '20%',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '10px',
    backgroundColor: white,
  },
  companyDes: {
    fontWeight: 'bold',
    alignItems: 'center',
    textAlign: 'left',
    lineHeight: '1.5em',
    paddingTop: '5%',
    paddingLeft: '15%',
    paddingRight: '15%',
    maxLength: '250',
  },
  ellipsis: {
    border: 'none',
    backgroundColor: 'transparent',
    fontWeight: 'bold',
  },
  ellipsisOnName: {
    border: 'none',
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    fontSize: '28px',
    color: darkBlue,
  },
  editButtonWrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editButton: {
    marginRight: '10%',
    height: '40px',
    backgroundColor: skyBlue,
    color: white,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: darkSkyBlue,
    },
    [theme.breakpoints.down('md')]: {
      width: '40px',
      fontSize: 0,
    },
  },
  edit_icon: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '9px',
    },
  },
}));

export default useStyles;
