import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { darkBlue } from 'src/app/color';

const useStyles = makeStyles((theme: Theme) => createStyles({
  arrow: {
    left: '0px',
    top: '0px',
    height: '1.2em',
    width: '1em',
    color: darkBlue,
    float: 'left',
    marginRight: '10px',
  },
  section: {
    margin: '0px 0px 12px 0px',
    height: '35px',
  },
  name: {
    fontSize: '18px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    float: 'left',
    color: darkBlue,
    '&:hover': {
      cursor: 'pointer',
    },
    maxWidth: '500px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
    },
  },
  more_icon: {
    float: 'left',
    display: 'inline-block',
    color: darkBlue,
  },
  add_icon: {
    color: darkBlue,
  },
  todo_add_button: {
    marginLeft: '15px',
    marginRight: '-5px',
    color: darkBlue,
  },
}));

export default useStyles;
