import React from 'react';
import Box from '@material-ui/core/Box';
import { TodoContextProvider } from 'src/context/TodoContext';

import TodoSet from './components/TodoSet/TodoSet';

function TodoPanelPage() {
  return (
    <TodoContextProvider>
      <Box ml={5}>
        <TodoSet />
      </Box>
    </TodoContextProvider>
  );
}

export default TodoPanelPage;
