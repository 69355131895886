import { createStyles, makeStyles } from '@material-ui/core/styles';
import { lightYellow, warmYellow } from 'src/app/color';

export default makeStyles(() => createStyles({
  error__invitation: {
    visibility: 'hidden',
  },
  error__invitation__active: {
    visibility: 'visible',
    backgroundColor: lightYellow,
    textJustify: 'auto',
    border: '1px solid',
    borderColor: warmYellow,
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'red',
    textAlign: 'center',
  },
}));
