import React from 'react';
import ITodoList from 'src/common/interfaces/Dto/Todo/TodoList/ITodoList';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import useStyles from './Section.style';
import SectionContent from './SectionContent';
import SectionHeader from './SectionHeader';

interface TodoListDetail {
  index: number,
  todoList: ITodoList,
}

function Section({ todoList, index }: TodoListDetail) {
  const classes = useStyles();
  const { todoListTitle, id } = todoList;

  return (
    <Draggable key={id} draggableId={`${todoList.id.toString()}list`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          data-testid={`${todoList.id.toString()}listColumn`}
        >
          <div
            {...provided.dragHandleProps}
            className={classes.sectionHeader}
          >
            <SectionHeader title={todoListTitle} id={id} />
          </div>
          <Droppable droppableId={id.toString()} type="task">
            {(providedItem) => (
              <div
                ref={providedItem.innerRef}
                {...providedItem.droppableProps}
                className={classes.sectionContainer}
              >
                <SectionContent todoList={todoList} />
                {providedItem.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
}

export default Section;
