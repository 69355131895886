import React, { memo } from 'react';
import useStyles from './Contribution.style';

interface PropsType {
  dayOfWeek: string,
  // eslint-disable-next-line react/no-unused-prop-types
  percentage: number,
  setChooseDay: Function,
  handleClick: Function,
  id: string,
}

function Contribution(props: PropsType) {
  const { dayOfWeek, id, setChooseDay, handleClick } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.contribution}>
      <div className={classes.numberOfContributionsWrapper}>
        <button
          type="button"
          aria-label={dayOfWeek}
          className={classes.numberOfContributions}
          onClick={() => { setChooseDay(dayOfWeek); handleClick(); }}
          id={id}
        />
      </div>
      <div className={classes.dayOfWeek}>{dayOfWeek}</div>
    </div>
  );
}

export default memo(Contribution);
