/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Button,
  Typography,
  TextField,
  SvgIcon,
  IconButton,
} from '@material-ui/core';
import crossIcon from 'src/assets/svgs/crossIcon.svg';
import { ReactComponent as emailIcon } from 'src/assets/svgs/emailIcon.svg';
import { Link } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { backButton } from 'src/assets/buttons';
import useStyles from './PasswordRecovaryForm.style';
import formConfig from './config';
import { useRecoveryPassword, useExtraFormConf } from './hooks';

function PasswordRecoveryForm({ freezeTime, setCanClick, canClick }: any) {
  const classes = useStyles();

  const { validationSchema, handleOnSubmit, warning } = useRecoveryPassword(setCanClick);
  const { getStartAdornment, getErrorMsg } = useExtraFormConf();
  const { emailInput } = formConfig;

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >
      {() => (
        <Form>
          warning.shown
          ? (
          <div className={classes.warning} data-testid="warningMessage">
            <img src={crossIcon} alt="crossIcon" />
            <Typography className={classes.warning_message}>
              {warning.message}
            </Typography>
          </div>
          )
          : null

          <div className={classes.label}>
            We&apos;ll send a recovery link to your email
          </div>

          <Field
            {...emailInput}
            as={TextField}
            helperText={getErrorMsg('email')}
            InputProps={getStartAdornment(<SvgIcon component={emailIcon} viewBox="0 0 32 32" />)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.send_button}
            disabled={!canClick}
          >
            Send recovery link
            {canClick ? ' ' : `(${freezeTime}${warning.message})`}
          </Button>

          <Link to="/login">
            <IconButton
              type="submit"
              className={classes.back_button}
            >
              <SvgIcon
                component={backButton}
                viewBox="0 0 48 48"
                fontSize="large"
              />
            </IconButton>
          </Link>
        </Form>
      )}
    </Formik>
  );
}
export default PasswordRecoveryForm;
