import React, { ChangeEvent, useContext, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { StatusCodes } from 'http-status-codes';
import { Button, TextField } from '@material-ui/core';
import { Dialog, DialogContent } from '@mui/material';
import { createTodoList } from 'src/utils/apiUtils';
import { TodoContext } from 'src/context/TodoContext';
import useStyles from './SectionAdd.style';

function SectionAdd() {
  const classes = useStyles();
  const { companyId, projectId, setUpdate } = useContext(TodoContext);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [requestErr, setRequestErr] = useState(false);
  const [titleErr, setTitleErr] = useState(false);

  const addSection = async () => {
    const todoListTitle = title || 'Untitled section';
    try {
      const { status } = await createTodoList({ companyId, projectId, todoListTitle });
      if (status === StatusCodes.OK) {
        setUpdate((prevState) => prevState + 1);
        setOpen(false);
        setTitle('');
      }
    } catch (error: any) {
      if (error.response?.status === StatusCodes.BAD_REQUEST) {
        setRequestErr(true);
      }
    }
  };

  const handleTitleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (val.length <= 255) {
      setTitle(val);
      setTitleErr(false);
    } else {
      setTitleErr(true);
    }
  };

  return (
    <>
      {requestErr && (
        <div data-testid="warning-request">
          Error: 400. Add section failed.
        </div>
      )}

      {titleErr && (
      <div data-testid="warning-length-exceed">
        At most 255 characters.
      </div>
      )}

      <div>
        <button
          type="button"
          className={classes.addSection}
          onClick={() => setOpen(true)}
        >
          <AddIcon className={classes.addIcon} />
          <span className={classes.buttonText}>  Add section  </span>
        </button>
        <Dialog
          open={open}
          classes={{ paper: classes.modal }}
        >
          <DialogContent className={classes.modalContent}>
            <TextField
              fullWidth
              autoFocus
              placeholder="Enter a Section Name"
              variant="outlined"
              onChange={handleTitleChange}
              value={title}
              inputProps={{ className: classes.input }}
              data-testid="textField"
            />
            <div className={classes.modalButtonPanel}>
              <div className={classes.buttonContainer}>
                <Button
                  fullWidth
                  style={{ textTransform: 'none' }}
                  variant="contained"
                  onClick={() => addSection()}
                  size="large"
                  color="primary"
                  data-testid="AddBtn"
                >
                  Add
                </Button>
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  fullWidth
                  style={{ textTransform: 'none' }}
                  variant="contained"
                  size="large"
                  onClick={() => setOpen(false)}
                  color="default"
                  data-testid="CloseBtn"
                >
                  Close
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default SectionAdd;
