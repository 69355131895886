import React from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledBox = withStyles({
  root: {
    color: 'red',
  },
})(Box);

function StyledErrorMessage({ message, className }: any) {
  return (
    <StyledBox className={className}>
      <Typography>{message}</Typography>
    </StyledBox>
  );
}

export default StyledErrorMessage;
