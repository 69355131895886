import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { blueGrey, white } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  selector: {
    position: 'relative',
  },

  select: {
    width: '115px',
    height: '33px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: white,
    border: 'transparent',
    position: 'relative',
  },

  arrow: {
    position: 'absolute',
    left: '5px',
    width: '18px',
    height: '18px',
    color: blueGrey,
  },

  options: {
    borderRadius: '5px',
    boxShadow: '0 2px 2px 0 rgba(125, 88, 159, 0.19)',
    backgroundColor: white,
    position: 'absolute',
    zIndex: 9999,
    overflow: 'hidden',
  },
}));

export default useStyles;
