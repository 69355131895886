import React from 'react';
import useStyles from './PriorityLabel.style';

interface PropsType {
  priority: string;
  disabled: boolean;
}

function PriorityLabel(props: PropsType) {
  const classes = useStyles();
  const { priority, disabled } = props;

  return (
    <div className={disabled ? classes.option_disabled : classes.option} id={priority}>
      {priority}
    </div>
  );
}

export default PriorityLabel;
