import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import Crew from 'src/components/Crew/Crew';
import CompanyInfor from 'src/components/CompanyInfo/CompanyInfo';

import { CompanyContextProvider } from 'src/context/CompanyContext';
import InviteMemberPopupWindow from './components/InviteMemberPopupWindow/InviteMemberPopupWindow';
import useStyles from './CompanyPage.style';

import CompanyPageHeader from './components/CompanyPageHeader';

function CompanyPage() {
  const classes = useStyles();
  return (
    <CompanyContextProvider>
      <CompanyPageHeader />
      <div className={classes.mainPaper}>
        <Switch>
          <Route exact path="/companies/:companyId(\d+)/company/companyinfo" component={CompanyInfor} />
          <Route path="/companies/:companyId(\d+)/company/companycrew" component={Crew} />
          <Redirect to="/companies/:companyId(\d+)/company/companycrew" />
        </Switch>
      </div>
      <InviteMemberPopupWindow />
    </CompanyContextProvider>
  );
}
export default CompanyPage;
