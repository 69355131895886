import React from 'react-dom';

function Files() {
  return (
    <div>
      In Developing
    </div>
  );
}

export default Files;
