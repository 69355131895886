import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, TextField } from '@material-ui/core';
import { useFetch, usePathVariable } from 'src/common/customHooks';
import * as apiProjectInvitation from 'src/utils/apiProjectInvitation';
import { getEmployeeInfo, fetchProjectMemberList } from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';
import ICompanyMember from 'src/common/interfaces/ICompanyMember';
import IProjectInfo from 'src/common/interfaces/IProjectInfo';
import useStyles from './InvitePopUp.style';
import SelectedCompanyMembers from './components/SelectedMemberList/SelectedCompanyMembers';
import SelectCompanyMembers from './components/SelectCompanyMembers/SelectCompanyMembers';

interface PropsType {
  setInvitePopupDisplay: Function;
  setProjectUserNames: Function;
  projectUserNames: string[];
  projectInfo: IProjectInfo;
}

function InvitePopUp(props: PropsType) {
  const classes = useStyles();
  const { setProjectUserNames, projectUserNames, setInvitePopupDisplay, projectInfo } = props;
  const { companyId, projectId } = usePathVariable();

  const { data: companyMemberList } = useFetch(getEmployeeInfo, [companyId], [] as ICompanyMember[]);

  const projectOwner = companyMemberList.find((owner: ICompanyMember) => owner.id.toString() === projectInfo.leaderId.toString());

  const [focused, setFocused] = useState(false);
  const [selectedMemberList, setSelectedMemberList] = useState([] as ICompanyMember[]);
  const [addAllSelectedMemberId, setAddAllSelectedMemberId] = useState([] as string[]);
  const [selectableMemberList, setSelectableMemberList] = useState([] as ICompanyMember[]);
  const [input, setInput] = useState('');

  const getProjectMemberList = async () => {
    const membersInfoResponse = await fetchProjectMemberList(companyId, projectId);
    if (membersInfoResponse.status === StatusCodes.OK) {
      setAddAllSelectedMemberId(membersInfoResponse.data.map((e: ICompanyMember) => e.id.toString()));
    }
  };
  const closePopup = () => {
    setInvitePopupDisplay(false);
  };

  const selectorRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: any) => {
    if (selectorRef.current && selectorRef.current.contains(e.target)) {
      return;
    }
    setFocused(false);
  };

  const handleInputOrder = (event: any) => {
    setInput(event.target.value);
  };

  const setAbleToDisplay = () => {
    if (input !== '') {
      return companyMemberList.filter((e) => e.name.includes(input) && !addAllSelectedMemberId.includes(e.id.toString()));
    }
    return companyMemberList.filter((e) => !addAllSelectedMemberId.includes(e.id.toString()));
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getProjectMemberList();
  }, []);

  useEffect(() => {
    setSelectableMemberList((setAbleToDisplay()));
  }, [addAllSelectedMemberId, companyMemberList, input, selectedMemberList]);

  const onFocus = () => setFocused(true);
  const inviteMembersSubmit = async () => {
    const addProjectsMembers = await apiProjectInvitation.addProjectsMembers(companyId, projectId, selectedMemberList.map((e: ICompanyMember) => e.id.toString()).toString());
    if (addProjectsMembers.status === StatusCodes.OK) {
      setInvitePopupDisplay(false);
      setProjectUserNames(projectUserNames.concat(selectedMemberList.map((e: ICompanyMember) => e.name.toString())));
    }
    window.location.reload();
  };

  return (
    <div className={classes.popup}>
      <div className={classes.invite_window}>
        <div className={classes.invite_header}>
          <Typography className={classes.invite_title}>
            {`Share ${projectInfo.name}`}
          </Typography>
          <button
            type="button"
            aria-label="close-invite-button"
            className={classes.close_btn}
            onClick={closePopup}
          >
            <CloseIcon className={classes.close_icon} fontSize="large" />
          </button>
        </div>
        <div className={classes.invite_content}>
          <div className={classes.search_bar}>
            <Typography className={classes.invite_form_title}>
              Invite with name
            </Typography>

            <div ref={selectorRef} className={classes.selector}>
              <TextField
                className={focused ? classes.searchBar_focused : ''}
                placeholder="Add project members by name..."
                fullWidth
                variant="outlined"
                onFocus={onFocus}
                onChange={handleInputOrder}
              />
              <div className={classes.member_options}>
                {focused ? selectableMemberList.map((member: ICompanyMember) => (
                  <SelectCompanyMembers
                    key={member.id}
                    member={member}
                    selectedMemberList={selectedMemberList}
                    setSelectedMemberList={setSelectedMemberList}
                    setFocused={setFocused}
                    addAllSelectedMemberId={addAllSelectedMemberId}
                    setAddAllSelectedMemberId={setAddAllSelectedMemberId}
                  />
                )) : null}
              </div>
            </div>
          </div>

          <div className={classes.selected_member_display}>
            {projectOwner ? (
              <SelectedCompanyMembers
                key={projectOwner.id}
                selectedMember={projectOwner}
                projectOwnerId={projectOwner.id}
                selectedMemberList={selectedMemberList}
                setSelectedMemberList={setSelectedMemberList}
                addAllSelectedMemberId={addAllSelectedMemberId}
                setAddAllSelectedMemberId={setAddAllSelectedMemberId}
              />
            ) : null}
            {selectedMemberList.map((selectedMember: ICompanyMember) => (
              <SelectedCompanyMembers
                key={selectedMember.id}
                selectedMember={selectedMember}
                projectOwnerId={projectOwner?.id}
                selectedMemberList={selectedMemberList}
                setSelectedMemberList={setSelectedMemberList}
                addAllSelectedMemberId={addAllSelectedMemberId}
                setAddAllSelectedMemberId={setAddAllSelectedMemberId}
              />
            ))}
          </div>
        </div>
        <div className={classes.invite_fonter}>
          <button
            type="button"
            className={classes.share_btn}
            onClick={inviteMembersSubmit}
          >
            Share
          </button>
        </div>
      </div>
    </div>
  );
}

export default InvitePopUp;
