import React from 'react';
import ICompanyMember from 'src/common/interfaces/ICompanyMember';
import UserProfileIcon from 'src/components/UserProfileIcon/UserProfileIcon';
import useStyles from './SelectCompanyMembers.style';

interface PropsType {
  member: ICompanyMember;
  selectedMemberList: any;
  setSelectedMemberList: Function;
  setFocused: Function;
  addAllSelectedMemberId: string[];
  setAddAllSelectedMemberId: Function;

}
function SelectCompanyMembers(props: PropsType) {
  const classes = useStyles();
  const { member, selectedMemberList, setSelectedMemberList, setFocused, addAllSelectedMemberId, setAddAllSelectedMemberId } = props;

  const selectMembers = () => {
    setFocused(false);
    setSelectedMemberList(selectedMemberList.concat(member));
    setAddAllSelectedMemberId(addAllSelectedMemberId.concat(member.id.toString()));
  };

  return (
    <button
      className={classes.optionBtn}
      type="button"
      onClick={selectMembers}
    >
      <UserProfileIcon
        name={member.name}
        textSizeRatio={1.5}
        size="35"
      />
      <div className={classes.members_name}>
        {member.name}
      </div>
    </button>

  );
}

export default SelectCompanyMembers;
