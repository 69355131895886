import { Buffer } from 'buffer';

const getProjectIdsFromJwt = () => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken !== undefined) {
    const tokenDecodablePart = accessToken === null ? '' : accessToken.split('.')[1];
    const decoded = JSON.parse(Buffer.from(tokenDecodablePart, 'base64').toString());
    const { projectIds } = decoded;
    const projectIdsList: string[] = projectIds.map((projectIdsObj: any) => projectIdsObj.toString());
    return projectIdsList;
  }
  return [];
};
export default getProjectIdsFromJwt;
