import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { awLightPurple } from 'src/app/color';

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) => createStyles({
  hide: {
    display: 'none',
  },
  logo: {
    padding: '10px',
    marginBottom: '10px',
  },
  companySelector: {
    flex: 1,
  },
  menuButton: {
    '&:hover': {
      backgroundColor: awLightPurple,
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerHeaderOpen: {
    padding: '30px 0px 30px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  drawerHeaderClose: {
    padding: '30px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) - 3,
  },
  footer: {
    marginTop: 'auto',
  },
}));

export default useStyles;
