import { Button, TextField } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useContext } from 'react';
import compare from 'src/common/functions/compare';
import IProjectMember from 'src/common/interfaces/IProjectMember';
import { TodoContext } from 'src/context/TodoContext';
import * as Yup from 'yup';
import ProjectMember from '../Assignee/ProjectMember';
import useStyles from './AssigneeForm.style';

interface AssigneeFormProps {
  assignees: IProjectMember[];
  assigneesIds: string;
  setNewTodoItem: Function;
  setIsAssigneesOpen: Function;
  handleOnClickFromMembersList: Function;
}

interface Values {
  emailInput: string;
  shareOnly: boolean;
}

function AssigneeForm({ assignees, assigneesIds, setNewTodoItem, setIsAssigneesOpen, handleOnClickFromMembersList }: AssigneeFormProps) {
  const classes = useStyles();
  const { projectMembers, setUpdate } = useContext(TodoContext);

  const validateEmail = (emailInput:string) => {
    let error;
    if (assignees && assignees.filter((assignee) => assignee.email.toString() === emailInput).length === 1) {
      error = 'Already assigned to this task';
    }
    return error;
  };

  const handleOnSubmit = async (values : Values) => {
    const newAssignee = projectMembers.filter((member) => member.email.toString() === values.emailInput)[0];
    let newAssigneesIds;
    if (assigneesIds && newAssignee) {
      newAssigneesIds = assigneesIds.concat(',', newAssignee.id.toString());
    }
    if (!assigneesIds && newAssignee) {
      newAssigneesIds = newAssignee.id.toString();
    }
    await setNewTodoItem(undefined, undefined, newAssigneesIds);
    setUpdate((prevState) => prevState + 1);
    setIsAssigneesOpen(false);
  };

  const validationSchema = Yup.object({
    emailInput: Yup.string().trim().required('Email Required!').email('Please enter a valid email'),
  });

  const availbleMembers = projectMembers.filter((member) => !assignees.includes(member));

  return (
    <Formik
      initialValues={{
        emailInput: '',
        shareOnly: false,
      }}
      onSubmit={handleOnSubmit}
      validationSchema={validationSchema}
    >

      {({ isSubmitting, errors, values, isValid }) => (
        <Form className={classes.assigneeForm}>
          <legend className={classes.title}>Assignee</legend>
          <div className={classes.emailInputSection}>
            <Field
              as={TextField}
              name="emailInput"
              variant="outlined"
              margin="none"
              placeholder="Please enter email"
              size="small"
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              className={classes.emailInput}
              validate={validateEmail}
              helperText={errors.emailInput}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting || values.emailInput === '' || !isValid}
              className={classes.sendBtn}
            >
              {isSubmitting ? 'Sending...' : 'Send'}
            </Button>
          </div>
          <div className={classes.projectMembersList}>
            {availbleMembers.sort(compare).map((member) => (
              <ProjectMember
                key={member.id}
                id={member.id}
                name={member.name}
                email={member.email}
                onClick={handleOnClickFromMembersList}
              />
            ))}
          </div>
          <div className={classes.shareOnly}>
            <label htmlFor="shareOnly-id">
              <Field
                type="checkbox"
                name="shareOnly"
                id="shareOnly-id"
                className={classes.checkbox}
              />
              Share Only this task (hide all other project data)
            </label>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default AssigneeForm;
