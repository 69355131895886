import React, { useEffect, useState } from 'react';
import { Avatar, Grid, Toolbar } from '@material-ui/core';
import { stringAvatar, stringToColor } from 'src/common/functions/toDoPageHeaderFunction';
import timeConvert from 'src/common/functions/timeCovert';
import EllipsisTextArea from 'src/components/EllipsisTextArea/EllipsisTextArea';
import useStyles from './MessageItem.style';

interface PropsType {
  messageCategoryEmoji: string | null;
  messageCategoryName: string | null;
  messageTitle: string;
  content: string | null;
  posterUser: string;
  postTime: string;
}

function MessageItem(props: PropsType) {
  const classes = useStyles();
  const { messageCategoryEmoji, messageCategoryName, messageTitle, content, posterUser, postTime } = props;
  const [timeDiff, setTimeDiff] = useState('');
  let timeoutId: NodeJS.Timeout;
  const refreshTime = (createdTime: string) => {
    if (timeoutId) {
      // clear time
      clearTimeout(timeoutId);
    }
    const { timeDisplay, interval } = timeConvert(createdTime);
    setTimeDiff(timeDisplay);
    if (interval != null) {
      timeoutId = setTimeout(() => {
        // have set time
        refreshTime(createdTime);
      }, interval);
    }
  };
  useEffect(() => {
    refreshTime(postTime);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return (
    <Toolbar variant="dense" className={classes.row}>
      <Grid item className={classes.category} xs={2}>
        <div className={classes.emoji}>{messageCategoryEmoji}</div>
        <div>{messageCategoryName}</div>
      </Grid>
      <Grid item className={classes.title} xs={3}>
        <EllipsisTextArea content={messageTitle} />
      </Grid>
      <Grid item className={classes.message} xs={5}>
        <EllipsisTextArea content={content} />
      </Grid>
      <Grid item className={classes.publishedBy} xs={2}>
        <Avatar
          style={{ backgroundColor: stringToColor(posterUser) }}
          className={classes.avatar}
        >
          {stringAvatar(posterUser)}
        </Avatar>
      </Grid>
      <Grid item className={classes.publishedTime} xs={2}>{timeDiff}</Grid>
    </Toolbar>
  );
}

export default MessageItem;
