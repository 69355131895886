import React, { useEffect, useRef } from 'react';
import ArrowDropDownOutlined from '@material-ui/icons/ArrowDropDown';
import PriorityOption from './components/PriorityOption/PriorityOption';
import useStyles from './PrioritySelector.style';
import PriorityLabel from './components/PriorityOption/components/PriorityLabel';

const OPTIONS = [{
  priority: 'Low',
}, {
  priority: 'Medium',
}, {
  priority: 'High',
}];
interface PropsType {
  select: string;
  isOpen: boolean;
  handleSelect: Function;
  setIsOpen: Function;
  disabled: boolean;
}
function PrioritySelector(props: PropsType) {
  const { select, handleSelect, isOpen, setIsOpen, disabled } = props;
  const classes = useStyles();

  const selectorRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (e: any) => {
    if (selectorRef.current && selectorRef.current.contains(e.target)) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={classes.selector} ref={selectorRef}>
      <button
        className={classes.select}
        type="button"
        value={select}
        onClick={handleClick}
        disabled={disabled}
      >
        <ArrowDropDownOutlined className={classes.arrow} />
        <PriorityLabel priority={select} disabled={disabled} />
      </button>
      {
        isOpen
          ? (
            <div className={classes.options}>
              {OPTIONS.map((option) => (
                <PriorityOption
                  priority={option.priority}
                  onClick={() => handleSelect(option.priority)}
                  select={select}
                  key={option.priority}
                  disabled={disabled}
                />
              ))}
            </div>
          )
          : null
      }
    </div>
  );
}

export default PrioritySelector;
