import { makeStyles } from '@material-ui/core/styles';
import { deepDarkBlue } from 'src/app/color';

const useStyles = makeStyles(() => ({
  container: {
    margin: '15px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Poppins-regular',
    fontSize: '24px',
    color: deepDarkBlue,
    fontWeight: 600,
    lineHeight: 1.75,
    alignSelf: 'flex-start',
    marginTop: 0,
    marginBottom: '48px',
  },
  columns: {
    width: '577.4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    position: 'relative',
  },
  loading: {
    width: '577.4px',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
