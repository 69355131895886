import React, { useState, useEffect, useRef } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography, TextField, Button, Select } from '@material-ui/core';
import { Form, Formik, Field } from 'formik';
import listIcon from 'src/assets/svgs/listIcon.svg';
import boardIcon from 'src/assets/svgs/boardIcon.svg';
import useStyles from './CreateBlankProjectPopup.style';
import { useCreateProjectForm, useExtraFormConf } from './hooks';

interface CreateBlankProjectPopupProps {
  showBlankProject: boolean;
  toggleShowBlankProject: any;
  togglePopup: any;
}

function CreateBlankProjectPopup({ showBlankProject, toggleShowBlankProject, togglePopup }:CreateBlankProjectPopupProps) {
  const classes = useStyles();
  const { getErrorMsg } = useExtraFormConf();
  const { initialValues, handleOnSubmit, validationSchema } = useCreateProjectForm();

  const [isPrivateString, setIsPrivateString] = useState('false');

  const [defaultView, setDefaultView] = useState('List');

  const textInputRef = useRef<any>();

  useEffect(() => {
    textInputRef.current.focus();
  }, [toggleShowBlankProject]);

  return (
    <div className={showBlankProject ? '' : classes.content_hidden}>
      <div className={classes.popup_header}>
        <button
          type="button"
          aria-label="back-popup-button"
          className={classes.back_btn}
          onClick={toggleShowBlankProject}
        >
          <KeyboardBackspaceRoundedIcon className={classes.back_icon} fontSize="large" />
        </button>

        <button
          type="button"
          aria-label="close-popup-button"
          className={classes.close_btn}
          onClick={togglePopup}
        >
          <CloseIcon className={classes.close_icon} fontSize="large" />
        </button>
      </div>

      <div className={classes.form_container}>
        <Typography className={classes.popup_title}>
          New project
        </Typography>

        <Formik
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <legend className={classes.input_label}>
                Project name
              </legend>

              <Field
                as={TextField}
                name="name"
                variant="outlined"
                margin="none"
                fullWidth
                inputRef={textInputRef}
                placeholder="Project Name"
                helperText={getErrorMsg('name')}
                inputProps={{
                  className: values.name === '' ? classes.nameInput_empty : classes.nameInput,
                  'aria-label': 'name',
                }}
              />
              <legend className={classes.input_label}>
                Project details
              </legend>

              <Field
                as={TextField}
                name="description"
                multiline
                fullWidth
                rows={4}
                variant="outlined"
                placeholder="Describe a few words about your project..."
                inputProps={{
                  'aria-label': 'description',
                }}
              />

              <legend className={classes.input_label}>
                Privacy
              </legend>

              <Field
                as={Select}
                name="isPrivateString"
                variant="outlined"
                value={isPrivateString}
                aria-label="privacy-selector"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIsPrivateString(event.target.value);
                  setFieldValue('isPrivateString', event.target.value);
                }}
                data-testid="privacy-selector"
                fullWidth
              >
                <MenuItem value="false" data-testid="select-public">Public</MenuItem>
                <MenuItem value="true" data-testid="select-private">Private</MenuItem>
              </Field>

              <legend className={classes.input_label}>
                Default View
              </legend>

              <div className={classes.view_selectors}>
                <Field
                  as="button"
                  type="button"
                  value="List"
                  name="defaultView"
                  className={defaultView === 'List' ? classes.view_select_btn_selected : classes.view_select_btn}
                  onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setDefaultView(event.currentTarget.value);
                    setFieldValue('defaultView', event.currentTarget.value);
                  }}
                >
                  <img src={listIcon} className={classes.view_icon} alt="list icon" />
                  <span className={classes.view_label}>List</span>
                </Field>

                <Field
                  as="button"
                  type="button"
                  value="Board"
                  name="defaultView"
                  className={defaultView === 'Board' ? classes.view_select_btn_selected : classes.view_select_btn}
                  onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setDefaultView(event.currentTarget.value);
                    setFieldValue('defaultView', event.currentTarget.value);
                  }}
                >
                  <img src={boardIcon} className={classes.view_icon} alt="board icon" />
                  <span className={classes.view_label}>Board</span>
                </Field>
              </div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                aria-label="submit-button"
                className={classes.submit_button}
                disabled={isSubmitting || values.name === ''}
              >
                {isSubmitting ? 'Creating...' : 'Create project'}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default CreateBlankProjectPopup;
