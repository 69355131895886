import React from 'react';
import { Button, Container } from '@material-ui/core';
import styles from './ProjectMembers.module.scss';
import useShowAndClose from './hooks';
import useStyles from './ProjectMembers.style';
import ProjectMember from './components/ProjectMember/ProjectMember';
import ProjectInvitation from './components/ProjectInvitation/ProjectInvitation';

interface Props {
  membersInfo: Member[],
  getMembers: Function,
  isProjectMember: boolean;
  isCompanyManager: boolean;
}

interface Member {
  name: string,
  title: string,
  email: string
}

function ProjectMembers({ membersInfo, getMembers, isProjectMember, isCompanyManager }: Props) {
  const { active, onShow, onClose } = useShowAndClose();
  const classes = useStyles();
  const addPeopleButton = () => (
    isProjectMember || isCompanyManager ? (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.root}
        data-testid="addPeople"
        onClick={onShow}
      >
        + Add people
      </Button>
    ) : (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.root}
        data-testid="addPeople"
        disabled
      >
        + Add people
      </Button>
    )
  );

  const memberDisplay = membersInfo ? membersInfo.map((e: any) => <ProjectMember key={e.email} member={e} />)
    : <div>No Project Members</div>;
  return (
    <Container>
      <div className={classes.peopleList}>
        <div className={styles.wrapper}>
          <div className={styles.button}>
            {addPeopleButton()}
          </div>
        </div>
        {active ? <ProjectInvitation closeForm={onClose} getMembers={getMembers} /> : null}
        <div className={classes.bottomContent}>
          {memberDisplay}
        </div>
      </div>
    </Container>
  );
}

export default ProjectMembers;
