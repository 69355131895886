import React from 'react';
import { Card, CardContent, Typography, TextField, CardMedia } from '@material-ui/core';
import IContributionActivities from 'src/common/interfaces/Dto/Todo/TodoItem/IContributionActivities';
import { ReactComponent as badge } from 'src/assets/svgs/badge-check.min.svg';
import useStyles from './ContributionActivityItem.style';

const replaceEntexdedCharacters = (title: string) => {
  const returnTitle = title.substring(0, 27).concat('...');
  return returnTitle;
};

const getMonthFromString = (month: string) => {
  const returnDate = new Date(Date.parse(month?.concat(' 1, 2012'))).getMonth() + 1;
  return returnDate;
};

const getDate = (completedTime: string) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = completedTime?.split('T')[0];
  const month = months[getMonthFromString(date?.split('-')[1]) - 1];
  const day = date?.split('-')[2];
  const year = date?.split('-')[0];
  const returnDate = month?.concat(' ').concat(day).concat(', ').concat(year);
  return returnDate;
};

function ContributionActivityItem({ taskName, dueDate }: IContributionActivities) {
  const title = taskName;
  const date = getDate(dueDate);
  const [value, setValue] = React.useState('');

  const handleChange = (event: any) => {
    const description = event.target.value;
    setValue(description);
    if (description.length > 54) {
      setValue(description.substring(0, 50));
      setValue(description.concat('...'));
    }
  };
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <CardMedia component={badge} className={classes.badge} />
        <Typography gutterBottom variant="h5">
          {title.length > 28 ? replaceEntexdedCharacters(title) : title}
        </Typography>
        <TextField
          className={classes.textField}
          id="outlined-multiline-flexible"
          multiline
          variant="outlined"
          value={value}
          inputProps={{ maxLength: 55, cols: 20, maxRows: 2, wrap: 'hard' }}
          placeholder="Please type here..."
          onChange={handleChange}
        />
        <Typography variant="body2" className={classes.date}>
          {date}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ContributionActivityItem;
