import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { stoneGrey } from 'src/app/color';

const useStyles = makeStyles((theme: Theme) => createStyles({
  scheduleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'white',
    padding: '50px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingBottom: '30px',
    borderBottom: '2px solid',
    width: '90%',
  },
  newEventBtn: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '25px',
    color: 'white',
    fontWeight: 'bold',
    height: '35px',
    width: '110%',
  },
  scheduleTitle: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '35%',
    color: stoneGrey,
    fontFamily: 'Poppins',
    fontSize: '50px',
    flexGrow: 5,
  },
  calendarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '90%',
  },
  scheduleCalendar: {
    marginTop: '50px',
    width: '100%',
  },
  eventListContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-star',
    alignItems: 'left',
  },
  eventTime: {
    padding: '20px',
    width: '20%',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  eventContext: {
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  addEventContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    border: 'solid 1px black',
    borderRadius: '5px',
  },
  addEventName: {
    textAlign: 'left',
    padding: '10px 0px',
    borderBottom: '1px solid black',
    paddingLeft: '20px',
  },
  addEventContext: {
    paddingLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  allDayEvent: {
    paddingLeft: '-20px',
  },
  eventItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '10px',
  },
  eventItemTitle: {
    width: '60px',
  },
  eventAssginees: {
    borderRadius: '25px',
  },
  eventNotesInput: {
    width: '80%',
  },
  addEventBtnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'felx-star',
    textAlign: 'left',
    paddingBottom: '20px',
    paddingLeft: '20px',
    marginTop: '10px',
  },
  postEventBtn: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '25px',
    color: 'white',
    fontWeight: 'bold',
    height: '35px',
    width: '15%',
    marginRight: '20px',
  },
  postCancelBtn: {
    backgroundColor: 'white',
    borderRadius: '25px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    height: '35px',
    width: '10%',
  },
}));

export default useStyles;
