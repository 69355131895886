/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField, SvgIcon } from '@material-ui/core';
import { Field } from 'formik';
import { ReactComponent as nameIcon } from 'src/assets/svgs/nameIcon.svg';
import { ReactComponent as emailIcon } from 'src/assets/svgs/emailIcon.svg';
import { useSignupForm, useExtraFormConf, useWizard } from './hooks';
import SignupErrorMessage from '../SignupErrorMessage/SignupErrorMessage';
import formConfig from './config';
import PasswordField from './PasswordField';

function SignupForm() {
  const { getStartAdornment, getErrorMsg } = useExtraFormConf();
  const { nameInput, emailInput } = formConfig;
  const { Wizard } = useWizard();
  const WizardStep = ({ children }: any) => children;
  const {
    initialValues,
    validationNameAndEmailSchema,
    validationPasswordSchema,
    handleCheckEmailOnSubmit,
    handleCreateAccountOnSubmit,
    signupError,
  } = useSignupForm();

  return (
    <Wizard initialValues={initialValues} onSubmit={() => {}}>
      <WizardStep validationSchema={validationNameAndEmailSchema} onSubmit={handleCheckEmailOnSubmit}>
        <SignupErrorMessage
          content={signupError.message}
          active={signupError.have}
          to="/login"
          hasLink={signupError.hasLink}
          linkContent={signupError.linkContent}
        />
        <Field
          {...nameInput}
          as={TextField}
          helperText={getErrorMsg('name')}
          InputProps={getStartAdornment(<SvgIcon component={nameIcon} viewBox="0 0 32 32" />)}
        />
        <Field
          {...emailInput}
          as={TextField}
          name="email"
          label="Your email"
          helperText={getErrorMsg('email')}
          InputProps={getStartAdornment(<SvgIcon component={emailIcon} viewBox="0 0 32 32" />)}
        />
        <Field
          {...emailInput}
          as={TextField}
          name="confirmEmail"
          label="Confirm your Email"
          helperText={getErrorMsg('confirmEmail')}
          InputProps={getStartAdornment(<SvgIcon component={emailIcon} viewBox="0 0 32 32" />)}
        />
      </WizardStep>
      <WizardStep validationSchema={validationPasswordSchema} onSubmit={handleCreateAccountOnSubmit}>
        <PasswordField />
      </WizardStep>
    </Wizard>
  );
}

export default SignupForm;
