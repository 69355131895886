import React, { useState, useContext } from 'react';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { CompanyContext } from 'src/context/CompanyContext';
import EditCompanyInfoPopupWindows from './components/EditCompanyInfoPopupWindows/EditCompanyInfoPopupWindows';
import useStyles from './CompanyInfo.style';

function CompanyInfo() {
  const classes = useStyles();
  const [shortNameDisplay, setShortNameDisplay] = useState(true);
  const [shortDesDisplay, setShortDesDisplay] = useState(true);

  const { isEditPanelOpen, setEditPanelOpen, isCompanyManager, companyName, companyDescription } = useContext(
    CompanyContext,
  );

  return (
    <div className={classes.companyInfoContainer}>
      <div className={classes.editButtonWrap}>
        <Button
          className={classes.editButton}
          startIcon={<EditIcon className={classes.edit_icon} />}
          variant="contained"
          data-testid="edit-button"
          disabled={!isCompanyManager}
          onClick={() => {
            setEditPanelOpen(!isEditPanelOpen);
          }}
        >
          Edit
        </Button>
      </div>
      <div className={classes.companyName}>
        <ClickAwayListener
          onClickAway={() => {
            setShortNameDisplay(true);
          }}
        >
          <div>
            {shortNameDisplay ? (
              <span>
                {companyName?.substring(0, 48)}
                {companyName?.length > 48 && (
                  <button
                    type="submit"
                    className={classes.ellipsisOnName}
                    onClick={() => {
                      setShortNameDisplay(false);
                    }}
                  >
                    ...
                  </button>
                )}
              </span>
            ) : (
              <div>{companyName}</div>
            )}
          </div>
        </ClickAwayListener>
      </div>
      <div className={classes.companyInfo}>
        <div className={classes.companyDes}>
          <ClickAwayListener
            onClickAway={() => {
              setShortDesDisplay(true);
            }}
          >
            <div>
              {shortDesDisplay ? (
                <p>
                  {companyDescription?.substring(0, 255)}
                  {companyDescription?.length > 255 && (
                    <button
                      type="submit"
                      className={classes.ellipsis}
                      onClick={() => {
                        setShortDesDisplay(false);
                      }}
                    >
                      ...
                    </button>
                  )}
                </p>
              ) : (
                <div>{companyDescription}</div>
              )}
            </div>
          </ClickAwayListener>
        </div>
        <EditCompanyInfoPopupWindows />
      </div>
    </div>
  );
}

export default CompanyInfo;
