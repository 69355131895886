import { makeStyles } from '@material-ui/core/styles';
import { black, lightRed } from 'src/app/color';

const useStyles = makeStyles(() => ({
  paper: {
    margin: '15px 13px 0px 13px',
    padding: '23px 23px 0px 23px',
    borderRadius: '10px',
    width: '265px',
    minHeight: '250px',
    maxHeight: '254px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  },
  itemsWrapper: {
    boxShadow: 'none',
  },
  emptyItemWrapper: {
    height: '230px',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyText: {
    fontFamily: 'Poppins-SemiBold',
    marginTop: '13px',
    color: 'rgba(0,0,0,0.5)',
    fontSize: 14,
  },
  divider: {
    backgroundColor: lightRed,
    maxWidth: 250,
  },
  title: {
    fontFamily: 'Poppins-SemiBold',
    fontSize: 16,
    color: black,
  },
}));

export default useStyles;
