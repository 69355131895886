import { makeStyles } from '@material-ui/core/styles';
import { darkGrey, white } from 'src/app/color';

const useStyles = makeStyles(() => ({
  helperText: {
    marginTop: 0,
    fontSize: '10px',
  },
  assigneeForm: {
    padding: '13px 16px 15px 18px',
    borderRadius: '5px',
    boxShadow: '0 2px 2px 0 rgba(125, 88, 159, 0.19)',
    backgroundColor: white,
  },
  title: {
    fontFamily: 'Poppins-medium',
    fontSize: '9px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: darkGrey,
    marginBottom: '10px',
  },
  emailInput: {
    width: '201px',
    height: '33px',
    marginRight: '15px',
  },
  resize: {
    fontSize: '12px',
  },
  sendBtn: {
    width: '81px',
    height: '33px',
    fontSize: '12px',
    textTransform: 'initial',
  },
  emailInputSection: {
    display: 'flex',
  },
  shareOnly: {
    fontSize: '10px',
    fontFamily: 'Poppins-medium',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.6,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: darkGrey,
    marginTop: '18px',
  },
  checkbox: {
    width: '8px',
    height: '8px',
    marginRight: '6px',
  },
  projectMembersList: {
    marginTop: '20px',
    transform: 'translateX(-15px)',
  },
}));

export default useStyles;
