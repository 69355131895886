import { black } from 'src/app/color';

export const randomColor = () => {
  const hex = Math.floor(Math.random() * 0xffffff);
  const color = `#${hex.toString(16)}`;
  return color;
};

// eslint-disable-next-line consistent-return
export const stringToColor = (string: string | null) => {
  if (string !== null) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }
  return black;
};

// eslint-disable-next-line consistent-return
export const stringAvatar = (name: string | null) => {
  if (name !== null) {
    const capitalName = name.toUpperCase();
    return capitalName.split(' ').length < 2
      ? capitalName.split(' ')[0][0]
      : capitalName.split(' ')[0][0] + capitalName.split(' ')[1][0];
  }
  return 'NA';
};
