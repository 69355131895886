import { useEffect, useState } from 'react';
import { getAllUserCompanies } from 'src/utils/apiUtils';
import IUserCompanyInfo from 'src/common/interfaces/IUserCompanyInfo';
import { StatusCodes } from 'http-status-codes';

const useUserCompanyList = (email: string | null) => {
  const [userCompanyList, setUserCompanyList] = useState<IUserCompanyInfo[]>([] as IUserCompanyInfo[]);
  useEffect(() => {
    const fetchUserCompanyList = async () => {
      const userId = localStorage.getItem('userId');
      const companyId = localStorage.getItem('companyId');
      const {
        status,
        data,
      } = await getAllUserCompanies(email, userId, companyId);
      if (status === StatusCodes.OK) {
        setUserCompanyList(data);
      }
    };
    fetchUserCompanyList();
  }, [email]);
  return userCompanyList;
};
export default useUserCompanyList;
