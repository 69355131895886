import { makeStyles } from '@material-ui/core/styles';
import { deepDarkBlue, grey } from 'src/app/color';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 40,
  },
  tab: {
    textTransform: 'none',
    minWidth: 20,
    marginLeft: 50,
    padding: 0,
    fontFamily: 'Poppins-Regular',
    fontWeight: 500,
    fontSize: 14,
    color: grey,
    opacity: 1,
  },
  active: {
    borderBottom: '2px solid',
    color: deepDarkBlue,
    fontWeight: 600,
  },
  invite_button: {
    height: '2.5rem',
    textTransform: 'capitalize',
    marginLeft: '2%',
    marginRight: '1%',
    marginTop: '5px',
    width: '80px',
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      width: '40px',
      fontSize: 0,
    },
  },
  invite_icon: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '9px',
    },
  },
}));
