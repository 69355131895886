import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import UserProfileIcon from 'src/components/UserProfileIcon/UserProfileIcon';
import ProgressBar from 'src/components/ProgressBar/ProgressBar';
import IProjectSectionProps from 'src/common/interfaces/IProjectSectionProps';
import { usePathVariable } from 'src/common/customHooks';
import useStyles from './ProjectSectionItem.style';

function ProjectSectionItem({ id, name, description, projectUserNames, todoItemCompleteNum, todoItemUndoNum }: IProjectSectionProps) {
  const { companyId } = usePathVariable();
  const styles = useStyles();
  const path = `/companies/${companyId}/projects/${id}`;
  const todoItemTotalNum = todoItemCompleteNum + todoItemUndoNum;
  const percentage = (todoItemTotalNum && todoItemTotalNum !== 0) ? ((todoItemCompleteNum / todoItemTotalNum) * 100) : 0;

  return (
    <Box className={styles.project_item_container}>
      <div className={styles.info}>
        <div className={styles.header}>
          <div className={styles.name}>
            {name}
          </div>
          <Link className={styles.link} id="projectLink" to={path}>
            <MoreVertIcon />
          </Link>
        </div>
        <Link id="optionLink" to={path}>
          <div className={styles.description}>
            {description ? <span>{description}</span> : <span>No Content</span>}
          </div>
          <div className={styles.progress}>
            <div>Progress</div>
            <div>
              {percentage.toFixed(0)}
              %
            </div>
          </div>
          <div className={styles.line}>
            <ProgressBar value={percentage} />
          </div>
          <div className={styles.container}>
            <div className={styles.icon}>
              <TextsmsOutlinedIcon />
              <span>7</span>
              <AttachFileIcon />
              <span>2</span>
            </div>
            <div className={styles.projectUserName}>
              {
                projectUserNames.slice(0, 3).map((item: string) => (
                  <div key={item} className={styles.item}>
                    <UserProfileIcon key={item} name={item} size="30" textSizeRatio={2.5} />
                  </div>
                ))
              }
            </div>
          </div>
        </Link>
      </div>
    </Box>
  );
}

export default ProjectSectionItem;
