import axios from 'axios';
import baseUrl from './apiUrls';

// Start GetProjectsMembersInfo//
const getProjectMembersInfo = (companyId: string, projectId: string) => axios({
  method: 'get',
  url: `${baseUrl}/companies/${companyId}/projects/${projectId}/members`,
});
// End GetProjectsMembersInfo//

export default getProjectMembersInfo;
