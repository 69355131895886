import { Box } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import Loading from 'src/components/Loading/Loading';
import Contribution from './components/Contribution/Contribution';
import Dotline from './components/Dotline/Dotline';
import useStyles from './Contributions.style';

const covertDataToDayCountPairMap = (data: { [key: string]: any }) => {
  const contributionTodoItemsCounts = new Map();

  Object.entries(data).forEach(([key, value]) => {
    const AbbrKey = key.charAt(0) + key.substring(1, 3).toLowerCase();
    contributionTodoItemsCounts.set(AbbrKey, value.length);
  });

  return contributionTodoItemsCounts;
};

function Contributions({ contributionTodoItemsListData, loading, setChooseDay }: any) {
  const classes = useStyles();

  const contributionTodoItemsCounts = useMemo(() => covertDataToDayCountPairMap(contributionTodoItemsListData), [contributionTodoItemsListData]);
  const highestNumberOfContributions = Math.max(...Array.from(contributionTodoItemsCounts.values()));

  const [dayClicked, setDayClicked] = useState<string>('');
  const handleClick = (day: string) => {
    setDayClicked(day);
  };

  const numberOfContributionsClicked = contributionTodoItemsCounts.get(dayClicked);

  return (
    <Box className={classes.container}>
      <h2 className={classes.title}>Contributions</h2>
      <Box className={loading ? classes.loading : classes.columns}>
        {loading
          ? <Loading />
          : Array.from(contributionTodoItemsCounts).map(([key, value]) => (
            <Contribution
              setChooseDay={setChooseDay}
              key={key}
              dayOfWeek={key}
              percentage={value === 0 ? 0 : value / highestNumberOfContributions}
              handleClick={() => handleClick(key)}
              id={key === dayClicked ? 'clicked' : ''}
            />
          ))}
        {dayClicked
          ? (
            <Dotline
              numberOfContributions={numberOfContributionsClicked}
              percentage={numberOfContributionsClicked === 0 ? 0 : numberOfContributionsClicked / highestNumberOfContributions}
            />
          )
          : null}
      </Box>
    </Box>
  );
}

export default Contributions;
