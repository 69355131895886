import React, { useContext } from 'react';
import ICompanyEmployee from 'src/common/interfaces/ICompanyEmployee';
import { CompanyContext } from 'src/context/CompanyContext';
import useStyles from './Crew.style';

import Employee from './Employee';

function Crew() {
  const classes = useStyles();
  const { companyEmployees } = useContext(CompanyContext);

  const employeeList = companyEmployees.map((e: ICompanyEmployee) => (
    <Employee key={e.id} employee={e} />
  ));

  return (
    <div className={classes.crewContainer}>
      <div className={classes.tableHeader}>
        <div className={classes.tableHeaderContent}>Name</div>
        <div className={classes.tableHeaderContent}>Email</div>
      </div>
      {employeeList}
    </div>
  );
}

export default Crew;
