import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  fab: {
    width: '35px',
    height: '35px',
    backgroundColor: 'transparent',
    border: '1px dashed #a3aed0',
    boxShadow: 'none',
    textAlign: 'right',
  },
  no_date_div: {
    marginBottom: '-10px',
  },
  date_picker: {
    width: '20px',
    color: '#a3aed0',
    boxShadow: '0',
    textAlign: 'right',
  },
  no_date_calendar_wrapper: {
    display: 'block',
    marginBottom: '-30px',
  },
  no_date_calendar: {
    display: 'none',
  },
  calendar: {
    border: 'none',
    fontFamily: 'Poppins-regular',
    fontSize: '14px',
    fontWeight: 550,
    fontStyle: 'normal',
    color: '#1b2559',
    fontStretch: 'normal',
    textAlign: 'center',
    zIndex: 9999,
    width: '90px',
  },
  react_datepicker_picker: {
    zIndex: 100,
  },
}));

export default useStyles;
