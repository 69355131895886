/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { getProjectList } from 'src/utils/apiUtils';
import { useFetch, usePathVariable } from 'src/common/customHooks';
import addProjectIcon from 'src/assets/svgs/addProjectIcon.svg';
import IProjectInfo from 'src/common/interfaces/IProjectInfo';
import ProjectSectionItem from 'src/components/DashBoard/components/ProjectSection/components/ProjectSectionItemList/ProjectSectionItem/components/ProjectSectionItem';
import Loading from 'src/components/Loading/Loading';
import useStyles from './ProjectsPageContent.style';
import DisplaySelectors from './components/DisplaySelectors/DisplaySelectors';
import PopupWindow from './components/PopupWindow/PopupWindow';

function ProjectsPageContent() {
  const { companyId } = usePathVariable();
  const classes = useStyles();

  const userId = localStorage.getItem('userId');
  const { data: projectListData, loading } = useFetch(getProjectList, [companyId, userId], [] as IProjectInfo[], [companyId]);

  const sortedProjectListData = projectListData.sort((a, b) => a.name.localeCompare(b.name));

  const [toggleDisplayPopup, setToggleDisplayPopup] = useState(false);

  const [showBlankProject, setShowBlankProject] = useState(false);

  const toggleShowBlankProject = () => {
    setShowBlankProject(!showBlankProject);
  };

  const togglePopup = () => {
    setToggleDisplayPopup(!toggleDisplayPopup);
    setShowBlankProject(false);
  };

  return (
    <div className={loading ? classes.emptyContent : classes.content}>
      {loading ? <Loading />
        : (
          <>
            <DisplaySelectors />
            <div className={classes.content_container}>
              <div className={classes.add_project_btn_container}>

                <div className={classes.empty_container}>
                  <button
                    type="button"
                    aria-label="add-project-button"
                    className={classes.add_project_btn}
                    onClick={togglePopup}
                  >
                    <img src={addProjectIcon} className={classes.add_project_icon} alt="add_project_icon" />
                  </button>
                  {projectListData.length === 0 && (
                  <>
                    <div className={classes.empty_arrow} />
                    <div className={classes.empty_img} />
                    <div className={classes.empty_content}>Click here to start your first project!</div>
                  </>
                  )}
                </div>
              </div>
              <div className={classes.add_project_btn_place} />
              {sortedProjectListData.map((project: any) => (
                <ProjectSectionItem key={project.id} {...project} />
              ))}
            </div>
          </>
        )}
      <PopupWindow
        toggleDisplayPopup={toggleDisplayPopup}
        togglePopup={togglePopup}
        showBlankProject={showBlankProject}
        toggleShowBlankProject={toggleShowBlankProject}
      />
    </div>
  );
}

export default ProjectsPageContent;
