// change date to "13 Nov, 2021" format
const dateFormater = (date: string) => {
  let formattedDate = date;
  const month = formattedDate.substring(0, 3);
  formattedDate = formattedDate.substring(4, formattedDate.length);
  const day = formattedDate.substring(0, formattedDate.length - 6);
  const year = formattedDate.substring(formattedDate.length - 6, formattedDate.length);
  formattedDate = day.concat(' ').concat(month).concat(year);
  return formattedDate;
};
export default dateFormater;
