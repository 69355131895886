import { makeStyles } from '@material-ui/core/styles';
import { blueGrey, deepDarkBlue, tiffanyGreen } from 'src/app/color';

const useStyles = makeStyles(() => ({
  row: {
    minHeight: '46px',
    marginLeft: '8px',
    marginRight: '50px',
    textTransform: 'none',
    color: deepDarkBlue,
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    backgroundColor: ' #fff',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.25)',
  },
  button: {
    textTransform: 'none',
    color: deepDarkBlue,
    fontSize: '14px',
    fontWeight: 600,
  },
  task: {
    textAlign: 'left',
  },
  checkButton: {
    fill: blueGrey,

  },
  checkedButton: {
    color: tiffanyGreen,
  },
  textFiled: {
    marginTop: '10px',
    maxWidth: '100%',
  },
  checkbox: {
    marginLeft: '-6px',
    marginTop: '6px',

  },
  add: {
    width: '20px',
    color: blueGrey,
    boxShadow: '0',
    textAlign: 'right',
  },
  fab: {
    width: '35px',
    height: '35px',
    backgroundColor: 'transparent',
    border: '1px dashed #a3aed0',
    boxShadow: 'none',
    textAlign: 'right',
  },
  calendar: {
    border: 'none',
    fontFamily: 'Poppins-regular',
    fontSize: '14px',
    fontWeight: 550,
    fontStyle: 'normal',
    color: deepDarkBlue,
    fontStretch: 'normal',
    textAlign: 'center',
    zIndex: 9999,
    width: '90px',
  },
  date_picker: {
    width: '20px',
    color: blueGrey,
    boxShadow: '0',
    textAlign: 'right',
  },
  react_datepicker_picker: {
    zIndex: 100,
  },
  no_date_calendar_wrapper: {
    display: 'block',
    marginBottom: '-30px',
  },
  no_date_calendar: {
    display: 'none',
  },
  no_date_div: {
    marginBottom: '-10px',
  },
  priority_selector: {
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: '30px',
    height: '30px',
    flexGrow: 0,
    fontSize: '14px',
    fontFamily: 'Poppins-medium',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: '-0.28px',
  },
  assigneesBtn: {
    backgroundColor: 'transparent',
    border: 'transparent',
    cursor: 'pointer',
  },
  assignees: {
    display: 'flex',
    justifyContent: 'left',
    position: 'relative',
  },
  assigneesWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
