import { createStyles, makeStyles } from '@material-ui/core';
import { blueGrey, lightGrey } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  addSection: {
    fontSize: '18px',
    color: blueGrey,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontFamily: 'Poppins-Regular',
    marginTop: '8px',
    paddingTop: '0px',
    border: '0px',
    backgroundColor: lightGrey,
    position: 'relative',
    bottom: '8px',
    cursor: 'pointer',
    display: 'inline-block',
    right: '6px',
  },
  addIcon: {
    float: 'left',
    fontSize: '26px',
    color: blueGrey,
    fontWeight: 'bold',
    fontStyle: 'normal',
    marginRight: '7px',
    marginBottom: '1px',
  },
}));

export default useStyles;
