import { makeStyles, Theme } from '@material-ui/core/styles';
import { blueGrey, darkGrey, deepDarkBlue, grey } from 'src/app/color';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 30,
    marginLeft: 40,
  },
  tab: {
    textTransform: 'none',
    minWidth: 20,
    marginLeft: 50,
    padding: 0,
    fontFamily: 'Poppins-Regular',
    fontWeight: 500,
    fontSize: 14,
    color: darkGrey,
    opacity: 1,
    [theme.breakpoints.down('md')]: {
      marginLeft: '15px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 20,
      marginLeft: 50,
      padding: 0,
    },
  },
  active: {
    borderBottom: '2px solid',
    color: deepDarkBlue,
    fontWeight: 600,
  },
  add: {
    width: '20px',
    height: '20px',
    color: blueGrey,
    boxShadow: '0',
    textAlign: 'right',
  },
  fab: {
    width: '36px',
    height: '18px',
    backgroundColor: 'transparent',
    border: '1px solid #a3aed0',
    boxShadow: 'none',
    textAlign: 'right',
    marginTop: '7px',
    [theme.breakpoints.down('md')]: {
      marginRight: '50%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      marginRight: '-90px',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      marginRight: '5px',
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: '-20%',
    },
  },
  avatar: {
    position: 'relative',
    width: '35px',
    height: '35px',
    border: 'none',
    fontSize: 14,
  },
  avatar_number: {
    position: 'relative',
    width: '35px',
    height: '35px',
    border: 'none',
    fontSize: 14,
    backgroundColor: grey,
  },
  avatar_group: {
    marginTop: '7px',
    [theme.breakpoints.down('md')]: {
      marginRight: '15%%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      marginLeft: '30%',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      marginLeft: '-50%',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '20%',
    },
  },
}));
