import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  optionBtn: {
    width: '115px',
    height: '33px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    border: 'transparent',
    cursor: 'pointer',
    fontFamily: 'Poppins-regular',
    '&:hover': {
      backgroundColor: 'rgba(163, 174, 208, 0.19)',
    },
    '&#Selected': {
      backgroundColor: 'rgba(163, 174, 208, 0.19)',
    },
  },
}));

export default useStyles;
