import React from 'react';
import { usePathVariable, useAuth } from 'src/common/customHooks';
import Box from '@material-ui/core/Box';
import MessageList from './components/MessageList/MessageList';

function MessageBoard() {
  const { projectId, companyId } = usePathVariable();
  const { isProjectMember, isCompanyManager } = useAuth();

  return (
    <Box ml={5} minWidth="1080px">
      <MessageList
        companyId={companyId}
        projectId={projectId}
        isProjectMember={isProjectMember}
        isCompanyManager={isCompanyManager}
      />
    </Box>
  );
}

export default MessageBoard;
