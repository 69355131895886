/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { TextField, Dialog, Button } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import IEditCategories from 'src/common/interfaces/IEditCategories';
import useStyles from './EditCategories.style';
import EmojiList from './Emoji/EmojiList';
import useEditCategories from './hook/useEditCategories';

function EditCategories(props: IEditCategories) {
  const styles = useStyles();
  const {
    btnOpen, emojiListOpen, setEmojiListOpen,
    open, handleClose, handleSubmit, setbtnOpen, setEmoji,
    messageContent, emoji, categoryFormSchema,
  } = useEditCategories(props);
  return (
    <div>
      <Dialog
        className={styles.dialog}
        aria-labelledby="message-category-dialog"
        open={open}
        maxWidth="md"
        onClose={() => {
          handleClose();
          setbtnOpen(true);
          setEmojiListOpen(true);
          setEmoji('');
        }}
      >
        <div data-testid="categoryTitle" className={styles.titleContainer}>
          <h1 className={styles.title}>
            <b>Message categories for Launching a new product</b>
          </h1>
          <div className={styles.content}>
            Set up categories for this project below. You can also&nbsp;
            <a href="/" className={styles.link}>
              change categories for the whole account
            </a>
          &nbsp; in Adminland. &nbsp;
            {' '}
          </div>
        </div>
        <div className={styles.categroies_list}>
          {messageContent}
        </div>
        {!emojiListOpen
          && (
            <div className={styles.emojiPop} data-testid="emojiListBox">
              <EmojiList handleClosePop={() => setEmojiListOpen(true)} setEmoji={setEmoji} />
            </div>
          ) }
        <Formik
          // data-testid="submitEdit"
          // data-testid="textbox"
          initialValues={{ category: '' }}
          onSubmit={(value, action) => {
            handleSubmit(value);
            action.resetForm();
          }}
          validationSchema={categoryFormSchema}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className={styles.form}>
                {btnOpen
                  ? (
                    <Button
                      data-testid="openCategory"
                      className={styles.btn}
                      variant="contained"
                      fullWidth
                      onClick={() => setbtnOpen(false)}
                    >
                      Add a category
                    </Button>
                  )
                  : (
                    <div className={styles.container}>
                      <span
                        onClick={() => setEmojiListOpen(!emojiListOpen)}
                        data-testid="openEmojiListbtn"
                        className={`${emojiListOpen ? `${styles.emojiSelect}` : `${styles.emojiListShown}`}`}
                      >
                        {emoji || <SentimentSatisfiedIcon />}
                      </span>
                      <div className={styles.input} data-testid="text2">
                        <Field
                          data-testid="text"
                          as={TextField}
                          fullWidth
                          id="category"
                          name="category"
                          placeholder="Name it..."
                        />
                        <span className={styles.err} data-testid="warning">
                          {errors.category && touched.category && <div>{errors.category}</div>}
                        </span>
                      </div>
                      <IconButton
                        aria-label="delete"
                        disabled={isSubmitting || !emojiListOpen}
                        type="submit"
                        data-testid="submitEdit2"
                      >
                        <CheckIcon className={styles.checkIcon} />
                      </IconButton>
                      <IconButton aria-label="delete">
                        <CloseIcon
                          data-testid="cancelButton"
                          onClick={() => {
                            setbtnOpen(true);
                            setEmoji('');
                            setEmojiListOpen(true);
                          }}
                        />
                      </IconButton>
                    </div>
                  )}
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}

export default EditCategories;
