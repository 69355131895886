import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { darkBlue } from 'src/app/color';

const useStyles = makeStyles((theme: Theme) => createStyles({
  project_switch: {
    flexGrow: 1,
    float: 'right',
  },
  project_switch_button: {
    marginLeft: '3.2rem',
    maxWidth: '500px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0rem',
      maxWidth: '600px',
    },
    textTransform: 'none',
  },
  title: {
    maxWidth: '700px',
    color: darkBlue,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      maxWidth: '600px',
    },
  },
  nested: {
    maxWidth: '700px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '600px',
    },
  },
  menuShow: {
    position: 'fixed',
    left: '120px',
    maxWidth: '700px',
    animation: `$fadeIn .35s ${theme.transitions.easing.easeInOut}`,
    [theme.breakpoints.down('md')]: {
      marginTop: '0rem',
      maxWidth: '600px',
      marginLeft: '0rem',
    },
    zIndex: 9999,
  },
  menuExit: {
    position: 'fixed',
    left: '120px',
    maxWidth: '700px',
    animation: `$fadeOut .35s ${theme.transitions.easing.easeInOut}`,
    [theme.breakpoints.down('md')]: {
      marginTop: '0rem',
      maxWidth: '600px',
      marginLeft: '0rem',
    },
    zIndex: 9999,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
      left: '0rem',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateY(0)',
      left: '0rem',
    },
    '100%': {
      opacity: 0,
      transform: 'translateY(-100%)',
    },
  },
}));

export default useStyles;
