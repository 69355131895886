import getNewJwt from 'src/utils/apiJwt';
import { StatusCodes } from 'http-status-codes';

const refreshJwt = async () => {
  const refreshJwtResponse = await getNewJwt();
  if (refreshJwtResponse.status === StatusCodes.OK) {
    localStorage.setItem('accessToken', refreshJwtResponse.data.accessToken);
  }
};

export default refreshJwt;
