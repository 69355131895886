import { Box } from '@material-ui/core';
import React from 'react';
import TaskCards from 'src/pages/components/DashBoardPage/components/TaskCards/TaskCards';
import Header from 'src/components/Header/Header';
import { useCurrentDate } from 'src/common/customHooks/useCurrentDate';
import useStyles from './DashBoardPage.style';
import ContributionArea from './components/Contributions/ContributionArea';

function DashBoardPage() {
  const classes = useStyles();
  const { today } = useCurrentDate();

  return (
    <Box>
      <Header title="Dashboard" isCompanyPage={false} isInnerProjectPage={false} otherProjects={[]} />
      <Box className={classes.body}>
        <TaskCards />
        <ContributionArea today={today} />
      </Box>
    </Box>
  );
}

export default DashBoardPage;
