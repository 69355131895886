import { makeStyles } from '@material-ui/core/styles';
import { skyBlue, white } from 'src/app/color';

const useStyles = makeStyles(() => ({
  assigneesMenu: {
    position: 'absolute',
    zIndex: 999,
    top: '40px',
  },
  projectMembersList: {
    backgroundColor: white,
    borderRadius: '5px',
    flexGrow: 0,
    boxShadow: '0 2px 2px 0 rgba(125, 88, 159, 0.19)',
  },
  inviteBtn: {
    width: '331px',
    height: '44px',
    paddingLeft: '21.6px',
    border: 'transparent',
    background: 'transparent',
    fontFamily: 'Poppins-medium',
    fontSize: '12px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.33,
    letterSpacing: '-0.24px',
    textAlign: 'left',
    color: skyBlue,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 0,
    '&:hover': {
      backgroundColor: 'rgba(163, 174, 208, 0.19)',
    },
  },
  addIcon: {
    fontSize: '18px',
    marginRight: '12.3px',
  },
}));

export default useStyles;
