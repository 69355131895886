import { useState, useEffect } from 'react';

const useTimer = () => {
  const [freezeTime, setFreezeTime] = useState(60);
  const [canClick, setCanClick] = useState(true);

  useEffect(() => {
    let timeId: any = null;
    if (!canClick) {
      timeId = setInterval(() => {
        setFreezeTime(freezeTime - 1);
      }, 1000);
    }
    if (freezeTime <= 0) {
      clearInterval(timeId);
      setFreezeTime(60);
      setCanClick(true);
    }
    return () => clearInterval(timeId);
  }, [freezeTime, canClick]);
  return { canClick, freezeTime, setCanClick };
};

export default useTimer;
