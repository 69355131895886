import { createStyles, makeStyles } from '@material-ui/core';

const borderRadius = 10;

const useStyles = makeStyles(() => createStyles({
  todoList: {
    marginBottom: '25px',
  },
  todoItems: {
    '&>:first-child': {
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
    },
    '&>:last-child': {
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
  },
}));

export default useStyles;
