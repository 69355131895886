import React, { useState } from 'react';
import { Fab } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DatePicker from 'react-datepicker';
import useStyles from './Calendar.styles';

interface CalendarProps {
  dueDate: string,
  setNewTodoItem: (date?: string, newPriority?: string, newAssigneesIds?: string) => Promise<void>,
  inputRef: React.MutableRefObject<HTMLInputElement | undefined>,
  disabled: boolean,
}

function Calendar({ dueDate, setNewTodoItem, inputRef, disabled }: CalendarProps) {
  const classes = useStyles();
  const [newDate, setNewDate] = useState(dueDate);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [onMouseOver] = useState(false);

  const openDatePicker = () => {
    setDatePickerIsOpen(!datePickerIsOpen);
  };

  const handleBlur = () => {
    if (onMouseOver) {
      if (!inputRef.current?.value) {
        inputRef.current?.focus();
      }
      return;
    }
    setNewTodoItem();
  };

  const handleDateChange = (nDate:Date | [Date | null, Date | null] | null) => {
    const date = nDate?.toString() as string;
    const transDate = new Date(date).toISOString();
    setNewDate(transDate);
    setNewTodoItem(transDate);
  };

  return (
    (!newDate || Number.isNaN(new Date(newDate).getTime())) ? (
      <div data-testid="no-dueDate">
        <DatePicker
          wrapperClassName={classes.no_date_calendar_wrapper}
          className={classes.no_date_calendar}
          onChange={(nDate) => {
            openDatePicker();
            handleDateChange(nDate);
          }}
          minDate={new Date()}
          onClickOutside={openDatePicker}
          open={datePickerIsOpen}
        />
        <div className={classes.no_date_div}>
          <Fab
            aria-label="add"
            className={classes.fab}
            disabled={disabled}
            onClick={openDatePicker}
            onBlur={handleBlur}
            data-testid="date-icon"
          >
            <CalendarTodayIcon className={classes.date_picker} />
          </Fab>
        </div>
      </div>
    ) : (
      <div data-testid="date-picker">
        <DatePicker
          selected={new Date(newDate)}
          disabled={disabled}
          className={classes.calendar}
          popperClassName={classes.react_datepicker_picker}
          minDate={new Date()}
          onChange={(nDate) => {
            handleDateChange(nDate);
          }}
          dateFormat="dd MMM yyyy "
        />
      </div>
    )
  );
}

export default Calendar;
