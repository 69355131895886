import React, { useContext } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import { TodoContext } from 'src/context/TodoContext';

import useStyles from './Section.style';

function SectionHeader({ title, id }: { title: string, id: string }) {
  const classes = useStyles();
  const { addTodoItem } = useContext(TodoContext);
  return (
    <div className={classes.sectionHeader}>
      <Typography
        className={classes.sectionHeaderTitle}
        component="span"
        noWrap
      >
        {title}
      </Typography>
      <IconButton
        className={classes.sectionAddButton}
        size="small"
        data-testid="btn-add-item"
        onClick={() => {
          addTodoItem(id);
        }}
      >
        <AddIcon />
      </IconButton>
      <IconButton
        size="small"
        component="span"
        data-testid="btn-edit"
      >
        <MoreHorizIcon className={classes.moreIcon} />
      </IconButton>
    </div>
  );
}

export default SectionHeader;
