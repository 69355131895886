const formConfig = {
  nameInput: {
    label: 'Name',
    name: 'name',
    variant: 'outlined',
    margin: 'normal',
    fullWidth: true,
    id: 'name',
    autoComplete: 'name',
  },
  emailInput: {
    label: 'Email',
    name: 'email',
    variant: 'outlined',
    margin: 'normal',
    disabled: true,
    fullWidth: true,
    id: 'email',
    autoComplete: 'email',
  },
  passwordInput: {
    'data-testid': 'passwordInput',
    label: 'Password',
    variant: 'outlined',
    margin: 'normal',
    fullWidth: true,
    id: 'password',
    name: 'password',
    autoComplete: 'current-password',
  },
  confirmPasswordInput: {
    'data-testid': 'confirmPasswordInput',
    label: 'Confirm Password',
    variant: 'outlined',
    margin: 'normal',
    fullWidth: true,
    id: 'confirmPassword',
    name: 'confirmPassword',
    autoComplete: 'current-password',
  },
};

export default formConfig;
