const formConfig = {
  nameInput: {
    name: 'name',
    label: 'Name',
    variant: 'outlined',
    margin: 'normal',
    fullWidth: true,
    id: 'name',
    placeholder: 'your name',
    autoComplete: 'name',
  },
  emailInput: {
    variant: 'outlined',
    margin: 'normal',
    fullWidth: true,
    id: 'email',
    autoComplete: 'email',
    placeholder: 'name@asyncworking.com',
  },
  passwordInput: {
    variant: 'outlined',
    margin: 'normal',
    fullWidth: true,
    type: 'password',
    autoComplete: 'new-password',
  },
};

export default formConfig;
