import React from 'react';
import {
  Typography,
  Grid,
  Hidden,
} from '@material-ui/core';
import {
  Link,
} from 'react-router-dom';
import forgetPassword from 'src/assets/svgs/forgetPassword.svg';
import { useParams } from 'react-router';
import useStyles from './PasswordRecovery.style';
import logo from '../../assets/svgs/logoWithoutTexts.svg';
import Footer from '../Footer/Footer';
import PasswordRecoveryForm from './components/PasswordRecoveryForm';
import EmailCheckingPage from './components/EmailCheckingPage/EmailCheckingPage';
import useTimer from './hooks/useTimer';

interface FormStep {
  [key: string] : JSX.Element,
}

function PasswordRecovery() {
  const { step } = useParams<{ step: string }>();
  const classes = useStyles();
  const { freezeTime, setCanClick, canClick } = useTimer();

  const steps: FormStep = {
    'email-input-page': <PasswordRecoveryForm
      freezeTime={freezeTime}
      setCanClick={setCanClick}
      canClick={canClick}
    />,
    'email-checking-page': <EmailCheckingPage />,
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      className={classes.background}
    >
      <Grid item md={5} className={classes.form}>
        <Link to="/">
          <img src={logo} className="img" alt="logo" />
        </Link>
        <Typography className={classes.title}>
          Forgot your password?
        </Typography>
        {
            steps[step]
          }
        <Footer isPublicPage />
      </Grid>
      <Hidden smDown>
        <Grid item md={7} container className={classes.img_container}>
          <img src={forgetPassword} alt="forgetPassword" className={classes.background_img} />
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default PasswordRecovery;
