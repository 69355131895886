/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  InputBase,
  Paper,
  useScrollTrigger,
  Button,
  Box,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import IProject from 'src/common/interfaces/IProject';
import { ReactComponent as InviteIcon } from 'src/assets/svgs/inviteIcon.svg';
import UserProfileMenu from 'src/components/Header/components/UserProfileMenu/UserProfileMenu';
import SubNavBar from 'src/components/Header/components/SubNavBar/SubNavBar';
import dateFormater from 'src/common/functions/dateFormater';
import moment from 'moment';
import ProjectSelector from './components/ProjectSelector/ProjectSelector';
import useStyles from './Header.style';
import CompanyPageSubNav from './components/CompanyPageSubNav/CompanyPageSubNav';

interface Props {
  title: string | undefined;
  isInnerProjectPage: boolean;
  isCompanyPage: boolean;
  otherProjects: IProject[]
}
interface IElevationScrollProps {
  children: React.ReactElement;
}

const ElevationScroll = ({ children }: IElevationScrollProps) => {
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    className: trigger ? classes.appBar_scroll : classes.appBar,
    elevation: 0,
  });
};

function Header({ title, isInnerProjectPage, isCompanyPage, otherProjects }: Props) {
  const classes = useStyles();
  const [date, setDate] = useState(dateFormater(moment(new Date()).format('ll')));
  useEffect(() => {
    const timer = setInterval(() => {
      setDate(dateFormater(moment(new Date()).format('ll')));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const renderTitle = () => {
    if (isInnerProjectPage) {
      return (
        <ProjectSelector
          title={title}
          otherProjects={otherProjects}
        />
      );
    }

    if (title === 'Dashboard') {
      return (
        <Box className={classes.dashboard_title}>
          <Typography className={classes.text_welcome} data-testid="welcome">
            Welcome,
            {' '}
            {localStorage.getItem('name')}
            !
          </Typography>
          <Typography className={classes.text_date}>
            {date}
          </Typography>
        </Box>
      );
    }
    return (
      <Typography className={classes.normal_title}>
        {title}
      </Typography>
    );
  };

  return (
    <ElevationScroll>
      <AppBar position="sticky">
        <Toolbar>
          {renderTitle()}
          <Paper className={classes.search} elevation={2}>
            <div className={classes.searchIcon}>
              <SearchIcon color="primary" />
            </div>
            <InputBase
              placeholder="Search"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </Paper>
          { title === 'Project' && (
          <Button
            className={classes.invite_button}
            startIcon={<InviteIcon className={classes.invite_icon} />}
            variant="contained"
            color="primary"
            data-testid="invite-button"
          >
            Invite
          </Button>
          )}
          <UserProfileMenu />
        </Toolbar>
        {isInnerProjectPage ? <SubNavBar /> : (isCompanyPage ? <CompanyPageSubNav /> : null)}
      </AppBar>
    </ElevationScroll>
  );
}

export default Header;
