import { createTheme } from '@material-ui/core/styles';
import { awPurple, awDarkPurple, tomatoRed } from './color';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins-Regular',
  },
  palette: {
    primary: {
      main: awPurple, // salsifyGrassGreen,
    },
    secondary: {
      main: awDarkPurple,
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        color: tomatoRed,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 374, // phone
      md: 1024, // tablet
      lg: 1440, // desktop
      xl: 1920,
    },
  },
});

export default theme;
