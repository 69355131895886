import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { deepDarkBlue, salsifyGrassGreen, white } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  row: {
    minHeight: '56px',
    marginLeft: '8px',
    marginRight: '50px',
    textTransform: 'none',
    color: deepDarkBlue,
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    backgroundColor: white,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.25)',
  },
  category: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
  },
  title: {
    textAlign: 'left',
    width: '100%',
    padding: '10px',
  },
  message: {
    textAlign: 'left',
    width: '100%',
    padding: '10px',
  },
  publishedBy: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'left',
  },
  publishedTime: {
    textAlign: 'center',
  },
  avatar: {
    textAlign: 'center',
    width: '28px',
    height: '28px',
    border: 'none',
    fontSize: 12,
    margin: '0 12px 0 11px',
    flexGrow: 0,
  },
  add: {
    whiteSpace: 'nowrap',
    fontSize: '0.6rem',
    borderRadius: '30px',
    color: salsifyGrassGreen,
    backgroundColor: white,
    borderColor: salsifyGrassGreen,
    textTransform: 'capitalize',
    padding: '5px 12px 5px 12px',
  },
  textFiled: {
    width: '100%',
  },
}));

export default useStyles;
