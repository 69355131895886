import React, { useContext, memo } from 'react';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Calendar } from 'react-multi-date-picker';
import { ScheduleContext } from 'src/context/ScheduleContext';
import styled from 'styled-components';
import EventList from './components/EventList/EventList';
import EventEditForm from './components/EventEditForm/EventEditForm';
import useStyles from './Schedules.style';

function Schedules() {
  const classes = useStyles();
  const { targetDate, setTargetDate } = useContext(ScheduleContext);
  const { showEventEditForm, setShowEventEditForm } = useContext(ScheduleContext);
  const onDateSelected = (e: any) => {
    setTargetDate(new Date(e.year, e.monthIndex, e.day));
  };
  const StyledCalendar = styled(Calendar)`
   .rmdp-top-class { 
      justify-content: center;
      width: 890px;
   },
   .rmdp-header {
      color: black;
      width: 890px;
    },
    .rmdp-day-picker {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    .rmdp-arrow {
      border: solid #7d589f;
      border-width: 0 2px 2px 0;
      display: inline-block;
      height: 3px;
      margin-top: 5px;
      padding: 2px;
      width: 3px;
    }
    .rmdp-week-day {
      color: #7d589f;
    }
    .rmdp-day.rmdp-selected span:not(.highlight) {
      background-color: #7d589f;
    }
    .rmdp-day.rmdp-today span {
      background-color: #5f11cb
    }
  `;

  return (
    <div className={classes.scheduleContainer}>
      <div className={classes.titleContainer}>
        <div>
          <button type="submit" className={classes.newEventBtn} onClick={() => setShowEventEditForm(true)}>+ New Event</button>
        </div>
        <div className={classes.scheduleTitle}>
          Schedule
        </div>
      </div>
      <div className={classes.calendarContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledCalendar className={classes.scheduleCalendar} numberOfMonths={2} disableMonthPicker value={targetDate} onChange={onDateSelected} />
        </LocalizationProvider>
      </div>
      <div className={classes.eventListContainer}>
        <EventList />
      </div>
      <div className={classes.addEventContainer}>
        {showEventEditForm && <EventEditForm />}
      </div>
    </div>
  );
}

export default memo(Schedules);
