import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { deepDarkBlue } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  rowHeader: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '46px',
    margin: '20px 50px 28px 8px',
    textTransform: 'none',
    color: deepDarkBlue,
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    backgroundColor: ' #fff',
    borderRadius: '10px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },
  header_Category: {
    textAlign: 'left',
  },
  header_Title: {
    textAlign: 'left',
    padding: '10px',
  },
  header_Message: {
    textAlign: 'left',
    padding: '10px',
  },
  header_Published_By: {
    textAlign: 'center',
    padding: '10px',
  },
  header_Published_Time: {
    textAlign: 'center',
    padding: '10px',
  },
}));

export default useStyles;
