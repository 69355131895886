import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useDialog = (display: boolean) => {
  const history = useHistory();
  const [freezeTime, setFreezeTime] = useState(11);

  const redirect = () => {
    history.push(`/companies/${localStorage.getItem('companyId')}`);
  };
  useEffect(() => {
    const timeId = setInterval(() => {
      setFreezeTime(freezeTime - 1);
    }, 1000);
    if (display && freezeTime <= 0) {
      clearInterval(timeId);
      redirect();
    }
    return () => clearInterval(timeId);
  }, [freezeTime, display]);
  return { freezeTime, redirect };
};
