import { useParams } from 'react-router-dom';
import getRolesFromJwt from '../functions/getRolesFromJwt';
import checkIsProjectMember from '../functions/checkIsProjectMember';

export const useAuth = () => {
  const { companyId, projectId } = useParams<{ companyId: string, projectId: string }>();

  const isProjectMember = checkIsProjectMember(projectId);
  const isCompanyManager = getRolesFromJwt().includes(`Company Manager,${companyId}`);
  const isProjectManager = getRolesFromJwt().includes(`Project Manager,${projectId}`);

  return { isCompanyManager, isProjectManager, isProjectMember };
};
