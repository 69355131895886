/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import {
  Typography,
} from '@material-ui/core';
import crossIcon from 'src/assets/svgs/crossIcon.svg';
import useSignupErrorMessageStyles from './SignupErrorMessage.style';

interface ISignupErrorMessageProps {
  content: string
  active: boolean
  to: string
  hasLink: boolean
  linkContent: string
}

function SignupErrorMessage({ content, active, to, hasLink, linkContent }: ISignupErrorMessageProps) {
  const classes = useSignupErrorMessageStyles(active, hasLink);

  return (
    <div className={classes.warning}>
      <img src={crossIcon} alt="crossIcon" />
      <Typography className={classes.warning_message}>{content}</Typography>
      <Link className={classes.link} to={to} data-testid="loginLink">
        {linkContent}
      </Link>
    </div>
  );
}

export default SignupErrorMessage;
