import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  row: {
    minHeight: '46px',
    marginLeft: '8px',
    marginRight: '50px',
    textTransform: 'none',
    color: '#1b2559',
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    backgroundColor: ' #fff',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.25)',
  },
  button: {
    textTransform: 'none',
    color: '#1b2559',
    fontSize: '14px',
    fontWeight: 600,
  },
  task: {
    textAlign: 'left',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minWidth: '329px',
    minHeight: '168px',
    marginBottom: '10px',
    overflow: 'visible',
  },
  draggingCard: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minWidth: '329px',
    minHeight: '168px',
    marginBottom: '10px',
    overflow: 'visible',
    transform: 'rotate(-7deg)',
  },
  cardTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '13px 18px 0px 16px',
  },
  cardBottom: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px 3.5px 16px',
  },
  prioritySelector: {
    padding: '0 0 0 10px',
  },
  textFiled: {
    overflow: 'hide',
  },
  checkButton: {
    fill: '#a3aed0',
    width: '35px',
    height: '35px',
  },
  checkedButton: {
    width: '35px',
    height: '35px',
    color: '#56cfd4',
  },
  checkbox: {
    right: '-9px',
  },
}));
export default useStyles;
