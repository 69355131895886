import React from 'react';
import useStyles from './ProgressBar.style';

function ProgressBar({ value } :any) {
  const lineHeight = '4px';
  const percentage = value === 0 ? lineHeight : `${value}%`;
  const styles = useStyles(percentage, lineHeight);
  const index = Math.floor(value / 10);
  const colorTable = [
    '#c64a6f', // 0 -9
    '#c64a6f', // 10-19
    '#ff8201', // 20-29
    '#faa145', // 30-39
    '#f8c643', // 40-49
    '#2994f4', // 50-59
    '#6b83db', // 60-69
    '#7dc2c5', // 70-79
    '#57cc98', // 80-89
    '#73be47', // 90-99
    '#1fa800', // 100
  ];
  return (
    <div className={styles.progressBar}>
      <div className={styles.progress} style={{ backgroundColor: colorTable[index] }} />
    </div>
  );
}

export default ProgressBar;
