import React, { useState } from 'react';
import clsx from 'clsx';
import { Drawer, List, Divider, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactComponent as Logo } from 'src/assets/svgs/logoWithTexts.svg';
import { ReactComponent as DashBoardIcon } from 'src/assets/svgs/dashboard_icon.svg';
import { ReactComponent as ProjectsIcon } from 'src/assets/svgs/projects_icon.svg';
import { ReactComponent as NotificationsIcon } from 'src/assets/svgs/noti_icon.svg';
import { ReactComponent as TemplatesIcon } from 'src/assets/svgs/template_icon.svg';
import { ReactComponent as CompanyIcon } from 'src/assets/svgs/company_icon.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/svgs/settings_icon.svg';
import NavItem from './components/NavItem/NavItem';
import Logout from './components/Logout/Logout';
import useStyles from './NavBar.style';
import CompanySelector from './components/CompanySelector';

function NavBar() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const companyId = localStorage.getItem('companyId');
  const email = localStorage.getItem('email');
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={clsx({
        [classes.drawerHeaderOpen]: open,
        [classes.drawerHeaderClose]: !open,
      })}
      >
        <div className={clsx({
          [classes.hide]: !open,
          [classes.companySelector]: open,
        })}
        >
          <CompanySelector email={email} />
        </div>
        <IconButton
          aria-label="drawer switch"
          className={classes.menuButton}
          onClick={open
            ? handleDrawerClose
            : handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <NavItem
          text="Dashboard"
          path={`/companies/${companyId}`}
          icon={DashBoardIcon}
          exact
        />
        <NavItem
          text="Projects"
          path={`/companies/${companyId}/projects`}
          icon={ProjectsIcon}
        />
        <NavItem
          text="Notifications"
          path="/noti"
          icon={NotificationsIcon}
          disabled
        />
        <NavItem
          text="Templates"
          path="/tem"
          icon={TemplatesIcon}
          disabled
        />
        <NavItem
          text="Company"
          path={`/companies/${companyId}/company`}
          icon={CompanyIcon}
        />
        <NavItem
          text="Settings"
          path="/setting"
          icon={SettingsIcon}
          disabled
        />
      </List>
      <footer className={classes.footer}>
        <Logo
          className={clsx({
            [classes.hide]: !open,
            [classes.logo]: open,
          })}
        />
        <Divider />
        <Logout />
      </footer>
    </Drawer>
  );
}

export default NavBar;
