import React from 'react';
import ICompanyEmployee from 'src/common/interfaces/ICompanyEmployee';
import Avatar from 'react-avatar';
import useStyles from './Crew.style';

interface PropsType {
  employee: ICompanyEmployee;
}

function Employee(props: PropsType) {
  const classes = useStyles();
  const { employee } = props;
  const { name, email } = employee;

  // @ts-ignore
  const avata = <Avatar maxInitials={3} name={name} round size="30" textSizeRatio={2.5} />;

  return (
    <div className={classes.employeeInfo}>
      <div className={classes.employeeAvatarName}>
        { avata }
        <div className={classes.empolyeeName}>{name}</div>
      </div>
      <div className={classes.employeeEmail}>{email}</div>
    </div>
  );
}

export default Employee;
