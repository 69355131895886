import React from 'react';
import { TodoContextProvider } from 'src/context/TodoContext';
import Board from './Board';

function BoardPanelPage() {
  return (
    <TodoContextProvider>
      <Board />
    </TodoContextProvider>
  );
}

export default BoardPanelPage;
