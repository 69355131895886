import { makeStyles } from '@material-ui/core/styles';
import { blueGrey, deepDarkBlue } from 'src/app/color';

const useStyles = makeStyles(() => ({
  projectMember: {
    width: '331px',
    height: '44px',
    display: 'flex',
    alignmentBaseline: 'baseline',
    alignItems: 'center',
    flexGrow: 0,
    fontFamily: 'Poppins-medium',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    background: 'transparent',
    border: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(163, 174, 208, 0.19)',
    },
  },
  avatar: {
    width: '28px',
    height: '28px',
    border: 'none',
    fontSize: 12,
    margin: '0 12px 0 11px',
    flexGrow: 0,
  },
  name: {
    height: '28px',
    width: '78px',
    minWidth: '78px',
    margin: '1px 12px 0 0',
    fontSize: '12px',
    lineHeight: 2.33,
    letterSpacing: '-0.24px',
    textAlign: 'left',
    color: deepDarkBlue,
    flexGrow: 0,
    overflow: 'hidden',
  },
  email: {
    height: '28px',
    Width: '152px',
    margin: '1px 9px 0px 0px',
    fontSize: '10px',
    lineHeight: 2.8,
    letterSpacing: '-0.2px',
    textAlign: 'left',
    color: blueGrey,
    flexGrow: 0,
    overflow: 'hidden',
  },
}));

export default useStyles;
