/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { createMessageCategory } from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';
import IMessageCategoryItem from 'src/common/interfaces/IMessageCategoryItem';
import IEditCategories from 'src/common/interfaces/IEditCategories';
import ModifyCategories from 'src/components/Selector/components/Category/ModifyCategories';
import * as Yup from 'yup';

interface SubmitPropsType {
  category: string;
}
const useEditCategories = (props: IEditCategories) => {
  const [emoji, setEmoji] = useState('');
  const [btnOpen, setbtnOpen] = useState(true);
  const [emojiListOpen, setEmojiListOpen] = useState(true);
  const { open, handleClose, messageCategoryOptions, projectId, companyId, setIsModify, isModify } = props;
  const messageContent = messageCategoryOptions.map((e: IMessageCategoryItem) => (
    <ModifyCategories
      key={e.messageCategoryId}
      messageCategory={e}
    />
  ));
  const handleSubmit = async ({ category }: SubmitPropsType) => {
    category = category || 'Untitled';
    if (emoji === '') {
      // TODO
      alert('//TODO emoji is required');
      return;
    }
    const createMessageCategoryRes = await createMessageCategory({ companyId, projectId, category, emoji });
    if (createMessageCategoryRes.status === StatusCodes.OK) {
      setIsModify(isModify + 1);
      setbtnOpen(true);
      setEmoji('');
    }
  };

  const categoryFormSchema = Yup.object().shape({
    category: Yup.string().trim().max(15, 'Category name cannot exceed 15 characters'),
  });
  return { btnOpen,
    messageContent,
    emojiListOpen,
    setEmojiListOpen,
    open,
    handleClose,
    handleSubmit,
    setbtnOpen,
    setEmoji,
    emoji,
    categoryFormSchema,
  };
};

export default useEditCategories;
