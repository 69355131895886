import React, { useContext } from 'react';
import { Tabs, Tab, Grid, Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { usePathVariable } from 'src/common/customHooks';
import { ReactComponent as InviteIcon } from 'src/assets/svgs/inviteIcon.svg';
import { CompanyContext } from 'src/context/CompanyContext';
import { useStyles } from './CompanyPageSubNav.style';

function CompanyPageSubNav() {
  const classes = useStyles();
  const { companyId } = usePathVariable();
  const listItem = [
    { id: 0, label: 'Crew', to: `/companies/${companyId}/company/companycrew` },
    { id: 1, label: 'Information', to: `/companies/${companyId}/company/companyinfo` },
  ];
  const { ifOpenInviteMemberPanel, setIfOpenInviteMemberPanel, isCompanyManager } = useContext(CompanyContext);
  return (

    <Grid className={classes.root} container>
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        xs={8}
        sm={9}
        md={10}
      >
        <Tabs value={false}>
          { listItem.map((item) => (
            <Tab
              key={item.id}
              label={item.label}
              className={classes.tab}
              component={NavLink}
              to={item.to}
              activeClassName={classes.active}
              disableTouchRipple
            />
          ))}
        </Tabs>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-end"
        xs={1}
      >
        <Button
          className={classes.invite_button}
          startIcon={<InviteIcon className={classes.invite_icon} />}
          variant="contained"
          color="primary"
          data-testid="invite-button"
          onClick={() => { setIfOpenInviteMemberPanel(!ifOpenInviteMemberPanel); }}
          disabled={!isCompanyManager}
        >
          Invite
        </Button>
      </Grid>
    </Grid>

  );
}

export default CompanyPageSubNav;
