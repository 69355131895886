import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  crewContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    height: '50px',
    inlineHeight: '50px',
    textAlign: 'left',
    marginTop: '50px',
    borderRadius: '10px',
    marginBottom: '30px',
  },
  tableHeaderContent: {
    width: '50%',
    textAlign: 'left',
    fontWeight: 'bold',
    marginLeft: '8%',
  },
  employeeInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    height: '50px',
    inlineHeight: '50px',
    textAlign: 'left',
    marginTop: '5px',
    borderRadius: '10px',
  },
  employeeAvatarName: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    textAlign: 'left',
    fontWeight: 'bold',
    marginLeft: '8%',
  },
  empolyeeName: {
    marginLeft: '10px',
  },
  employeeEmail: {
    width: '50%',
    textAlign: 'left',
    fontWeight: 'bold',
    marginLeft: '8%',
  },
  avatar: {
    position: 'relative',
    width: '35px',
    height: '35px',
    border: 'none',
    fontSize: 14,
  },
}));

export default useStyles;
