import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { awLightPurple, blueGrey } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  listText: {
    flexGrow: 0,
    margin: '0 0 0 12px',
    marginLeft: '-20px',
    color: blueGrey,
    fontFamily: 'Poppins-Regular',
    fontSize: '18px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '30px',
    letterSpacing: '-0.36px',
    textAlign: 'left',
  },
  logout: {
    '&:hover': {
      backgroundColor: awLightPurple,
    },
  },
}));

export default useStyles;
