/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import { IconButton, TextField, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightSharpIcon from '@material-ui/icons/ArrowRightSharp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddIcon from '@material-ui/icons/Add';
import * as Yup from 'yup';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { updateTodoListTitle } from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';
import { TodoContext } from 'src/context/TodoContext';
import { useAuth, usePathVariable } from 'src/common/customHooks';
import ITodoList from 'src/common/interfaces/Dto/Todo/TodoList/ITodoList';
import useStyles from './TodoListHeader.style';

interface IProps {
  todoList: ITodoList;
  ifDisplayTodoItems: boolean;
  setIfDisplayTodoItems: React.Dispatch<React.SetStateAction<boolean>>;
}
interface IUpdateTodoListTitle {
  title: string;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().max(255, 'At most 255 characters.'),
});

function TodoListHeader(props: IProps) {
  const classes = useStyles();
  const { todoList, ifDisplayTodoItems, setIfDisplayTodoItems } = props;
  const { id: todoListId, todoListTitle } = todoList;
  const { isProjectMember, isCompanyManager } = useAuth();
  const { projectId, companyId } = usePathVariable();
  const [editable, setEditable] = useState(false);

  const initialValues: IUpdateTodoListTitle = {
    title: todoListTitle,
  };

  const { addTodoItem, setUpdate, isAddingTodoItem } = useContext(TodoContext);
  const displayTodoItems = () => setIfDisplayTodoItems(true);
  const hideTodoItems = () => {
    setIfDisplayTodoItems(false);
    setUpdate((prevState) => prevState + 1);
  };
  const handleOnSubmit = async ({ title }: IUpdateTodoListTitle) => {
    const resp = await updateTodoListTitle({ companyId, projectId, todoListId, todoListTitle: (title === '' ? 'Untitled section' : title.trim()) });
    if (resp.status === StatusCodes.OK) {
      setUpdate((prevState) => prevState + 1);
      setEditable(false);
    }
  };

  return (
    <div className={classes.section}>
      {ifDisplayTodoItems
        ? (
          <ArrowDropDownIcon
            className={classes.arrow}
            onClick={hideTodoItems}
            data-testid="btn-show-todolist"
          />
        )
        : (
          <ArrowRightSharpIcon
            className={classes.arrow}
            onClick={displayTodoItems}
            data-testid="btn-hide-todolist"
          />
        )}

      {(isProjectMember || isCompanyManager) && editable
        ? (
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
          >
            {() => (
              <Form>
                <Field
                  name="title"
                  as={TextField}
                  helperText={<ErrorMessage name="title" />}
                  inputProps={{
                    size: 27.5,
                  }}
                />
                <IconButton size="small" type="submit" data-testid="btn-submit">
                  <AddIcon className={classes.add_icon} />
                </IconButton>
              </Form>
            )}
          </Formik>
        )
        : (
          <>
            <Typography
              className={classes.name}
              component="span"
              noWrap
            >
              {todoListTitle}
            </Typography>
            {(isProjectMember || isCompanyManager) && (
              <>
                <IconButton
                  className={classes.todo_add_button}
                  size="small"
                  disabled={isAddingTodoItem}
                  onClick={() => {
                    setIfDisplayTodoItems(true);
                    addTodoItem(todoListId);
                  }}
                  data-testid="btn-add-item"
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  size="small"
                  component="span"
                  onClick={() => setEditable(true)}
                  data-testid="btn-edit"
                >
                  <MoreHorizIcon className={classes.more_icon} />
                </IconButton>
              </>
            )}
          </>
        )}
    </div>
  );
}

export default TodoListHeader;
