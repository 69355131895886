const compare = (a: any, b: any) => {
  if (a.name.charAt(0).match(/[a-z]/i) && !b.name.charAt(0).match(/[a-z]/i)) {
    return -1;
  }
  if (!a.name.charAt(0).match(/[a-z]/i) && b.name.charAt(0).match(/[a-z]/i)) {
    return 1;
  }
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return a.name - b.name;
};

export default compare;
