/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import IMessageCategoryItem from 'src/common/interfaces/IMessageCategoryItem';
import CreateIcon from '@mui/icons-material/Create';
import { IconButton } from '@mui/material';
import useStyles from './ModifyCategories.style';

interface PropsType {
  messageCategory: IMessageCategoryItem;
}

function ModifyCategories(props: PropsType) {
  const classes = useStyles();
  const { messageCategory } = props;
  const { messageCategoryId, categoryName, emoji } = messageCategory;

  return (
    <div
      className={classes.container}
      data-testid="modify button"
    >
      <div className={classes.messageCategoryContent}>
        <span>{emoji}</span>
        {' '}
        <span>{categoryName}</span>
      </div>
      <IconButton
        type="submit"
        key={messageCategoryId}
        onClick={() => console.log('modify message category')}
      >
        <CreateIcon className={classes.create} />
      </IconButton>
    </div>
  );
}

export default ModifyCategories;
