import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { awPurple, awLightPurple, deepDarkBlue, blueGrey } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  listText: {
    flexGrow: 0,
    margin: '0 0 0 12px',
    marginLeft: '-20px',
  },
  active: {
    backgroundColor: awLightPurple,
    borderRight: 'solid 4px',
    borderColor: awPurple,
    color: deepDarkBlue,
    fontFamily: 'Poppins-SemiBold',
  },
  button: {
    marginTop: '2rem',
    '&:hover': {
      backgroundColor: awLightPurple,
    },
  },
  root: {
    color: blueGrey,
    fontFamily: 'Poppins-Regular',
    fontSize: '18px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '30px',
    letterSpacing: '-0.36px',
    textAlign: 'left',
    cursor: 'not-allowed',
  },
}));

export default useStyles;
