import { makeStyles } from '@material-ui/core/styles';
import { deepDarkBlue } from 'src/app/color';

const useStyles = makeStyles(() => ({
  container: {
    margin: '10px',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Poppins-regular',
    fontSize: '24px',
    color: deepDarkBlue,
    fontWeight: 600,
    lineHeight: 1.75,
    alignSelf: 'flex-start',
    marginTop: 0,
    marginBottom: '48px',
  },
  emptyContainer: {
    height: '322px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
