import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { lightYellow, orange } from 'src/app/color';

export default makeStyles((theme: Theme) => createStyles({
  resend: {
    margin: '20px 0',
    padding: '3%',
    borderRadius: '10px',
    border: 'solid 1px #f8991f',
    backgroundColor: lightYellow,
  },
  warningIcon: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  resend_message: {
    fontWeight: 500,
    textAlign: 'left',
    color: orange,
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '0.875rem',
    },
  },
  resend_link: {
    fontFamily: 'Poppins-Regular',
    fontSize: '0.75rem',
    fontWeight: 500,
    textAlign: 'left',
    color: orange,
    paddingLeft: '53px',
  },
}));
