import React from 'react';
import {
  Button,
  Typography,
  Dialog,
} from '@material-ui/core';
import passwordDialog from 'src/assets/svgs/passwordDialog.svg';
import useStyles from './InvitationDialog.style';
import { useDialog } from './hooks/useDialog';

interface Props {
  display: boolean;
}
function InvitationDialog({ display }: Props) {
  const classes = useStyles();
  const { freezeTime, redirect } = useDialog(display);
  return (
    <Dialog aria-labelledby="customized-dialog-title" open={display}>
      <div className={classes.dialog_content}>
        <img src={passwordDialog} className={classes.img} alt="passwordDialog" />
        <Typography align="center" className={classes.change_password}>
          Your invitation link is invalid.
        </Typography>
        <Button
          type="button"
          variant="contained"
          color="primary"
          fullWidth
          onClick={redirect}
        >
          Go Back
          {freezeTime !== 0 && ` (${freezeTime - 1})`}
        </Button>
      </div>
    </Dialog>
  );
}
export default InvitationDialog;
