import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { darkBlue } from 'src/app/color';

const borderRadius = 10;
const useStyles = makeStyles((theme: Theme) => createStyles({
  newMessageSection: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 0px 12px 0px',
    height: '35px',
  },
  newMessageText: {
    fontSize: '18px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    float: 'left',
    color: darkBlue,
    maxWidth: '500px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
    },
  },
  newMessageButton: {
    marginLeft: '15px',
    marginRight: '-5px',
    color: darkBlue,
  },
  emptyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageList: {
    marginBottom: '25px',
  },
  messageItems: {
    '&>:first-child': {
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
    },
    '&>:last-child': {
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
  },
}));

export default useStyles;
