import React, { useState } from 'react';
import { useFetch, usePathVariable } from 'src/common/customHooks';
import { fetchContributionTodoItemsList } from 'src/utils/apiUtils';
import Contributions from 'src/pages/components/DashBoardPage/components/Contributions/Contributions';
import ContributionActivities from 'src/pages/components/DashBoardPage/components/ContributionActivities/ContributionActivities';
import IContributionActivities from 'src/common/interfaces/Dto/Todo/TodoItem/IContributionActivities';

interface IPropType {
  today: string
}
function ContributionArea({ today }: IPropType) {
  const { companyId } = usePathVariable();
  const userId = localStorage.getItem('userId');
  const [chooseDay, setChooseDay] = useState(today);

  const { data: contributionTodoItemsListData, loading } = useFetch(
    fetchContributionTodoItemsList,
    [companyId, userId],
    {} as { [key: string]: IContributionActivities },
  );

  return (
    <>
      <Contributions
        setChooseDay={setChooseDay}
        loading={loading}
        contributionTodoItemsListData={contributionTodoItemsListData}
      />
      <ContributionActivities
        chooseDay={chooseDay}
        loading={loading}
        contributionTodoItemsListData={contributionTodoItemsListData}
      />
    </>
  );
}

export default ContributionArea;
