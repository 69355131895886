import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    minWidth: 0,
  },
}));

export default useStyles;
