import { makeStyles } from '@material-ui/core/styles';
import { white } from 'src/app/color';

const useStyles = makeStyles(() => ({

  bottom_card_name: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 30px 0px 30px',
    borderBottom: 'grey',
  },
  submit_button: {
    float: 'right',
    width: 150,
    height: 35,
    borderRadius: '30px',
    margin: '30px 33px 0px 0px',
    fontFamily: 'Poppins-Regular',
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: white,
    textTransform: 'none',
  },
}));
export default useStyles;
