import { createStyles, makeStyles } from '@material-ui/core/styles';
import { awDeepPurple, stoneGrey, grey, tomatoRed } from 'src/app/color';

export default makeStyles(() => createStyles({
  forget_link: {
    fontSize: '0.875rem',
    textAlign: 'left',
    color: awDeepPurple,
  },
  signup_link: {
    fontFamily: 'Poppins-Medium',
    fontSize: '0.875rem',
    textAlign: 'left',
    color: awDeepPurple,
    fontWeight: 550,
  },
  account: {
    fontFamily: 'Poppins-Medium',
    fontSize: '0.875rem',
    textAlign: 'left',
    color: grey,
    fontWeight: 550,
  },
  label: {
    fontFamily: 'Poppins-Medium',
    fontSize: '0.875rem',
    color: stoneGrey,
    fontWeight: 500,
  },
  error: {
    display: 'block',
  },
  img: {
    display: 'inline',
    float: 'left',
  },
  errormessage: {
    fontFamily: 'Poppins-SemiBold',
    color: tomatoRed,
    fontWeight: 600,
    marginLeft: '8px',
    display: 'inline',
  },
  login_button: {
    textTransform: 'initial',
  },
}));
