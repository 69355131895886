import { createStyles, Theme, makeStyles, alpha } from '@material-ui/core/styles';
import { darkBlue, deepDarkBlue, lightGrey } from 'src/app/color';

const useStyles = makeStyles((theme: Theme) => createStyles({
  appBar: {
    backgroundColor: alpha(lightGrey, 0.9),
    padding: theme.spacing(1, 0, 1, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },
  appBar_scroll: {
    backgroundColor: alpha(lightGrey, 0.9),
    padding: theme.spacing(1, 0, 0, 0),
    borderBottom: 'Solid 1px rgba(0, 0, 0, 0.12)',
  },
  dashboard_title: {
    flexGrow: 1,
    marginTop: '1.1rem',
    marginLeft: '3.2rem',
    marginRight: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0rem',
    },
  },
  text_welcome: {
    color: darkBlue,
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  text_date: {
    color: 'rgba(54, 62, 109, 0.9)',
    fontSize: '1rem',
    marginTop: '0.5rem',
  },
  normal_title: {
    flexGrow: 1,
    marginLeft: '3.2rem',
    marginRight: '1.2rem',
    color: darkBlue,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0rem',
    },
  },
  search: {
    marginRight: '1.5rem',
    position: 'relative',
    borderRadius: '10px',
    backgroundColor: alpha(theme.palette.common.white, 0.8),
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: deepDarkBlue,
    height: '2.5rem',
  },
  inputInput: {
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '29rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  invite_button: {
    height: '2.5rem',
    textTransform: 'capitalize',
    marginLeft: '2%',
    marginRight: '1%',
    width: '80px',
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      width: '40px',
      fontSize: 0,
    },
  },
  invite_icon: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '9px',
    },
  },
}));

export default useStyles;
