import React from 'react';
import { Grid, Box } from '@material-ui/core';
import IContributionActivities from '../../../../../../../common/interfaces/Dto/Todo/TodoItem/IContributionActivities';
import EmptyPlaceholder from './components/EmptyPlaceholder/EmptyPlaceholder';
import ContributionActivityItem from './components/ContributionActivityItem/ContributionActivityItem';

interface IPropType {
  taskArray: IContributionActivities[];
}

function ContributionActivity({ taskArray }: IPropType) {
  const taskCount = taskArray?.length;

  return (
    <Grid container>
      {taskCount === 0 && (
        <Box sx={{ mx: 'auto' }}>
          <EmptyPlaceholder />
        </Box>
      )}
      {taskArray?.map((taskItem: IContributionActivities) => (
        <Grid item xs={4} key={taskItem.taskName}>
          <ContributionActivityItem
            taskName={taskItem.taskName}
            dueDate={taskItem.dueDate}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default ContributionActivity;
