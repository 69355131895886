import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { AuthRoute } from 'src/components/CustomRoutes/CustomRoutes';
import ProjectMembersPanel from 'src/pages/components/ProjectPanel/components/ProjectMembersPanel/ProjectMembersPanel';
import EditProject from 'src/pages/components/ProjectPanel/components/EditProject/EditProject';
import TodoPanel from 'src/pages/components/TodosPanelPage/TodoPanelPage';
import MessageBoard from 'src/pages/components/MessageBoard/MessageBoard';
// import NewMessagePage from 'src/pages/components/MessageBoard/components/NewMessagePage/NewMessagePage';
// import MessageDetails from 'src/pages/components/MessageBoard/components/MessageDetails/MessageDetails';
import Files from 'src/components/Files/Files';
import InnerProjectHeader from './components/InnerProjectHeader/InnerProjectHeader';
import useStyles from './InnerProjectPage.style';
import SchedulePanelPage from '../SchedulePanelPage/SchedulePanelPage';
import BoardPanelPage from '../BoardPanelPage';

function InnerProjectPage() {
  const classes = useStyles();
  return (
    <>
      <InnerProjectHeader />
      <div className={classes.mainPaper}>
        <Switch>
          <Route path="/companies/:companyId(\d+)/projects/:projectId(\d+)/members" component={ProjectMembersPanel} />
          <AuthRoute path="/companies/:companyId(\d+)/projects/:projectId(\d+)/edit" component={EditProject} roles={['Company Manager', 'Project Manager']} />
          <Route path="/companies/:companyId(\d+)/projects/:projectId(\d+)/board" component={BoardPanelPage} />
          <Route exact path="/companies/:companyId(\d+)/projects/:projectId(\d+)/messages" component={MessageBoard} />
          {/* <Route path="/companies/:companyId(\d+)/projects/:projectId(\d+)/messages/:messageId(\d+)" component={MessageDetails} /> */}
          {/* <AuthRoute path="/companies/:companyId(\d+)/projects/:projectId(\d+)/messages/new" component={NewMessagePage} roles={['Company Manager', 'Project Member']} /> */}
          <Route path="/companies/:companyId(\d+)/projects/:projectId(\d+)/todolistset" component={TodoPanel} />
          <Route path="/companies/:companyId(\d+)/projects/:projectId(\d+)/schedules" component={SchedulePanelPage} />
          <Route path="/companies/:companyId(\d+)/projects/:projectId(\d+)/files" component={Files} />
          <Redirect to="/companies/:companyId(\d+)/projects/:projectId(\d+)/todolistset" />
        </Switch>
      </div>
    </>
  );
}

export default InnerProjectPage;
