import React from 'react';
import { usePathVariable } from 'src/common/customHooks';
import MessageForm from './components/MessageForm/MessageForm';

function AddNewMessage() {
  const { projectId, companyId } = usePathVariable();
  return (
    <MessageForm projectId={projectId} companyId={companyId} />
  );
}
export default AddNewMessage;
