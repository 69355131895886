import React from 'react';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Tooltip } from '@material-ui/core';
import useStyles from './NavItem.style';

interface NavbarItemProps {
  text: string
  path: string
  icon: any
  exact?: boolean
  disabled?: boolean
}

function Item({ text, path, icon: Icon, exact, disabled }: NavbarItemProps) {
  const classes = useStyles();
  return (
    <Tooltip
      title={disabled ? 'Developing...' : ''}
      placement="bottom"
    >
      <span className={classes.root}>
        <ListItem
          className={classes.button}
          button
          disabled={disabled}
          component={NavLink}
          exact={exact}
          to={path}
          activeClassName={classes.active}
        >
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <span className={classes.listText}>
            {text}
          </span>
        </ListItem>
      </span>
    </Tooltip>
  );
}

Item.defaultProps = {
  exact: false,
  disabled: false,
};

export default Item;
