import { createStyles, makeStyles } from '@material-ui/core/styles';
import { darkBlue } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  company_switch: {
    display: 'flex',
    flexDirection: 'column',
  },
  company_switch_button: {
    maxWidth: '200px',
    textTransform: 'none',
  },
  title: {
    color: darkBlue,
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
  menu: {
    position: 'fixed',
    left: '10px',
    zIndex: 9999,
  },
}));

export default useStyles;
