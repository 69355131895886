import React, { useEffect, useState } from 'react';
import { useAuth, usePathVariable } from 'src/common/customHooks';
import { NavLink } from 'react-router-dom';
import { Tabs, Tab, Grid, Fab, Avatar } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import { getProjectInfo } from 'src/utils/apiUtils';
import { StatusCodes } from 'http-status-codes';
import IProjectInfo from 'src/common/interfaces/IProjectInfo';
import { stringAvatar, stringToColor } from 'src/common/functions/toDoPageHeaderFunction';
import InvitePopUp from 'src/components/Header/components/SubNavBar/components/InvitePopup';
import { useStyles } from './SubNavBar.style';

function SubNavBar() {
  const classes = useStyles();
  const { companyId, projectId } = usePathVariable();

  const { isCompanyManager, isProjectMember } = useAuth();
  const disabled = !isCompanyManager && !isProjectMember;

  const listItem = [
    { id: 0, label: 'List', to: `/companies/${companyId}/projects/${projectId}/todolistset` },
    { id: 1, label: 'Board', to: `/companies/${companyId}/projects/${projectId}/board` },
    { id: 2, label: 'Messages', to: `/companies/${companyId}/projects/${projectId}/messages` },
    { id: 3, label: 'Schedule', to: `/companies/${companyId}/projects/${projectId}/schedules` },
    { id: 4, label: 'Files', to: `/companies/${companyId}/projects/${projectId}/files` },
  ];

  const [invitePopupDisplay, setInvitePopupDisplay] = useState(false);
  const handleInvitePopup = () => {
    setInvitePopupDisplay(!invitePopupDisplay);
  };
  const [projectUserNames, setProjectUserNames] = useState([] as string[]);
  const [projectInfo, setProjectInfo] = useState(Object as unknown as IProjectInfo);

  const getProjectDetail = async () => {
    const projectInfoResponse = await getProjectInfo(companyId, projectId);
    if (projectInfoResponse.status === StatusCodes.OK) {
      setProjectInfo(projectInfoResponse.data);
      setProjectUserNames(projectInfoResponse.data.projectUserNames);
    }
  };

  useEffect(() => {
    getProjectDetail();
  }, [projectId]);

  return (
    <Grid className={classes.root} container>
      <Grid
        md={6}
        xs={7}
        lg={8}
        item
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Tabs value={false}>
            { listItem.map((item) => (
              <Tab
                key={item.id}
                label={item.label}
                className={classes.tab}
                component={NavLink}
                to={item.to}
                activeClassName={classes.active}
                disableTouchRipple
              />
            ))}
          </Tabs>
        </Grid>
      </Grid>
      <Grid
        xs={2}
        item
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Fab
            size="small"
            aria-label="add"
            className={classes.fab}
            onClick={handleInvitePopup}
            disabled={disabled}
          >
            <AddIcon className={classes.add} />
          </Fab>
        </Grid>
      </Grid>
      <Grid
        item
        xs={1}
        md={2}
        lg={2}
        xl={1}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          className={classes.avatar_group}
        >
          <AvatarGroup max={4}>
            {projectUserNames?.slice(0, 3).map((item: string) => (
              <Avatar
                key={item}
                style={{ backgroundColor: stringToColor(item) }}
                className={classes.avatar}
              >
                {stringAvatar(item)}
              </Avatar>
            ))}
            {projectUserNames?.length > 3 && (
              <Avatar className={classes.avatar_number}>
                {projectUserNames.length - 3}
                +
              </Avatar>
            )}
          </AvatarGroup>
        </Grid>
      </Grid>
      { invitePopupDisplay
        ? (
          <InvitePopUp
            setInvitePopupDisplay={setInvitePopupDisplay}
            setProjectUserNames={setProjectUserNames}
            projectUserNames={projectUserNames}
            projectInfo={projectInfo}
          />
        ) : null}
    </Grid>
  );
}

export default SubNavBar;
