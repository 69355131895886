/* eslint-disable react-hooks/exhaustive-deps */
import { CardContent, Typography } from '@material-ui/core';
import React, { } from 'react';
import Card from '@material-ui/core/Card';
import moment from 'moment';
import dateFormater from 'src/common/functions/dateFormater';
import useStyles from './TaskCardItem.style';

interface IProps {
  taskName: string,
  projectName: string,
  dueDate: string,
  title:string
}

function TaskCardItem({ title, taskName, projectName, dueDate }:IProps) {
  const classes = useStyles();
  const dueDateFormated = dateFormater(moment(dueDate).format('ll'));
  return (
    <Card className={classes.root}>
      <CardContent className={classes.card_content}>
        <Typography className={classes.project_task_one_line}>
          <Typography component="span" className={classes.project_name}>
            {projectName}
          </Typography>
          <Typography component="span" className={classes.task_name}>
            {taskName}
          </Typography>
        </Typography>
        <Typography
          className={`${title === 'Overdue' ? `${classes.overdue_date}` : `${classes.date}`}`}
          data-testid="duedate"
        >
          {dueDateFormated}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default TaskCardItem;
