/* eslint-disable implicit-arrow-linebreak */
import axios from 'axios';
import IRecoverPassword from 'src/common/interfaces/IRecoverPassword';
import IResetPassword from 'src/common/interfaces/IResetPassword';
import IUpdateNewTodoItemCompleted from 'src/common/interfaces/IUpdateNewTodoItemCompleted';
import IEditCompanyInfo from 'src/common/interfaces/IEditCompanyInfo';
import ITodoList from 'src/common/interfaces/Dto/Todo/TodoList/ITodoList';
import ILogin from '../common/interfaces/ILogin';
import ISignUp from '../common/interfaces/ISignUp';
import IResendEmail from '../common/interfaces/IResendEmail';
import IInvitedSignUp from '../common/interfaces/IInvitedSignUp';
import ICreateCompany from '../common/interfaces/ICreateCompany';
import IProjectProfile from '../common/interfaces/IProjectProfile';
import IVerifyEmailExists from '../common/interfaces/IVerifyEmailExists';
import IGetInvitationLink from '../common/interfaces/IGetInvitationLink';
import ICreateProject from '../common/interfaces/ICreateProject';
import ICreateTodoList from '../common/interfaces/ICreateTodoList';
import ICreateTodoItem from '../common/interfaces/Dto/Todo/TodoItem/ICreateTodoItem';
import IFetchSingleTodoList from '../common/interfaces/Dto/Todo/TodoList/IFetchSingleTodoList';
import IUpdateTodoList from '../common/interfaces/Dto/Todo/TodoList/IUpdateTodoList';
import IFetchTodoItem from '../common/interfaces/IFetchTodoItem';
import ICreateMessage from '../common/interfaces/ICreateMessage';
import IUpdateTodoItem from '../common/interfaces/Dto/Todo/TodoItem/IUpdateTodoItem';
import IUpdateTodoItemCompleted from '../common/interfaces/IUpdateTodoItemCompleted';

const baseUrl = process.env.REACT_APP_ASYNC_WORK_API;

axios.interceptors.request.use(
  async (config) => {
    if (localStorage.getItem('accessToken')) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (res: any) => res,
  (error: any) => {
    const status = error.response ? error.response.status : null;
    if (status === 406) {
      localStorage.clear();
      window.location.replace('/');
    }
    if (status === 403) {
      window.location.replace('/forbidden');
    }
    return Promise.reject(error);
  },
);

// Start LoginForm
export const login = ({ email, password }: ILogin) =>
  axios({
    method: 'post',
    url: `${baseUrl}/login`,
    data: {
      email,
      password,
    },
  });

export const verifyStatus = (email: string, token: string) =>
  axios({
    method: 'get',
    url: `${baseUrl}/login`,
    headers: { Authorization: `Bearer ${token}` },
    params: {
      email,
    },
  });

export const verifyCompany = (email: string) =>
  axios({
    method: 'get',
    url: `${baseUrl}/company`,
    params: {
      email,
    },
  });

export const getAllUserCompanies = (email: string | null, userId: string | null, companyId: string | null) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/`,
    params: {
      email,
      userId,
      companyId,
    },
  });
// End LoginForm

// Start SignUpForm
export const verifyEmailExists = ({ email }: IVerifyEmailExists) =>
  axios({
    method: 'get',
    url: `${baseUrl}/signup`,
    params: {
      email,
    },
  });

export const signup = ({ name, email, password }: ISignUp) =>
  axios({
    method: 'post',
    url: `${baseUrl}/signup`,
    data: {
      name,
      email,
      password,
    },
  });

export const sendPasswordRecoveryLink = ({ email }: IRecoverPassword) =>
  axios({
    method: 'post',
    url: `${baseUrl}/password`,
    params: {
      email,
    },
  });

export const resetPassword = ({ code, password }: IResetPassword) =>
  axios({
    method: 'put',
    url: `${baseUrl}/password-reset`,
    data: {
      accessToken: code,
      password,
    },
  });

export const getResetterInfo = (code: string | null) =>
  axios({
    method: 'get',
    url: `${baseUrl}/password-reset/info`,
    params: {
      code,
    },
  });

export const getInvitationInfo = (code: string | null) =>
  axios({
    method: 'get',
    url: `${baseUrl}/invitations/info`,
    params: {
      code,
    },
  });

export const invitationsRegister = ({ name, email, password, companyId, title }: IInvitedSignUp) =>
  axios({
    method: 'post',
    url: `${baseUrl}/invitations/register`,
    data: {
      name,
      email,
      password,
      companyId,
      title,
    },
  });

export const verifyActiveUser = (code: string | null) =>
  axios({
    method: 'post',
    url: `${baseUrl}/verify`,
    params: {
      code,
    },
  });

export const resendActivationLink = ({ email }: IResendEmail) =>
  axios({
    method: 'post',
    url: `${baseUrl}/resend`,
    data: {
      email,
    },
  });
// End SignUpForm

// Start FirstLoginForm
export const createCompany = ({ email, company, title }: ICreateCompany) =>
  axios({
    method: 'post',
    url: `${baseUrl}/companies`,
    data: {
      adminEmail: email,
      name: company,
      userTitle: title,
    },
  });
// End FirstLoginForm

// Start GetCompanyInfo and Colleagues list
export const getCompanyInfoColleagues = (email: string | null) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/company-info`,
    params: {
      email,
    },
  });
// End GetCompanyInfo and Colleagues list

// Start pre-fill name and description of company
export const fetchCompanyProfile = (companyId: string | null) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/profile`,
  });
// End prefill name and description of company

// Start update company profile
export const updateCompanyProfile = ({ companyId, name, description }: IEditCompanyInfo) =>
  axios({
    method: 'put',
    url: `${baseUrl}/companies/${companyId}/profile`,
    data: {
      companyId,
      name,
      description,
    },
  });
// End update company profile

// Start companyPanel
export const getCompanyInfo = (companyId: string | null) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}`,
  });

export const getEmployeeInfo = (companyId: string) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/employees`,
  });
// End companyPanel

// Start InvitationForm
export const getInvitationLink = ({ name, email, title, companyId }: IGetInvitationLink) =>
  axios({
    method: 'get',
    url: `${baseUrl}/invitations/companies`,
    params: {
      name,
      email,
      title,
      companyId,
    },
  });
// End InvitationForm

// Start CreateProject//
export const createProject = ({ name, ownerId, companyId, description, ifPrivate, defaultView }: ICreateProject) =>
  axios({
    method: 'post',
    url: `${baseUrl}/companies/${companyId}/projects`,
    data: {
      name,
      ownerId,
      companyId,
      description,
      ifPrivate,
      defaultView,
    },
  });
// End CreateProject//

// Start GetProjectList//
export const getProjectList = (companyId: string, userId: string | null) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/projects`,
    params: {
      userId,
    },
  });
// End GetProjectList//

// Start GetProjectInfo and ProjectUser list//
export const getProjectInfo = (companyId: string, projectId: string) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/project-info`,
  });

export const fetchProjectMemberList = (companyId: string, projectId: string) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/members`,
  });
// End GetProjectInfo and ProjectUser list//

// Start UpdateProjectProfile//
export const updateProjectProfile = ({ companyId, projectId, name, description }: IProjectProfile) =>
  axios({
    method: 'put',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/project-info`,
    data: {
      projectId,
      name,
      description,
    },
  });
// End UpdateProjectProfile//

// Start fetch todoLists
export const fetchTodoLists = (companyId: string, projectId: string, quantity: number) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/todolists`,
    params: {
      quantity,
    },
  });
// End fetch todoLists

// Start fetch todoList by id
export const fetchSingleTodoList = ({ companyId, projectId, todolistId }: IFetchSingleTodoList) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/todolists/${todolistId}`,
  });
// End fetch todoList by id

// Start create todoList
export const createTodoList = ({ companyId, projectId, todoListTitle }: ICreateTodoList) =>
  axios({
    method: 'post',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/todolists`,
    data: {
      todoListTitle,
    },
  });
// End create todoList

// Start create todoList
export const updateTodoListTitle = ({ companyId, projectId, todoListId, todoListTitle }: IUpdateTodoList) =>
  axios({
    method: 'put',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/todolists/${todoListId}`,
    data: {
      todoListTitle,
    },
  });
// End create todoList

// Start TodoItemForm
export const createTodoItem = ({
  companyId,
  projectId,
  todoListId,
  createdUserId,
  description,
  priority,
  notes,
  originNotes,
  dueDate,
  subscribersIds,
}: ICreateTodoItem) =>
  axios({
    method: 'post',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/todolists/${todoListId}/todoitems`,
    data: {
      todoListId,
      createdUserId,
      notes,
      priority,
      originNotes,
      description,
      dueDate,
      subscribersIds,
    },
  });
// End TodoItemForm

// Start fetch todoItem by id
export const fetchTodoItem = ({ companyId, projectId, todoItemId }: IFetchTodoItem) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/todoitems/${todoItemId}`,
  });
// End fetch todoItem by id

// Create Event
export const createEvent = (
  companyId: string,
  projectId: string,
  title: string,
  description: string,
  isAllDayEvent: boolean,
  startTimeISO: string,
  endTimeISO: string,
) =>
  axios({
    method: 'post',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/events`,
    data: {
      title,
      description,
      isAllDayEvent,
      startTime: startTimeISO,
      endTime: endTimeISO,
    },
  });

// Fetch event list of the day begins at dateTimeUTC
export const getEventList = (companyId: string, projectId: string, dateTimeISO: string) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/events?dayStartsAt=${dateTimeISO}`,
  });

// Start fetch SubscriberInfo by id
export const fetchSubscriberInfo = ({ companyId, projectId, todoItemId }: IFetchTodoItem) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/todoitems/${todoItemId}/assignees`,
  });
// End fetch SubscriberInfo by id

// Start update todoItem
export const updateTodoItem = ({
  companyId,
  todoItemId,
  projectId,
  description,
  priority,
  notes,
  originNotes,
  dueDate,
  subscribersIds,
}: IUpdateTodoItem) =>
  axios({
    method: 'put',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/todoitems/${todoItemId}`,
    data: {
      description,
      priority,
      notes,
      originNotes,
      dueDate,
      subscribersIds,
    },
  });
// End update todoItem

// Start change todoItem completed status
export const changeTodoItemCompletedStatus = ({ companyId, projectId, todoItemId }: IUpdateTodoItemCompleted) =>
  axios({
    method: 'put',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/todoitems/${todoItemId}/completed`,
  });
// End change todoItem completed status

// Start change todoItem completed status
export const changeNewTodoItemCompletedStatus = ({
  companyId,
  projectId,
  todoItemId,
  completedStatus,
}: IUpdateNewTodoItemCompleted) =>
  axios({
    method: 'put',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/todoitems/${todoItemId}/completed`,
    params: { completedStatus },
  });
// End change todoItem completed status

// Start fetch message list
export const fetchMessageList = (companyId: string, projectId: string) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/messages`,
  });
// End fetch message list

// Start create message
export const createMessage = ({
  projectId,
  companyId,
  messageCategoryId,
  messageTitle,
  posterUserId,
  text,
  // html,
  // subscribersIds,
}: ICreateMessage) =>
  axios({
    method: 'post',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/messages`,
    data: {
      projectId,
      companyId,
      messageCategoryId,
      messageTitle,
      posterUserId,
      content: text,
      // originNotes: html,
      // subscribersIds,
    },
  });
// End create message

// Start fetch single message
export const fetchMessage = (companyId: string, projectId: string, messageId: string) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/messages/${messageId}`,
  });
// End fetch single message

// Start fetch card todoitems
export const fetchCardTodoItemLists = (companyId: string, userId: string | null) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/cards`,
    params: {
      userId,
    },
  });
// End fetch card todoitems

export const createMessageCategory = ({ companyId, projectId, category, emoji }: any) =>
  axios({
    method: 'post',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/creation`,
    data: {
      projectId,
      categoryName: category,
      emoji,
    },
  });

export const editMessageCategory = ({ companyId, projectId, category, emoji, categoryId }: any) =>
  axios({
    method: 'put',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/categoryId/${categoryId}/edition`,
    data: {
      projectId,
      categoryName: category,
      emoji,
    },
  });

export const sendCompanyInvitation = (companyId: string, name: string, email: string, title: string) =>
  axios({
    method: 'post',
    url: `${baseUrl}/companies/${companyId}/invite-company-users`,
    data: {
      name,
      email,
      title,
    },
  });

// Start fetch contribution counts
export const fetchContributionTodoItemsCounts = (companyId: string, userId: string | null) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/contributions`,
    params: {
      userId,
    },
  });
// End fetch contribution counts

export const fetchContributionTodoItemsList = (companyId: string, userId: string | null) =>
  axios({
    method: 'get',
    url: `${baseUrl}/companies/${companyId}/contributions/activities`,
    params: {
      userId,
    },
  });

export const addNewCompanyMember = (code: string | null) =>
  axios({
    method: 'post',
    url: `${baseUrl}/accept-company-invitation`,
    params: {
      code,
    },
  });

export const reorderTodoLists = (companyId: string, projectId: string, movedLists: ITodoList[]) =>
  axios({
    method: 'put',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/todolists`,
    data: {
      todoLists: movedLists,
    },
  });

export const moveTodoItems = (companyId: string, projectId: string, movedLists: ITodoList[]) =>
  axios({
    method: 'put',
    url: `${baseUrl}/companies/${companyId}/projects/${projectId}/todoitems/todolists`,
    data: {
      todoLists: movedLists,
    },
  });
