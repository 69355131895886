import { makeStyles } from '@material-ui/core/styles';
import { deepDarkBlue } from 'src/app/color';

const useStyles = makeStyles(() => ({
  rowHeader: {
    minHeight: '46px',
    margin: '20px 50px 28px 8px',
    textTransform: 'none',
    color: deepDarkBlue,
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'center',
    backgroundColor: ' #fff',
    borderRadius: '10px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
  },
  button: {
    textTransform: 'none',
    color: deepDarkBlue,
    fontSize: '14px',
    fontWeight: 600,
  },
  taskName: {
    textAlign: 'left',
  },
  emptyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
