import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { TodoContext } from 'src/context/TodoContext';
import { reorderTodoLists, moveTodoItems } from 'src/utils/apiUtils';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { StatusCodes } from 'http-status-codes';
import Section from './components/Section';
import SectionAdd from './components/SectionAdd';
import useStyles from './Board.style';

function Board() {
  const classes = useStyles();
  const [reorderLists, setReorderLists] = useState(false);
  const [moveLists, setMoveLists] = useState(false);
  const [moveItems, setMoveItems] = useState(false);
  const [moveItemsListId, setMoveItemsListId] = useState(-1);
  const [sourceListId, setSourceListId] = useState(-1);
  const [destinationId, setDestinationId] = useState(-1);
  const { todoLists, setTodoLists, companyId, projectId } = useContext(TodoContext);
  let originIndex = 0;
  let purposeIndex = 0;
  let sourceIndex = 0;
  function reorderItems(listIndex: number, startIndex: number, endIndex: number) {
    // eslint-disable-next-line no-param-reassign
    startIndex = sourceIndex;
    setTodoLists((oldList) => {
      const newList = JSON.parse(JSON.stringify(oldList));
      newList.forEach((id: string, index: number) => {
        if (newList[index].id === listIndex) {
          originIndex = index;
        }
      });
      newList[originIndex].todoItems.splice(endIndex, 0, newList[originIndex].todoItems.splice(startIndex, 1)[0]);
      setMoveItems(true);
      return newList;
    });
  }

  const reorderList = (startIndex: number, endIndex: number) => {
    setTodoLists((oldList) => {
      const newList = JSON.parse(JSON.stringify(oldList));
      newList.splice(endIndex, 0, newList.splice(startIndex, 1)[0]);
      setReorderLists(true);
      return newList;
    });
  };

  const moveList = (sourceListIndex: number, destinationIndex: number, startIndex: number, endIndex: number) => {
    setTodoLists((oldList) => {
      const newList = JSON.parse(JSON.stringify(oldList));
      newList.forEach((id: string, index: number) => {
        if (newList[index].id === sourceListIndex) {
          originIndex = index;
        } else if (newList[index].id === destinationIndex) {
          purposeIndex = index;
        }
      });
      newList[purposeIndex].todoItems.splice(endIndex, 0, newList[originIndex].todoItems.splice(startIndex, 1)[0]);
      setMoveLists(true);
      return newList;
    });
  };

  const onDragEnd = async (result: any) => {
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }

    if (type === 'list') {
      reorderList(source.index, destination.index);
      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      sourceIndex = source.index;
      reorderItems(sInd, source.inedx, destination.index);
      setMoveItemsListId(sInd);
    } else {
      moveList(sInd, dInd, source.index, destination.index);
      setSourceListId(sInd);
      setDestinationId(dInd);
    }
  };

  useEffect(() => {
    const handleDragAndDrop = async () => {
      if (reorderLists) {
        try {
          const { status } = await reorderTodoLists(companyId, projectId, todoLists);
          if (status === StatusCodes.OK) {
            setReorderLists(false);
          }
        } catch (error: any) {
          console.error('reorderTodoLists failed');
        }
      } else if (moveItems && moveItemsListId !== -1) {
        const movedItemsList = todoLists.filter((list) => list.id.toString() === moveItemsListId.toString())[0];
        const movedList = [movedItemsList];
        try {
          const { status } = await moveTodoItems(companyId, projectId, movedList);
          if (status === StatusCodes.OK) {
            setMoveItems(false);
          }
        } catch (error: any) {
          console.error('reorderTodoItems failed');
        }
      } else if (moveLists && sourceListId !== -1 && destinationId !== -1) {
        const sourceList = todoLists.filter((list) => list.id.toString() === sourceListId.toString())[0];
        const destinationList = todoLists.filter((list) => list.id.toString() === destinationId.toString())[0];
        const movedLists = [sourceList, destinationList];
        try {
          const { status } = await moveTodoItems(companyId, projectId, movedLists);
          if (status === StatusCodes.OK) {
            setMoveLists(false);
          }
        } catch (error: any) {
          console.error('reorderTodoItems failed');
        }
      }
    };
    handleDragAndDrop();
  }, [todoLists, reorderLists, moveLists, moveItems, moveItemsListId, sourceListId, destinationId]);

  return (
    <div>
      <Grid container spacing={1} wrap="nowrap" justifyContent="flex-start" direction="row" alignItems="stretch">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="board" type="list" direction="horizontal">
            {(providedList) => (
              <div ref={providedList.innerRef} {...providedList.droppableProps} className={classes.BoardStyle}>
                {todoLists.map((todoList, index) => (
                  <Grid item key={todoList.id} xs="auto" md="auto" lg="auto">
                    <div className={classes.ListStyle}>
                      <Section todoList={todoList} index={index} />
                    </div>
                  </Grid>
                ))}
                {providedList.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Grid item xs>
          <Grid container direction="row-reverse">
            <Grid>
              <SectionAdd />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Board;
