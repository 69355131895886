import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  BoardStyle: {
    display: 'flex',
    justifyContent: 'spaceBetween',
  },
  ListStyle: {
    padding: '4px',
    grid: '4px',
    flexGrow: 1,
  },
}));

export default useStyles;
