/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, TextField, Typography, SvgIcon } from '@material-ui/core';
import { Form, Formik, Field } from 'formik';
import { ReactComponent as nameIcon } from 'src/assets/svgs/nameIcon.svg';
import { ReactComponent as emailIcon } from 'src/assets/svgs/emailIcon.svg';
import { ReactComponent as passwordIcon } from 'src/assets/svgs/passwordIcon.svg';
import { useCode } from 'src/common/customHooks';
import formConfig from './config/index';
import InvitationErrorMessage from '../InvitationErrorMessage/InvitationErrorMessage';
import { useExtraFormConf, useRegisterForm, useDoubleAdornment } from './hooks';
import useStyles from './InvitationRegisterForm.style';
import InvitationDialog from './InvitationDialog';

function InvitationRegisterForm() {
  const classes = useStyles();

  const { getStartAdornment, getErrorMsg } = useExtraFormConf();
  const { getDoubleAdornment, getSingleAdornment, showPassword } = useDoubleAdornment();
  const { code } = useCode();

  const {
    open,
    initialValues,
    validationSchema,
    handleOnSubmit,
    signupError,
    companyName,
  } = useRegisterForm(code);

  const { nameInput, emailInput, passwordInput, confirmPasswordInput } = formConfig;

  let newCompanyName = companyName;
  if (companyName.length > 12) {
    newCompanyName = companyName.substring(0, 12).concat('...');
  }

  const joinTitle = `Join ${newCompanyName} on Async Working`;

  return (
    <div>
      <InvitationDialog display={open} />
      <Typography gutterBottom className={classes.title}>
        {joinTitle}
      </Typography>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <InvitationErrorMessage content={signupError.message} active={signupError.shown} />
            <Field
              {...nameInput}
              as={TextField}
              placeholder="Your name"
              helperText={getErrorMsg('name')}
              InputProps={getStartAdornment(<SvgIcon component={nameIcon} viewBox="0 0 32 32" />)}
            />
            <Field
              {...emailInput}
              as={TextField}
              InputProps={getStartAdornment(<SvgIcon component={emailIcon} viewBox="0 0 32 32" />)}
            />
            <Field
              {...passwordInput}
              as={TextField}
              className={classes.password}
              placeholder="Enter your password"
              type={showPassword ? 'text' : 'password'}
              helperText={getErrorMsg('password')}
              InputProps={getSingleAdornment(<SvgIcon color="action" component={passwordIcon} viewBox="0 0 32 32" />)}
            />
            <Field
              {...confirmPasswordInput}
              as={TextField}
              placeholder="Confirm your password"
              type={showPassword ? 'text' : 'password'}
              helperText={getErrorMsg('confirmPassword')}
              InputProps={getDoubleAdornment(<SvgIcon component={passwordIcon} viewBox="0 0 32 32" />)}
            />
            <Button
              type="submit"
              fullWidth
              data-testid="incitationFormSignup"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={isSubmitting}
            >
              Create account
            </Button>
            <Typography gutterBottom className={classes.account}>
              Already have an account?
              <Link to="/login" className={classes.btn_signin}>
                Log in
              </Link>
            </Typography>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default InvitationRegisterForm;
