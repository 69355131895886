/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import IMessageCategoryItem from 'src/common/interfaces/IMessageCategoryItem';
import * as messageApiUtils from 'src/utils/Message/messageApiUtils';
import { StatusCodes } from 'http-status-codes';

const useMessageCategories = (companyId: string, projectId : string, isModify : number) => {
  const [messageCategories, setMessageCategories] = useState<IMessageCategoryItem[]>([] as IMessageCategoryItem[]);

  useEffect(() => {
    let isSubscribed = true;
    const fetchMessageCategoryList = async () => {
      const resp = await messageApiUtils.fetchMessageCategoryList(companyId, projectId);
      if (isSubscribed && resp.status === StatusCodes.OK) {
        setMessageCategories(resp.data);
      }
    };
    fetchMessageCategoryList();
    return () => { isSubscribed = false; };
  }, [projectId, isModify]);

  return messageCategories;
};

export default useMessageCategories;
