import React, { useContext, useRef } from 'react';
import { Card, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { CompanyContext } from 'src/context/CompanyContext';
import { FormikProps } from 'formik';
import IInviteMember from 'src/common/interfaces/IInviteMember';
import useStyles from './InviteMemberPopupWindow.style';
import InviteForm from './Components/InviteForm';

function InviteMemberPopupWindow() {
  const classes = useStyles();
  const {
    ifOpenInviteMemberPanel,
    setIfOpenInviteMemberPanel,
    inviteMemberInfo,
    setInviteMemberInfo,
    companyInfo,
  } = useContext(CompanyContext);
  const formikRef = useRef<FormikProps<IInviteMember>>(null);
  const handleResetForm = () => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  };
  const { name } = companyInfo;
  return (
    <div className={ifOpenInviteMemberPanel ? classes.popup_container_display : classes.content_hidden}>
      <Card className={classes.popup}>
        <div className={classes.top_card}>
          <Typography className={classes.top_card_content}>Invite people into Company</Typography>
          <CloseIcon
            className={classes.top_card_close}
            onClick={() => {
              setIfOpenInviteMemberPanel(false);
              setInviteMemberInfo({ ...inviteMemberInfo, name: '', email: '', title: '' });
              handleResetForm();
            }}
          />
        </div>
        <hr />
        <Typography className={classes.bottom_card_title} align="center">
          Your employee will get an email that gives them access to
        </Typography>
        <Typography align="center" className={classes.company_name}>
          {name}
        </Typography>
        <InviteForm formikRef={formikRef} />
      </Card>
    </div>
  );
}

export default InviteMemberPopupWindow;
