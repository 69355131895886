import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: '30px',
    justifyContent: 'center',
  },
  emptyContainer: {
    height: '322px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
