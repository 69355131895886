import React from 'react';
import { Grid } from '@material-ui/core';
import { Redirect, Switch, Route } from 'react-router-dom';
import { AuthRoute } from 'src/components/CustomRoutes/CustomRoutes';
import CompanyPanel from 'src/pages/components/CompanyPanel/CompanyPanel';
import ProjectsPage from 'src/pages/components/ProjectsPage/ProjectsPage';
import EditCompany from 'src/pages/components/CompanyPanel/components/EditCompany/EditCompany';
import InnerProjectPage from 'src/pages/components/InnerProjectPage/InnerProjectPage';
import DashBoardPage from 'src/pages/components/DashBoardPage/DashBoardPage';
import CompanyPage from 'src/pages/components/CompanyPage/CompanyPage';
import NavBar from '../NavBar/NavBar';
import useStyles from './DashBoard.style';

function DashBoard() {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <NavBar />
      <div className={classes.content}>
        <Switch>
          <Route exact path="/companies/:companyId(\d+)" component={DashBoardPage} />
          <Route path="/companies/:companyId(\d+)/companypanel" component={CompanyPanel} />
          <AuthRoute path="/companies/:companyId(\d+)/edit" component={EditCompany} roles={['Company Manager']} />
          <Route exact path="/companies/:companyId(\d+)/projects" component={ProjectsPage} />
          <Route path="/companies/:companyId(\d+)/company" component={CompanyPage} />
          <Route path="/companies/:companyId(\d+)/projects/:projectId(\d+)" component={InnerProjectPage} />
          <Redirect to="/companies/:companyId(\d+)" />
        </Switch>
      </div>
    </Grid>
  );
}
export default DashBoard;
