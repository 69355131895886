import {
  makeStyles,
} from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { darkBlue, deepDarkBlue, lightBlue, lightGrey, stoneGrey, tomatoRed, white } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  content_hidden: {
    display: 'none',
  },
  close_icon: {
    color: darkBlue,
  },
  back_icon: {
    color: darkBlue,
  },
  popup_header: {
    padding: '2rem 0',
    position: 'relative',
  },
  close_btn: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'none',
    textDecoration: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  back_btn: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: 'none',
    textDecoration: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  popup_title: {
    fontFamily: 'Poppins-Regular',
    fontSize: '24px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.75,
    letterSpacing: '-0.48px',
    color: darkBlue,
    marginBottom: '12px',
    display: 'inline-block',
  },
  form_container: {
    padding: '0 4rem',
    overflowY: 'auto',
    maxHeight: '70vh',
  },
  nameInput: {
    background: white,
  },
  nameInput_empty: {
    background: lightBlue,
  },
  input_label: {
    fontFamily: 'Poppins-Regular',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: stoneGrey,
    marginBottom: '7px',
    marginTop: '24px',
  },
  view_selectors: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  view_select_btn: {
    width: '196px',
    height: '86px',
    borderRadius: '5px',
    border: 'solid 1px #bdbdbd',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    background: 'transparent',
  },
  view_select_btn_selected: {
    width: '196px',
    height: '86px',
    borderRadius: '5px',
    border: 'solid 1px #7d589f',
    backgroundColor: lightGrey,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  view_icon: {
    marginLeft: '15px',
  },
  view_label: {
    fontFamily: 'Poppins-Regular',
    fontSize: '18px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: deepDarkBlue,
    marginLeft: '19px',
  },
  submit_button: {
    marginTop: '62px',
    fontFamily: 'Poppins-Regular',
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: white,
    textTransform: 'none',
    marginBottom: '40px',
  },
  error: {
    display: 'block',
  },
  img: {
    display: 'inline',
    float: 'left',
  },
  errormessage: {
    fontFamily: 'Poppins-SemiBold',
    color: tomatoRed,
    fontWeight: 600,
    marginLeft: '8px',
    display: 'inline',
  },
}));

export default useStyles;
