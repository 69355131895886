import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  emptyContent: {
    fontFamily: 'Poppins-regular',
    textAlign: 'right',
    fontSize: 18,
    marginTop: 150,
    fontWeight: 'bold',
    display: 'flex',
    flex: 1,
  },
}));

export default useStyles;
