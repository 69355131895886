import IProjectMember from 'src/common/interfaces/IProjectMember';
import ITodoItem from 'src/common/interfaces/Dto/Todo/TodoItem/ITodoItem';

const priorities = [
  { name: 'Low', value: 3 },
  { name: 'Medium', value: 2 },
  { name: 'High', value: 1 },
];

const findPriorityValue = (priority: string) => {
  const priorityItem = priorities.find((item) => item.name === priority);
  return priorityItem?.value || 0;
};

const findAssigneeName = (id: string, projectMembers: IProjectMember[]) => {
  const assignee = projectMembers.find((item) => item.id.toString() === id.toString());
  return assignee?.name || '';
};

const concatAllAssignees = (subscribersIds: string, projectMembers: IProjectMember[]) => {
  if (!subscribersIds) {
    return '';
  }
  const assignees = subscribersIds.split(',').map((id) => findAssigneeName(id, projectMembers));
  return assignees.join('').toUpperCase();
};

const findItemToSortByCriteria = (item: ITodoItem, sortBy: string, projectMembers: IProjectMember[]) => {
  let itemToSort;
  if (sortBy === 'Priority') {
    itemToSort = findPriorityValue(item.priority);
  }
  if (sortBy === 'Assignees') {
    itemToSort = concatAllAssignees(item.subscribersIds, projectMembers);
  }
  if (sortBy === 'Due date') {
    itemToSort = item.dueDate;
  }
  return itemToSort;
};

// Sort todo items by criteria (priority, assignees, due date)
const compareTodoItems = (a: any, b: any, sortBy: string, sortDirection: string, projectMembers: IProjectMember[]) => {
  const aItem = findItemToSortByCriteria(a, sortBy, projectMembers);
  const bItem = findItemToSortByCriteria(b, sortBy, projectMembers);
  // if a is undefined, then a > b, so a should be on the bottom of the list
  if (!aItem) {
    return 1;
  }
  // if b is undefined, then a < b, so b should be on the bottom of the list
  if (!bItem) {
    return -1;
  }
  if (sortDirection === 'ASC') {
    return aItem < bItem ? -1 : 1;
  }
  if (sortDirection === 'DESC') {
    return aItem > bItem ? -1 : 1;
  }
  // if a and b are equal, then return 0
  return 0;
};

export default compareTodoItems;
