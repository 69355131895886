import React, { memo } from 'react';

import ScheduleContextProvider from 'src/context/ScheduleContextProvider';
import Schedules from './components/Schedule/Schedules';

function SchedulePanelPage() {
  return (
    <ScheduleContextProvider>
      <Schedules />
    </ScheduleContextProvider>
  );
}

export default memo(SchedulePanelPage);
