/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import IMessageCategoryItem from 'src/common/interfaces/IMessageCategoryItem';
// import FormDialog from 'ma'
import EditCategories from 'src/pages/components/MessageBoard/components/MessageList/components/Category/EditCategories';
import Category from './components/Category/Category';
import useMessageCategories from './hooks/useMessageCategories';
import useSelect from './hooks/useSelect';
import useStyles from './Selector.style';

interface PropsType {
  projectId: string;
  setCategoryId: any;
  companyId: string;
}

function Selector(props : PropsType) {
  const classes = useStyles();
  const [isModify, setIsModify] = useState(0);
  const { projectId, setCategoryId, companyId } = props;
  const messageCategories = useMessageCategories(companyId, projectId, isModify);
  const { select, isOpened, isSelected, value, handleSelect, handleSelected } = useSelect(setCategoryId);
  const getCategoryId = (id: any) => (event : any) => handleSelected(event, id);
  const messageCategoryOptions = messageCategories.map((e: IMessageCategoryItem) => (
    <Category
      key={e.messageCategoryId}
      messageCategory={e}
      select={select}
      getCategoryId={getCategoryId}
    />
  ));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const test = { width: '288px' };
  return (
    <div className={classes.category__select}>
      <button
        type="button"
        className={`${isOpened ? `${classes.options__expansion}` : `${classes.btn__select}`}`}
        onClick={handleSelect}
      >
        {isSelected ? value : 'Pick a category (optional)'}
      </button>
      {isOpened
          && (
            <div style={test} className={classes.options__content}>
              <button
                type="button"
                id={`${(select === 'Pick a category (optional)') ? 'options__selected' : null}`}
                className={classes.options__action}
                onClick={getCategoryId(null)}
              >
                None
              </button>
              {messageCategoryOptions}
              <button
                type="button"
                className={classes.options__action}
                onClick={handleClickOpen}
              >
                Edit categories...
              </button>

              <EditCategories
                isModify={isModify}
                setIsModify={setIsModify}
                companyId={companyId}
                projectId={projectId}
                open={open}
                handleClose={handleClose}
                messageCategoryOptions={messageCategories}
              />
            </div>
          )}
    </div>
  );
}

export default Selector;
