import {
  makeStyles,
} from '@material-ui/core/styles';
import { createStyles, withStyles, Theme } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { awPurple, white } from 'src/app/color';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  selectors_box: {
    marginBottom: '1rem',
  },
  selector: {
    width: '115px',
    height: '100%',
    boxShadow: '0 5px 5px 0 rgb(211 211 211 / 50%), 0 5px 5px 0 rgb(211 211 211 / 50%)',
    marginRight: '41px',
    fontFamily: theme.typography.fontFamily,
    padding: 0,
  },
  display_toggle_btns: {
    backgroundColor: white,
    border: 'none',
    cursor: 'pointer',
    borderRadius: '4px',
    padding: 0,
    boxShadow: '0 5px 5px 0 rgb(211 211 211 / 50%), 0 5px 5px 0 rgb(211 211 211 / 50%)',
    marginRight: '52px',
  },
  toggle_avtive: {
    backgroundColor: awPurple,
    border: 'none',
    height: '35px',
    width: '35px',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toggle_btn: {
    border: 'none',
    backgroundColor: white,
    height: '35px',
    width: '35px',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toggle_icon_default: {
    color: white,
  },
  toggle_icon_primary: {
    color: awPurple,
  },
}));

export const BootstrapInput = withStyles((theme: Theme) => createStyles({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: white,
    border: 'none',
    fontSize: 16,
    padding: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}))(InputBase);

export const MenuProps = {
  MenuListProps: {
    style: {
      padding: 0,
    },
  },
};
