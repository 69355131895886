import { makeStyles, Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { white } from 'src/app/color';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  grid_container: {
    height: 'auto',
    minHeight: '100vh',
    flexWrap: 'nowrap',
    background: 'linear-gradient(to left, #faf8fd 50%, #ffffff 50%)',
    [theme.breakpoints.down('sm')]: {
      background: white,
    },
  },
  grid_left: {
    margin: 'auto',
    height: '650px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '375px',
      padding: theme.spacing(3),
    },
    [theme.breakpoints.between('md', 'xl')]: {
      paddingLeft: '8%',
      paddingRight: '3%',
    },
  },
  img_container: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    marginLeft: '8.333333%',
  },
}));
