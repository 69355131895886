const formConfig = {
  passwordInput: {
    name: 'password',
    label: 'Password',
    placeholder: 'Enter your new password',
  },
  confirmPasswordInput: {
    name: 'confirmPassword',
    label: 'Confirm password',
    placeholder: 'Confirm your new password',
  },
  passwordFormControl: {
    fullWidth: true,
    variant: 'outlined',
    margin: 'normal',
  },
};

export default formConfig;
