import React from 'react';
import { Grid, Hidden, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import welcome from 'src/assets/svgs/welcome.svg';
import logo from '../../assets/svgs/logoWithoutTexts.svg';
import InvitationRegisterForm from './components/InvitationRegisterForm/InvitationRegisterForm';
import { useStyles } from './InvitationRegisterPage.style';
import Footer from '../Footer/Footer';

function InvitationRegisterPage() {
  const classes = useStyles();

  return (
    <Grid
      direction="row"
      container
      justifyContent="space-around"
      alignItems="center"
      className={classes.grid_container}
    >
      <Grid item md={5} className={classes.grid_left}>
        <Link to="/">
          <Box mt={2}>
            <img src={logo} alt="logo" />
          </Box>
        </Link>
        <InvitationRegisterForm />
        <Footer isPublicPage />
      </Grid>
      <Hidden smDown>
        <Grid item md={7} container className={classes.img_container}>
          <img src={welcome} alt="welcome" className={classes.img} />
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default InvitationRegisterPage;
