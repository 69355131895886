import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey, lightRed, lightBlue, salsifyGrassGreen, white } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  dialog: {
    maxWidth: '530px',
    margin: 'auto',
    position: 'relative',
  },
  emojiPop: {
    width: '66%',
    left: '20px',
    maxHeight: '200px',
    overflow: 'auto',
    borderRadius: '3px',
    position: 'absolute',
    backgroundColor: white,
    boxShadow: '3px 3px 3px grey;',
    bottom: '60px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  emojiSelect: {
    width: '24px',
    height: '28px',
    cursor: 'pointer',
    padding: '3px',
    color: 'rgb(40,50,70)',
  },
  emojiListShown: {
    width: '24px',
    height: '28px',
    cursor: 'pointer',
    padding: '3px',
    position: 'relative',
    color: 'rgb(40,50,70)',
    '&::before': {
      bottom: '22px',
      left: '48%',
      border: 'solid rgba(255,255,255,0)',
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvent: 'none',
      zIndex: 6,
      borderColor: 'rgba(255,255,255,0)',
      borderTopColor: lightBlue,
      borderWidth: '11px',
      marginLeft: '-11px',
    },
    '&::after': {
      bottom: '22px',
      left: '48%',
      border: 'solid rgba(255,255,255,0)',
      content: '""',
      height: 0,
      width: 0,
      position: 'absolute',
      pointerEvent: 'none',
      zIndex: 6,
      borderColor: 'rgba(255,255,255,0)',
      borderTopColor: white,
      borderWidth: '11px',
      marginLeft: '-11px',
    },
  },
  container: {
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 'solid #f7f7f7',

    marginBottom: 10,
    backgroundColor: lightBlue,
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  content: {
    paddingLeft: 25,
    paddingRight: 5,
    textAlign: 'left',
    marginTop: 3,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 3,
  },
  link: {
    textDecoration: 'underline',
  },
  input: {
    width: '80%',
  },
  categroies_list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  err: {
    width: 'fit-content',
    color: 'red',
    fontSize: 12,
  },
  btn: {
    borderRadius: '35px',
    textTransform: 'initial',
    marginLeft: 10,
    marginRight: 280,
    marginBottom: 10,
    marginTop: 5,
    backgroundColor: white,
    borderColor: lightRed,
    fontColor: grey,
    fontSize: '15px',
  },
  checkIcon: {
    color: salsifyGrassGreen,
  },
}));

export default useStyles;
