/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { TextField, SvgIcon, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Field } from 'formik';
import { ReactComponent as passwordIcon } from 'src/assets/svgs/passwordIcon.svg';
import { useExtraFormConf } from './hooks';
import formConfig from './config';

function PasswordField() {
  const { getStartAdornment, getErrorMsg } = useExtraFormConf();
  const { passwordInput } = formConfig;

  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <Field
        {...passwordInput}
        as={TextField}
        label="Password"
        name="password"
        placeholder="Enter your password"
        helperText={getErrorMsg('password')}
        type={showPassword ? 'text' : 'password'}
        InputProps={getStartAdornment(<SvgIcon component={passwordIcon} viewBox="0 0 32 32" />)}
      />
      <Field
        {...passwordInput}
        as={TextField}
        name="confirmPassword"
        label="Confirm password"
        placeholder="Confirm your password"
        helperText={getErrorMsg('confirmPassword')}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon component={passwordIcon} viewBox="0 0 32 32" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword
                  ? <Visibility fontSize="small" />
                  : <VisibilityOff fontSize="small" />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default React.memo(PasswordField);
