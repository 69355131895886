import { createStyles, makeStyles } from '@material-ui/core';
import { blueGrey, darkBlue, lightGrey, tomatoRed } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  err: {
    color: tomatoRed,
    fontSize: '12px',
    marginLeft: '35px',
    marginBottom: '5px',
  },
  todolist_add: {
    margin: '0px',
    height: '35px',
    marginBottom: '30px',
  },
  arrow: {
    left: '0px',
    top: '0px',
    height: '1.2em',
    width: '1em',
    color: darkBlue,
    float: 'left',
    marginRight: '10px',
  },
  add_name: {
    width: '225px',
    height: '32px',
    display: 'inline',
    textIndent: '5px',
    fontSize: '16px',
    fontWeight: 200,
    outline: '0',
    backgroundColor: lightGrey,
    border: '1px solid',
    borderColor: blueGrey,
    caretColor: blueGrey,
    borderRadius: '8px',
    '&::placeholder': {
      fontFamily: 'Poppins-Regular',
      color: blueGrey,
    },
  },
  add_icon: {
    color: darkBlue,
  },
}));

export default useStyles;
