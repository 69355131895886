import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import accessDenied from 'src/assets/svgs/accessDenied.svg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  forbiddenBackImg: {
    backgroundImage: `url(${accessDenied})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '35rem',
    width: '100%',
    marginTop: '6rem',
    marginBottom: '1rem',
    transition: '0.3s',
    [theme.breakpoints.between('xs', 'sm')]: {
      height: '22rem',
      marginTop: '3rem',
      marginBottom: '0rem',
      transition: '0.3s',
    },
    [theme.breakpoints.down('xs')]: {
      height: '17rem',
      marginTop: '0rem',
      marginBottom: '0rem',
      transition: '0.3s',
    },
  },
  logoBox: {
    paddingLeft: '2rem',
    paddingTop: '2rem',
  },
  forbiddenContainner: {
    display: 'flex',
    flexDirection: 'column',
  },
  forbInfoLine1: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    textAlign: 'center',
    fontSize: '24px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  forbInfoLine2: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  backBbuttonBox: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    textAlign: 'center',
  },
  backBbutton: {
    width: '320px',
    textTransform: 'none',
  },
}));

export default useStyles;
