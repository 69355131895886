import React, { useState } from 'react';
import useStyles from './EllipsisTextArea.style';

interface PropsType {
  content: string | null;
}
function LimitedWordTextArea(props : PropsType) {
  const { content } = props;
  const classes = useStyles();
  const [displayFullContent, setDisplayFullContent] = useState(false);
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => (setDisplayFullContent(!displayFullContent))}
        className={displayFullContent ? classes.fullContent : classes.ellipsisContent}
      >
        {content}
      </div>
    </>
  );
}
export default LimitedWordTextArea;
