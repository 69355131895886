/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useRef, useState, KeyboardEvent, useMemo } from 'react';
import { useAuth } from 'src/common/customHooks';
import { TodoContext } from 'src/context/TodoContext';
import { createTodoItem, changeNewTodoItemCompletedStatus, updateTodoItem } from 'src/utils/apiUtils';
import ITodoItem from 'src/common/interfaces/Dto/Todo/TodoItem/ITodoItem';
import { Grid, Toolbar, TextField, Fab, Avatar, ClickAwayListener } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PrioritySelector from 'src/components/PrioritySelector/PrioritySelector';
import { stringAvatar, stringToColor } from 'src/common/functions/toDoPageHeaderFunction';
import { AvatarGroup } from '@material-ui/lab';
import IProjectMember from 'src/common/interfaces/IProjectMember';
import { StatusCodes } from 'http-status-codes';
import AssigneeMenu from 'src/components/AssigneeMenu/AssigneeMenu';
import useStyles from './TodoItem.style';

function TodoItem({ todoItem }: { todoItem: ITodoItem }) {
  const classes = useStyles();

  const [refresh, setFresh] = useState(false);

  const { setAddingTodoItem, setUpdate, projectMembers, todoLists, setTodoLists, projectInfo } = useContext(TodoContext);
  const { doneListId } = projectInfo;

  const {
    description,
    priority,
    subscribersIds,
    dueDate,
    todoItemId,
    companyId,
    projectId,
    todoListId,
    completed,
  } = todoItem;

  const createdUserId = localStorage.getItem('userId') as string;

  const [newDate, setNewDate] = useState(dueDate);
  const [onMouseOver, setOnMouseOver] = useState(false);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState(priority);
  const [isPriorityOpen, setIsPriorityOpen] = useState(false);
  const [isAssigneesOpen, setIsAssigneesOpen] = useState(false);
  const [assignees, setAssignees] = useState<IProjectMember[]>([]);

  useEffect(() => {
    if (subscribersIds && projectMembers) {
      let assigneesData = [] as IProjectMember[];
      subscribersIds.split(',').forEach((id) => {
        const assigneeData = projectMembers.filter((member) => member.id.toString() === id.toString());
        assigneesData = assigneesData.concat(assigneeData);
      });
      setAssignees(assigneesData);
    }
  }, [projectMembers, subscribersIds]);

  const assigneesAvatars = useMemo(
    () => assignees.map((assignee) => (
      <Avatar key={assignee.id} style={{ backgroundColor: stringToColor(assignee.name) }}>
        {stringAvatar(assignee.name)}
      </Avatar>
    )),
    [assignees],
  );

  const inputRef = useRef<HTMLInputElement>();

  const { isCompanyManager, isProjectMember } = useAuth();
  const disabled = !isCompanyManager && !isProjectMember;

  useEffect(() => {
    if (todoItemId === 'new') {
      inputRef.current?.focus();
    }
  }, []);

  const setNewTodoItem = async (date = newDate, newPriority = selectedPriority, newAssigneesIds = subscribersIds) => {
    setAddingTodoItem(false);
    const notes = '';
    const originNotes = '';
    const newDescription = inputRef.current?.value.trim();
    const newTodoItem = {
      companyId,
      projectId,
      notes,
      originNotes,
      description: newDescription,
      priority: newPriority,
      dueDate: date,
      subscribersIds: newAssigneesIds,
    };

    if (todoItemId === 'new' && newDescription === '') {
      setUpdate((prevState) => prevState + 1);
    }
    if (todoItemId === 'new' && newDescription !== '') {
      try {
        const { status } = await createTodoItem({ ...newTodoItem, todoListId, createdUserId });
        if (status === StatusCodes.OK) {
          setUpdate((prevState) => prevState + 1);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (todoItemId !== 'new') {
      try {
        const { status } = await updateTodoItem({ ...newTodoItem, todoItemId });
        if (status !== StatusCodes.OK) {
          setFresh(!refresh);
        }
        if (status === StatusCodes.OK) {
          setUpdate((prevState) => prevState + 1);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleInputBlur = () => {
    if (onMouseOver) {
      return;
    }
    setNewTodoItem();
  };

  const handleBlur = () => {
    if (onMouseOver) {
      if (!inputRef.current?.value) {
        inputRef.current?.focus();
      }
      return;
    }
    setNewTodoItem();
  };

  const handleDescriptionForEnterKey = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setNewTodoItem();
    }
  };

  const handleCompletedStatusChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const completedStatus = event.target.checked;
    try {
      const { status } = await changeNewTodoItemCompletedStatus({ companyId, projectId, todoItemId, completedStatus });
      if (status === StatusCodes.OK) {
        todoLists.forEach(({ todoItems }, index) => {
          if (todoLists[index].id === doneListId && !todoLists[index].todoItems.includes(todoItem)) {
            const newTodoItem = { ...todoItem, completed: true };
            todoLists[index].todoItems = [newTodoItem, ...todoItems];
            const newTodoLists = [...todoLists];
            setTodoLists(newTodoLists);
          }
          if (todoLists[index].id !== doneListId && todoLists[index].todoItems.includes(todoItem)) {
            todoLists[index].todoItems = todoLists[index].todoItems.filter((item) => item !== todoItem);
            const newTodoLists = [...todoLists];
            setTodoLists(newTodoLists);
          }
        });
      } else {
        setFresh(!refresh);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelect = (newPriority: string) => {
    setSelectedPriority(newPriority);
    setIsPriorityOpen(!isPriorityOpen);
    setNewTodoItem(undefined, newPriority);
  };

  const handleDateChange = (nDate: Date | [Date | null, Date | null] | null) => {
    const date = nDate?.toString() as string;
    const transDate = new Date(date).toISOString();
    setNewDate(transDate);
    setNewTodoItem(transDate);
  };

  const openDatePicker = () => {
    setDatePickerIsOpen(!datePickerIsOpen);
  };

  const handleClickAssignees = () => {
    setIsAssigneesOpen(!isAssigneesOpen);
  };

  const handleClickAwayAssignees = () => {
    setIsAssigneesOpen(false);
  };

  return (
    <Toolbar
      variant="dense"
      className={classes.row}
      onMouseOver={() => setOnMouseOver(true)}
      onMouseOut={() => setOnMouseOver(false)}
      data-testid="todo-item"
    >
      <Grid item xs={5} className={classes.task}>
        <Checkbox
          disabled={disabled}
          defaultChecked={completed}
          icon={<CircleCheckedFilled className={classes.checkButton} />}
          checkedIcon={<CircleCheckedFilled className={classes.checkedButton} />}
          className={classes.checkbox}
          onChange={handleCompletedStatusChange}
          data-testid="checkbox"
        />
        <TextField
          disabled={disabled}
          inputRef={inputRef}
          id="fullWidth"
          defaultValue={description}
          className={classes.textFiled}
          placeholder="Write a task name"
          onBlur={handleInputBlur}
          onKeyUp={handleDescriptionForEnterKey}
          InputProps={{ disableUnderline: true, 'aria-label': 'task description' }}
        />
      </Grid>
      <Grid item xs={3}>
        <div className={classes.priority_selector} onBlur={handleBlur}>
          <PrioritySelector
            handleSelect={handleSelect}
            select={selectedPriority}
            isOpen={isPriorityOpen}
            setIsOpen={setIsPriorityOpen}
            disabled={disabled}
          />
        </div>
      </Grid>
      <Grid item xs={3} className={classes.assigneesWrapper}>
        <ClickAwayListener onClickAway={handleClickAwayAssignees}>
          <div className={classes.assignees}>
            {subscribersIds && assigneesAvatars ? (
              <button type="button" onClick={handleClickAssignees} className={classes.assigneesBtn}>
                <AvatarGroup max={3} classes={{ avatar: classes.avatar }}>
                  {assigneesAvatars}
                </AvatarGroup>
              </button>
            ) : (
              <Fab aria-label="addAssignees" className={classes.fab} onClick={handleClickAssignees} onBlur={handleBlur}>
                <AddIcon className={classes.add} />
              </Fab>
            )}
            {isAssigneesOpen && (
              <AssigneeMenu
                projectMembers={projectMembers}
                assignees={assignees}
                assigneesIds={subscribersIds}
                setNewTodoItem={setNewTodoItem}
                setIsAssigneesOpen={setIsAssigneesOpen}
              />
            )}
          </div>
        </ClickAwayListener>
      </Grid>
      <Grid item xs={3}>
        {newDate === null || isNaN(new Date(newDate).getTime()) ? (
          <div data-testid="no-dueDate">
            <DatePicker
              wrapperClassName={classes.no_date_calendar_wrapper}
              className={classes.no_date_calendar}
              onChange={(nDate) => {
                openDatePicker();
                handleDateChange(nDate);
              }}
              minDate={new Date()}
              onClickOutside={openDatePicker}
              open={datePickerIsOpen}
            />
            <div className={classes.no_date_div}>
              <Fab
                aria-label="add"
                className={classes.fab}
                disabled={disabled}
                onClick={openDatePicker}
                onBlur={handleBlur}
                data-testid="date-icon"
              >
                <CalendarTodayIcon className={classes.date_picker} />
              </Fab>
            </div>
          </div>
        ) : (
          <div data-testid="date-picker">
            <DatePicker
              selected={new Date(newDate)}
              disabled={disabled}
              className={classes.calendar}
              popperClassName={classes.react_datepicker_picker}
              minDate={new Date()}
              onChange={(nDate) => {
                handleDateChange(nDate);
              }}
              dateFormat="d MMM yyyy "
            />
          </div>
        )}
      </Grid>
    </Toolbar>
  );
}

export default TodoItem;
