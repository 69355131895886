/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/aria-props */
import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ICompanyMember from 'src/common/interfaces/ICompanyMember';
import UserProfileIcon from 'src/components/UserProfileIcon/UserProfileIcon';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './SelectedCompanyMembers.style';

interface PropsType {
  selectedMember:ICompanyMember;
  projectOwnerId: string | undefined;
  setSelectedMemberList: Function;
  selectedMemberList:ICompanyMember[];
  addAllSelectedMemberId: string[];
  setAddAllSelectedMemberId: Function;
}

function SelectedCompanyMembers(props: PropsType) {
  const classes = useStyles();
  const { selectedMember, projectOwnerId, setSelectedMemberList, selectedMemberList, addAllSelectedMemberId, setAddAllSelectedMemberId } = props;

  const deleteSelectedMembers = () => {
    setSelectedMemberList(
      selectedMemberList.filter(
        (e: ICompanyMember) => e.id.toString() !== selectedMember.id.toString(),
      ),
    );
    setAddAllSelectedMemberId(
      addAllSelectedMemberId.filter(
        (id:string) => id !== selectedMember.id.toString(),
      ),
    );
  };

  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  };
  const offHover = () => {
    setHover(false);
  };

  return (
    <div
      className={classes.selected_members}
      onMouseOver={onHover}
      onMouseLeave={offHover}
    >
      <div className={classes.member_icon}>
        <UserProfileIcon
          name={selectedMember.name}
          size="35"
          textSizeRatio={1.5}
        />
      </div>
      <div className={classes.selected_members_info}>
        <Typography className={classes.selected_members_name}>
          {selectedMember.name}
        </Typography>

        <Typography className={classes.selected_members_email}>
          {selectedMember.email}
        </Typography>
      </div>
      <div className={classes.selected_tail}>
        {projectOwnerId?.toString() === selectedMember.id.toString()
          ? (
            <Typography className={classes.owner_label}>
              Owner
            </Typography>
          )
          : (
            <div>
              {hover ? (
                <button
                  className={classes.delete_btn}
                  type="button"
                  aria-label="close-popup-button"
                  onClick={deleteSelectedMembers}
                >
                  <CloseIcon
                    className={classes.delete_btn_icon}
                    fontSize="small"
                  />
                </button>
              ) : null}
            </div>
          )}
      </div>
    </div>
  );
}

export default SelectedCompanyMembers;
