import React, { useContext, useRef, memo } from 'react';
import { Card, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { CompanyContext } from 'src/context/CompanyContext';
import { FormikProps } from 'formik';
import IEditCompanyInfo from 'src/common/interfaces/IEditCompanyInfo';
import EditCompanyInfoForm from './components/EditCompanyInfoForm/EditCompanyInfoForm';
import useStyles from './EditCompanyInfoPopupWindows.style';

function EditCompanyInfoPopupWindows() {
  const classes = useStyles();
  const { isEditPanelOpen, setEditPanelOpen } = useContext(CompanyContext);
  const formikRef = useRef<FormikProps<IEditCompanyInfo>>(null);
  return (
    <div className={isEditPanelOpen ? classes.popup_container_display : classes.content_hidden}>
      <Card className={classes.popup}>
        <div className={classes.top_card}>
          <Typography className={classes.top_card_content}>Edit the company information</Typography>
          <CloseIcon
            className={classes.top_card_close}
            data-testid="CloseIcon"
            onClick={() => {
              setEditPanelOpen(false);
            }}
          />
        </div>
        <hr />
        <EditCompanyInfoForm formikRef={formikRef} />
      </Card>
    </div>
  );
}

export default memo(EditCompanyInfoPopupWindows);
