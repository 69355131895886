import React from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import './FirstLoginErrorMessage.scss';

interface WarningProps {
  content: string
}

function FirstLoginErrorMessage({ content }: WarningProps) {
  return (
    <Box className="firstLoginWarning">
      <Typography className="firstLoginWarningContent">{content}</Typography>
    </Box>
  );
}

export default FirstLoginErrorMessage;
