import { createStyles, makeStyles } from '@material-ui/core/styles';
import { lightSkyBlue, lightBlue, white } from 'src/app/color';
import checkmark from 'src/assets/svgs/checkmark.svg';
import select from '../../assets/svgs/select.svg';

export default makeStyles(() => createStyles({
  category__select: {
    boxSizing: 'border-box',
    position: 'relative',
  },
  btn__select: {
    padding: '0.425rem 2rem 0.425rem 1rem',
    border: '1px solid #283c46',
    borderRadius: '1.5rem',
    backgroundColor: white,
    background: 'no-repeat right 0.8rem center',
    backgroundImage: `url(${select})`,
    lineHeight: 1.5,
    color: 'rgb(40,50,70)',
    fontSize: '0.8rem',
    '&:focus': {
      outline: 'none',
      borderColor: lightSkyBlue,
      boxShadow: '0 0 6px 0 rgba(27,106,201,0.5)',
    },
  },
  options__expansion: {
    position: 'relative',
    padding: '0.425rem 2rem 0.425rem 1rem',
    border: '1px solid #283c46',
    borderRadius: '1.5rem',
    backgroundColor: white,
    background: 'no-repeat right 0.8rem center',
    backgroundImage: `url(${select})`,
    lineHeight: 1.5,
    color: 'rgb(40,50,70)',
    fontSize: '0.8rem',
    '&:focus': {
      outline: 'none',
      borderColor: lightSkyBlue,
      boxShadow: '0 0 6px 0 rgba(27,106,201,0.5)',
    },
  },
  options__content: {
    width: '18rem',
    borderRadius: '4px',
    border: '1px sold rgba(0,0,0,0.1)',
    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.1), 0px 5px 20px 0px rgba(0,0,0,0.05)',
    backgroundColor: white,
    position: 'absolute',
    zIndex: 5,
    right: 'auto',
    '& #options__selected': {
      fontWeight: 'bold',
      backgroundImage: `url(${checkmark})`,
      backgroundSize: '1.2rem',
    },
  },
  options__action: {
    border: 0,
    background: 'none',
    textAlign: 'left',
    width: '100%',
    display: 'block',
    lineHeight: 1.2,
    padding: '0.7rem 1.2rem 0.7rem 2.8rem',
    backgroundPosition: '1rem 50%',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: lightBlue,
    },
  },
}));
