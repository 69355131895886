import React from 'react';
import { Typography } from '@material-ui/core';
import crossIcon from 'src/assets/svgs/crossIcon.svg';
import useStyles from './WarningMessage.style';

interface IWarningMessageProps {
  content: string;
}

function WarningMessage({ content }: IWarningMessageProps) {
  const classes = useStyles();
  return (
    <div className={classes.warning}>
      <img src={crossIcon} alt="crossIcon" />
      <Typography className={classes.warning_message}>
        {content}
      </Typography>
    </div>
  );
}

export default WarningMessage;
