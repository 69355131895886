import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { darkGrey, tomatoRed } from 'src/app/color';

export default makeStyles((theme: Theme) => createStyles({
  title: {
    fontFamily: 'Poppins-Bold',
    fontWeight: 'bold',
    paddingTop: '23px',
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '1rem',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '1.25rem',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(4),
    },
  },
  button: {
    marginTop: theme.spacing(2),
    textTransform: 'initial',
  },
  account: {
    fontFamily: 'Poppins-Medium',
    fontSize: '0.875rem',
    textAlign: 'left',
    color: darkGrey,
    fontWeight: 550,
    paddingTop: '20px',
  },
  btn_signin: {
    paddingLeft: '10px',
  },
  error: {
    display: 'block',
  },
  img: {
    display: 'inline',
    float: 'left',
  },
  errormessage: {
    fontFamily: 'Poppins-SemiBold',
    color: tomatoRed,
    fontWeight: 600,
    marginLeft: '8px',
    display: 'inline',
  },
  password: {
    marginTop: '19px',
  },
}));
