import { makeStyles } from '@material-ui/core/styles';
import { blueGrey, darkGrey, lightBlue, deepDarkBlue } from 'src/app/color';

const useStyles = makeStyles(() => ({
  selected_members: {
    display: 'flex',
    alignItem: 'center',
    padding: '12px 0',
    '&:hover': {
      backgroundColor: lightBlue,
      visibility: 'visible',
    },
  },
  member_icon: {
    marginLeft: '35px',
    marginTop: '-3px',
  },
  selected_members_info: {
    marginLeft: '25px',
  },

  selected_members_name: {
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontSize: '14px',
    fontFamily: 'Poppins-Regular',
    lineHeight: 1,
    color: deepDarkBlue,
    paddingBottom: '3px',
  },

  selected_members_email: {
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontSize: '12px',
    fontFamily: 'Poppins-Regular',
    lineHeight: 1.33,
    color: darkGrey,
  },
  selected_tail: {
    flex: 1,
    textAlign: 'end',
    marginRight: '35px',
  },

  delete_btn: {
    background: 'none',
    textDecoration: 'none',
    border: 'none',
    cursor: 'pointer',

  },
  delete_btn_icon: {
    paddingTop: '3px',
    color: blueGrey,
  },
  owner_label: {
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontSize: '13px',
    fontFamily: 'Poppins-Regular',
    lineHeight: 1.69,
    color: darkGrey,
  },

}));

export default useStyles;
