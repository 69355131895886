import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { lightSkyBlue, orange, redPink, white } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  option: {
    width: '58px',
    height: '19px',
    padding: 0,
    flexGrow: 0,
    borderRadius: '70px',
    border: 'transparent',
    fontSize: '10px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '19px',
    letterSpacing: '-0.2px',
    textAlign: 'center',
    cursor: 'pointer',
    color: white,
    fontFamily: 'Poppins-medium',
    '&#Low': {
      backgroundColor: lightSkyBlue,
    },
    '&#Medium': {
      backgroundColor: orange,
    },
    '&#High': {
      backgroundColor: redPink,
    },
  },
  option_disabled: {
    width: '58px',
    height: '19px',
    padding: 0,
    flexGrow: 0,
    borderRadius: '70px',
    border: 'transparent',
    fontSize: '10px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '19px',
    letterSpacing: '-0.2px',
    textAlign: 'center',
    color: white,
    fontFamily: 'Poppins-medium',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    cursor: 'default',
  },
}));

export default useStyles;
