import React, { useState } from 'react';
import { Button, Grid, SvgIcon, Typography, IconButton } from '@material-ui/core';
import { Formik, Form, FormikConfig, FormikValues, FormikHelpers } from 'formik';
import { backButton, nextButton } from 'src/assets/buttons';
import { Link } from 'react-router-dom';
import useStyles from '../SignupForm.style';

const useWizard = () => {
  function Wizard({ children, initialValues }: FormikConfig<FormikValues>) {
    const classes = useStyles();
    const [stepNumber, setStepNumber] = useState(0);
    const steps = React.Children.toArray(children) as React.ReactElement<FormikConfig<FormikValues>>[];
    const [snapshot, setSnapshot] = useState(initialValues);
    const step = steps[stepNumber];
    const totalSteps = steps.length;
    const isSubmitStep = stepNumber === totalSteps - 1;
    const next = (values: FormikValues) => {
      setSnapshot(values);
      setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    };

    const previous = (values: FormikValues) => {
      setSnapshot(values);
      setStepNumber(Math.max(stepNumber - 1, 0));
    };

    const handleSubmit = (values: FormikValues, bag: FormikHelpers<FormikValues>) => {
      if (step.props.onSubmit) {
        step.props.onSubmit(values, bag);
      } else {
        setStepNumber((s) => s + 1);
      }
      bag.setTouched({});
      next(values);
    };
    return (
      <Formik initialValues={snapshot} onSubmit={handleSubmit} validationSchema={step.props.validationSchema}>
        {(values) => (
          <Form>
            {step}
            {isSubmitStep ? (
              <Grid
                container
                justifyContent="space-between"
                alignItems="baseline"
                className={classes.buttonContainer}
              >
                <Grid item>
                  <IconButton
                    onClick={() => previous(values)}
                    type="button"
                    className={classes.back_button}
                  >
                    <SvgIcon
                      component={backButton}
                      viewBox="0 0 48 48"
                      fontSize="large"
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    name="Create Account"
                    className={classes.signup_button}
                    data-testid="createAccount"
                  >
                    Create account
                  </Button>
                </Grid>
              </Grid>
            )
              : (
                <Grid container justifyContent="space-between" alignItems="baseline" className={classes.buttonContainer}>
                  <Grid container item direction="row" justifyContent="flex-start" alignItems="baseline" spacing={2}>
                    <Grid item>
                      <Typography className={classes.account}>
                        Already have an account?
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        <Link className={classes.login_link} to="/login">
                          Log in
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <IconButton
                      type="submit"
                      className={classes.next_button}
                      data-testid="nextButton"
                    >
                      <SvgIcon
                        component={nextButton}
                        viewBox="0 0 48 48"
                        fontSize="large"
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
          </Form>
        )}
      </Formik>
    );
  }
  return { Wizard };
};

export default useWizard;
