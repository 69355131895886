import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  userIcon: {
    float: 'right',
  },
  menu: {
    zIndex: 9999,
  },
}));

export default useStyles;
