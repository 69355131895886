import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import TemplateIcon from 'src/assets/svgs/templateIcon.svg';
import addProjectIcon from 'src/assets/svgs/addProjectIcon.svg';
import { Typography } from '@material-ui/core';
import useStyles from './CreateNewProjectPopup.style';

interface CreateNewProjectPopupProps {
  showBlankProject: boolean;
  togglePopup: any;
  toggleShowBlankProject: any;
}

function CreateNewProjectPopup({ togglePopup, showBlankProject, toggleShowBlankProject }: CreateNewProjectPopupProps) {
  const classes = useStyles();
  return (
    <div className={showBlankProject ? classes.content_hidden : ''}>
      <div className={classes.popup_header}>
        <button
          type="button"
          aria-label="close-popup-button"
          className={classes.close_btn}
          onClick={togglePopup}
        >
          <CloseIcon className={classes.close_icon} fontSize="large" />
        </button>
        <Typography className={classes.popup_title}>
          Create a new project
        </Typography>
        <Typography className={classes.popup_subtitle}>
          How would you like to start?
        </Typography>
      </div>

      <div className={classes.popup_content}>
        <div className={classes.popup_content_section}>
          <button
            type="button"
            className={classes.popup_content_button_left}
            onClick={toggleShowBlankProject}
            aria-label="add-blank-project-button"
          >
            <img src={addProjectIcon} className={classes.blank_project_icon} alt="add_project_icon" />
          </button>
          <Typography className={classes.content_label}>
            Blank Project
          </Typography>
          <Typography className={classes.content_description}>
            Start from Scratch
          </Typography>
        </div>

        <div className={classes.popup_content_section}>
          <button type="button" className={classes.popup_content_button_right}>
            <img src={TemplateIcon} className={classes.template_icon} alt="add_project_icon" />
          </button>
          <Typography className={classes.content_label}>
            Use a template
          </Typography>
          <Typography className={classes.content_description}>
            Choose from library
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default CreateNewProjectPopup;
