import React from 'react';
import Header from 'src/components/Header/Header';
import { usePathVariable, useFetch } from 'src/common/customHooks';
import { getProjectList } from 'src/utils/apiUtils';

interface IProject {
  id: string;
  name: string
}

function InnerProjectHeader() {
  const { companyId, projectId } = usePathVariable();
  const userId = localStorage.getItem('userId');
  const { data: projectItemList } = useFetch(getProjectList, [companyId, userId], [] as IProject[]);
  const otherProjects = projectItemList.filter((e) => e.id.toString() !== projectId);
  const thisProject = projectItemList.find((e) => e.id.toString() === projectId);

  return (
    <Header
      title={thisProject?.name}
      isInnerProjectPage
      isCompanyPage={false}
      otherProjects={otherProjects}
    />
  );
}
export default InnerProjectHeader;
