/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import useStyles from './EmojiList.style';

interface PropsType {
  handleClosePop: Function;
  setEmoji: Function;
}
function EmojiList({ handleClosePop, setEmoji }: PropsType) {
  const emojiList = [
    '👏', '👍', '🙌', '💪', '🤘', '✊', '✨', '❤️', '💯', '🎉',
    '🎊', '🥳', '😊', '😀', '😂', '😅', '😎', '😉', '😜', '😬',
    '😮', '😳', '🤔', '😒', '😢', '😭', '😱', '👀', '🙏', '💩',
    '👎', '✌️', '👈', '👆', '✋', '👋', '☀️', '🌙', '💥', '🔥',
    '🎂', '🍴', '💰', '🥇', '🚨', '💡', '🔧', '📈', '✅', '📢',
  ];
  const styles = useStyles();
  return (
    <div
      className={styles.continer}
      data-testid="emojiListId"
    >
      {emojiList.map((item) => (
        <div
          key={item}
          data-testid={item}
          className={styles.emojiIcon}
          onClick={() => {
            handleClosePop();
            setEmoji(item);
          }}
        >
          {item}
        </div>
      ))}
    </div>
  );
}

export default EmojiList;
