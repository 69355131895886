/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    mainPaper: {
      marginTop: 30,
      marginLeft: 40,
      marginRight: 40,
    },
  }),
);

export default useStyles;
