import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  change_password: {
    fontWeight: 600,
    fontSize: '0.9rem',
    marginBottom: '8%',
  },
  img: {
    display: 'block',
    width: '100%',
    height: 300,
  },
  dialog_content: {
    marginTop: '5%',
    marginBottom: '15%',
    marginLeft: '12%',
    marginRight: '12%',
  },
}));
