import React from 'react';
import Header from 'src/components/Header/Header';

function ProjectsPageHeader() {
  return (
    <Header title="Project" isCompanyPage={false} isInnerProjectPage={false} otherProjects={[]} />
  );
}

export default ProjectsPageHeader;
