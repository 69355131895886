import axios from 'axios';
import baseUrl from './apiUrls';

// Start PostProjectsMembersInfo//
export const addProjectsMembers = (companyId: string, projectId: string, userIds: string) => axios({
  method: 'post',
  url: `${baseUrl}/companies/${companyId}/projects/${projectId}/members`,
  params: {
    userIds,
  },
});
// End PostProjectsMembersInfo//

// Start ProjectsEmployeesInvitations//
export const getAvailableEmployees = (companyId: string, projectId: string) => axios({
  method: 'get',
  url: `${baseUrl}/companies/${companyId}/available-employees`,
  params: {
    projectId,
  },
});
// End ProjectsEmployeesInvitations//
