import React, { useContext, useState } from 'react';
import { useAuth, usePathVariable } from 'src/common/customHooks';
import { TodoContext } from 'src/context/TodoContext';
import { Grid, Toolbar, Button } from '@material-ui/core';
import Loading from 'src/components/Loading/Loading';
import { ReactComponent as SortAsc } from 'src/assets/svgs/sort-asc.svg';
import { ReactComponent as SortDesc } from 'src/assets/svgs/sort-desc.svg';
import { ReactComponent as Sort } from 'src/assets/svgs/sort.svg';
import TodoSetHeader from '../TodoSetHeader/TodoSetHeader';
import TodoListForm from '../TodoListForm/TodoListForm';
import TodoList from '../TodoList/TodoList';
import useStyles from './TodoSet.style';

const findSortIcon = (sortItem: string, sortBy: string, sortDirection: string) => {
  if (sortItem === sortBy) {
    return sortDirection === 'ASC' ? (
      <SortAsc data-testid="sort-asc-icon" />
    ) : (
      <SortDesc data-testid="sort-desc-icon" />
    );
  }
  return <Sort data-testid="sort-icon" />;
};

function TodoSet() {
  const classes = useStyles();
  const [isAdded, setIsAdded] = useState(false);
  const { todoLists, loading } = useContext(TodoContext);
  const { projectId, companyId } = usePathVariable();
  const { isProjectMember, isCompanyManager } = useAuth();

  const [sort, setSort] = useState<{ [name: string]: string }>({ by: '', direction: 'ASC' });
  const sortItems = ['Priority', 'Assignees', 'Due date'];

  const handleSort = (sortby: string) => {
    if (sort.by === sortby) {
      setSort({ by: sortby, direction: sort.direction === 'ASC' ? 'DESC' : 'ASC' });
    } else {
      setSort({ by: sortby, direction: 'ASC' });
    }
  };

  return (
    <>
      <Toolbar variant="dense" className={classes.rowHeader}>
        <Grid item xs={5} className={classes.taskName}>
          Task name
        </Grid>
        {sortItems.map((item) => (
          <Grid item key={item} xs={3}>
            <Button
              endIcon={findSortIcon(item, sort.by, sort.direction)}
              className={classes.button}
              onClick={() => handleSort(item)}
            >
              {item}
            </Button>
          </Grid>
        ))}
      </Toolbar>
      <div className={loading ? classes.emptyContent : ''}>
        {loading ? (
          <Loading />
        ) : (
          todoLists.map((todoList) => <TodoList key={todoList.id} todoList={todoList} sort={sort} />)
        )}
      </div>
      {isAdded && (isProjectMember || isCompanyManager) && (
        <TodoListForm companyId={companyId} projectId={projectId} setIsAdded={setIsAdded} />
      )}
      <TodoSetHeader setAdd={setIsAdded} isProjectMember={isProjectMember} isCompanyManager={isCompanyManager} />
    </>
  );
}

export default TodoSet;
