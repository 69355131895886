import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { deepDarkBlue, lightBlue, white } from 'src/app/color';

const useStyles = makeStyles(() => createStyles({
  optionBtn: {
    background: white,
    width: '100%',
    padding: '12px 28px',
    textDecoration: 'none',
    border: 'none',
    display: 'flex',
    cursor: 'pointer',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: lightBlue,
    },
  },

  members_name: {
    marginLeft: '15px',
    marginTop: '3px',
    fontFamily: 'Poppins-Regular',
    fontSize: '14px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    color: deepDarkBlue,
  },

}));
export default useStyles;
