import { makeStyles } from '@material-ui/core';
import { awPurple } from 'src/app/color';

interface PropsType {
  numberOfContributions: number,
  percentage: number,
}

const useStyles = makeStyles(() => ({
  dotline: (props: PropsType) => ({
    width: '577.4px',
    height: `${(1 - props.percentage) * 112}px`,
    borderBottom: 'dashed 1px #7d589f',
    position: 'absolute',
    top: '-1px',
    left: 0,
    zIndex: -99,
  }),
  numberOfTasks: (props: PropsType) => ({
    fontFamily: 'Poppins-medium',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 1.67,
    color: awPurple,
    position: 'absolute',
    top: `${(1 - props.percentage) * 112 - 10}px`,
    right: '-60px',
  }),
}));

export default useStyles;
