import {
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import arrow from 'src/assets/svgs/arrow.svg';
import illustration from 'src/assets/svgs/illustration.svg';
import { createStyles } from '@material-ui/core';
import { blueGrey, darkBlue, white } from 'src/app/color';

const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    paddingTop: '74px',
    paddingLeft: '54.5px',
  },
  emptyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
  },
  content_container: {
    position: 'relative',
  },
  add_project_btn_container: {
    width: '255px',
    padding: '1rem',
    position: 'absolute',
    height: '290px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  add_project_btn: {
    background: 'none',
    textDecoration: 'none',
    border: 'none',
    cursor: 'pointer',
    margin: 0,
    padding: 0,
  },
  add_project_icon: {
    width: '100%',
  },
  add_project_btn_place: {
    width: '255px',
    display: 'inline-block',
    padding: '1rem',
    height: '230px',
  },
  popup_container_display: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    zIndex: 10,
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(196, 196, 196, 0.26)',
  },
  popup_container_hidden: {
    display: 'none',
  },
  popup: {
    width: '35rem',
    maxWidth: '90vw',
    background: white,
    boxShadow: '0 5px 20px rgb(0 0 0 / 35%)',
    maxHeight: '90vh',
    borderRadius: '0.8rem',
    padding: '2rem',
  },

  close_icon: {
    color: darkBlue,
  },
  popup_header: {
    padding: '4rem 0',
    position: 'relative',
  },
  close_btn: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: 'none',
    textDecoration: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  popup_title: {
    color: darkBlue,
    display: 'block',
    textAlign: 'center',
    fontSize: '36px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 0.78,
    letterSpacing: '-0.72px',
  },
  popup_subtitle: {
    color: darkBlue,
    display: 'block',
    textAlign: 'center',
    marginTop: '1rem',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.56,
    letterSpacing: '-0.36px',
  },
  popup_content: {
    display: 'flex',
    marginBottom: '4rem',
  },
  popup_content_button_left: {
    background: 'none',
    textDecoration: 'none',
    border: 'none',
    cursor: 'pointer',
    left: 'auto',
    right: 'auto',
    height: '164px',
    width: '176px',
  },
  popup_content_button_right: {
    background: 'none',
    textDecoration: 'none',
    border: 'solid 2px #a3aed0',
    borderRadius: '20px',
    cursor: 'pointer',
    left: 'auto',
    right: 'auto',
    height: '164px',
    width: '176px',
  },
  blank_project_icon: {
    height: '164px',
    width: '176px',
  },
  template_icon: {
  },
  popup_content_section: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content_label: {
    marginTop: '1rem',
    color: darkBlue,
    fontSize: '18px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.56,
    letterSpacing: '-0.36px',
  },
  content_description: {
    color: blueGrey,
    fontSize: '14px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: '-0.28px',
  },
  empty_container: {
    width: '100%',
    position: 'relative',
  },
  empty_arrow: {
    position: 'absolute',
    top: '60px',
    left: '250px',
    width: '272px',
    height: '95px',
    backgroundImage: `url(${arrow})`,
    backgroundRepeat: 'no-repeat',
    transition: '0.3s',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '100% 100%',
      transform: 'rotate(60deg)',
      width: '130px',
      height: '45px',
      top: '250px',
      left: '50px',
      transition: '0.3s',
    },
  },
  empty_img: {
    position: 'absolute',
    top: '170px',
    left: '550px',
    width: '70px',
    height: '96px',
    backgroundImage: `url(${illustration})`,
    backgroundRepeat: 'no-repeat',
    transition: '0.3s',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '100% 100%',
      width: '60px',
      height: '90px',
      top: '350px',
      left: '100px',
      transition: '0.3s',
    },
  },
  empty_content: {
    position: 'absolute',
    top: '190px',
    left: '650px',
    width: '220px',
    fontFamily: 'Poppins-Regular',
    textAlign: 'left',
    fontSize: '18px',
    transition: '0.3s',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '100% 100%',
      width: '220px',
      height: '90px',
      top: '450px',
      left: '40px',
      transition: '0.3s',
    },
  },
}));

export default useStyles;
