import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import CompanyProfile from './components/CompanyProfile/CompanyProfile';
import './EditCompany.scss';

function EditCompany() {
  const history = useHistory();
  const [name, setName] = useState('');
  const { companyId } = useParams<{ companyId: string }>();
  const goBack = () => {
    history.push(`/companies/${companyId}/companypanel`);
  };

  const getCompName = (compName:string) => {
    setName(compName);
  };

  return (
    <>
      <Paper
        className="goBack"
        elevation={3}
        onClick={() => {
          goBack();
        }}
      >
        <div className="icon">
          <div className="square">
            <div />
            <div />
            <div />
            <div />
          </div>
          <div className="name">
            {name}
          </div>
        </div>
      </Paper>
      <Paper className="companyPanel" elevation={3}>
        <CompanyProfile getCompName={getCompName} companyId={companyId} />
      </Paper>
    </>
  );
}
export default EditCompany;
