import axios from 'axios';

const baseUrl = process.env.REACT_APP_ASYNC_WORK_API;

// Start getNewJwt
export const getNewJwt = () => axios({
  method: 'get',
  url: `${baseUrl}/refresh`,
});

export default getNewJwt;
// End getNewJwt
