import { makeStyles, createStyles } from '@material-ui/core';
import { grey } from 'src/app/color';

const footerStyles = makeStyles(() => createStyles({
  root: {
    position: 'relative',
    bottom: 0,
  },
  text: {
    color: grey,
    fontSize: '12px',
    textAlign: 'center',
    margin: '10px',
  },
  line: {
    marginTop: '10%',
  },
}));

export default footerStyles;
