import { Avatar } from '@material-ui/core';
import React from 'react';
import { stringAvatar, stringToColor } from 'src/common/functions/toDoPageHeaderFunction';
import useStyles from './ProjectMember.style';

interface ProjectMemberProps {
  id: string;
  name: string;
  email: string;
  onClick: Function;
}

function ProjectMember({ id, name, email, onClick }: ProjectMemberProps) {
  const classes = useStyles();

  return (
    <button type="button" className={classes.projectMember} onClick={() => onClick(id)}>
      <Avatar
        style={{ backgroundColor: stringToColor(name) }}
        className={classes.avatar}
      >
        {stringAvatar(name)}
      </Avatar>
      <span className={classes.name}>{name}</span>
      <span className={classes.email}>{email}</span>
    </button>
  );
}
export default ProjectMember;
