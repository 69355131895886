import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  emojiIcon: {
    cursor: 'pointer',
    padding: '2px',
    fontSize: '20px',
    width: '26.75px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  continer: {
    display: 'flex',
    width: '100%',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
  },
}));

export default useStyles;
