import React, { useState, memo, useMemo } from 'react';
import { usePathVariable } from 'src/common/customHooks';
import { ScheduleContext } from './ScheduleContext';

function ScheduleContextProvider({ children } :{ children: JSX.Element }) {
  const [targetDate, setTargetDate] = useState(new Date());
  const [showEventEditForm, setShowEventEditForm] = useState(false);
  const [listLatestUpdatedTime, setlistLatestUpdatedTime] = useState(new Date());
  const { companyId, projectId } = usePathVariable();

  const scheduleContext = useMemo(
    () => ({ companyId, projectId, targetDate, setTargetDate, showEventEditForm, setShowEventEditForm, listLatestUpdatedTime, setlistLatestUpdatedTime }),
    [companyId, projectId, targetDate, setTargetDate, showEventEditForm, setShowEventEditForm, listLatestUpdatedTime, setlistLatestUpdatedTime],
  );

  return (
    <ScheduleContext.Provider value={scheduleContext}>
      {children}
    </ScheduleContext.Provider>
  );
}

export default memo(ScheduleContextProvider);
