/* eslint-disable react/jsx-props-no-spreading,no-restricted-syntax */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Footer from 'src/components/Footer/Footer';
import getRolesFromJwt from 'src/common/functions/getRolesFromJwt';
import getProjectIdsFromJwt from 'src/common/functions/getProjectIdsFromJwt';

export function PrivateRoute({ component: Component, ...rest }: any) {
  return (
    <>
      <Route
        {...rest}
        render={() => (
          localStorage.getItem('accessToken')
            ? <Component />
            : <Redirect to="/login" />)}
      />
      <Footer isPublicPage={false} />
    </>
  );
}

export function PublicRoute({ component: Component, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={() => (
        localStorage.getItem('accessToken')
          ? <Redirect to={`/companies/${localStorage.getItem('companyId')}`} />
          : <Component />
      )}
    />
  );
}

export function FirstLoginRoute({ component: Component, ...rest }: any) {
  return (
    <>
      <Route
        {...rest}
        render={() => {
          if (localStorage.getItem('accessToken')) {
            return localStorage.getItem('companyId')
              ? <Redirect to="/" />
              : <Component />;
          }
          return <Redirect to="/login" />;
        }}
      />
      <Footer isPublicPage={false} />
    </>
  );
}

export function AuthRoute({ component: Component, roles, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={
      ({ match }) => {
        const userRoles = getRolesFromJwt();
        const projectIds = getProjectIdsFromJwt();
        const { projectId, companyId } = match.params;
        for (const role of roles) {
          // eslint-disable-next-line default-case
          switch (role) {
            case 'Company Manager':
              if (userRoles.includes(`Company Manager,${companyId}`)) {
                return <Component />;
              }
              break;
            case 'Project Manager':
              if (userRoles.includes(`Project Manager,${projectId}`)) {
                return <Component />;
              }
              break;
            case 'Project Member':
              if (projectIds.includes(projectId as string)) {
                return <Component />;
              }
              break;
          }
        }
        return <Redirect to="/forbidden" />;
      }
    }
    />
  );
}
