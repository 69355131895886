const formConfig = {
  emailInput: {
    name: 'email',
    variant: 'outlined',
    margin: 'normal',
    fullWidth: true,
    id: 'email',
    autoFocus: true,
    autoComplete: 'email',
    placeholder: 'name@asyncworking.com',
  },
};

export default formConfig;
