/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { darkBlue, white } from 'src/app/color';

const useStyles = makeStyles(() =>
  createStyles({
    popup_container_display: {
      top: '80px',
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      zIndex: 10,
      position: 'fixed',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(196, 196, 196, 0.26)',
    },
    content_hidden: {
      display: 'none',
    },
    popup: {
      width: '33rem',
      background: white,
      boxShadow: '0 5px 20px rgb(0 0 0 / 35%)',
      padding: '0rem 0rem 2rem 0rem',
      borderRadius: '1rem',
    },
    top_card: {
      border: 'none',
    },
    bottom_card: {
      border: 'none',
    },
    top_card_content: {
      display: 'inline-block',
      padding: 5,
      margin: '10px 0px 0px 10px',
      fontSize: '24px',
      fontFamily: 'Poppins-Medium',
      color: darkBlue,
      fontWeight: 600,
      letterSpacing: '-0.48px',
    },
    top_card_close: {
      float: 'right',
      marginRight: '15px',
      color: 'grey',
      fontSize: '18px !important',
      marginTop: 5,
    },
    bottom_card_title: {
      display: 'flex',
      justifyContent: 'center',
    },
    bottom_card_name: {
      display: 'flex',
      justifyContent: 'center',
      margin: '10px 30px 0px 30px',
      borderBottom: 'grey',
    },
    submit_button: {
      float: 'right',
      width: 150,
      height: 35,
      borderRadius: '30px',
      margin: '30px 33px 0px 0px',
      fontFamily: 'Poppins-Regular',
      fontSize: '16px',
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: white,
      textTransform: 'none',
    },
    company_name: {
      fontFamily: 'Poppins-Medium',
      color: darkBlue,
      fontWeight: 600,
      letterSpacing: '-0.48px',
    },
  }),
);

export default useStyles;
