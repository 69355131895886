import React, { useContext, useMemo, useState } from 'react';
import ITodoList from 'src/common/interfaces/Dto/Todo/TodoList/ITodoList';
import { TodoContext } from 'src/context/TodoContext';
import TodoListHeader from '../TodoListHeader/TodoListHeader';
import TodoItem from '../TodoItem/TodoItem';
import useStyles from './TodoList.style';
import compareTodoItems from '../../functions/compareTodoItems';

interface IProps {
  todoList: ITodoList;
  sort: { [key: string]: string };
}

function TodoList({ todoList, sort }: IProps) {
  const classes = useStyles();
  const { todoItems } = todoList;
  const [ifDisplayTodoItems, setIfDisplayTodoItems] = useState(true);

  const { projectMembers } = useContext(TodoContext);
  const sortedTodoItems = useMemo(
    () => todoItems.sort((a, b) => compareTodoItems(a, b, sort.by, sort.direction, projectMembers)),
    [todoItems, sort.by, sort.direction, projectMembers],
  );

  return (
    <div className={classes.todoList}>
      <TodoListHeader
        todoList={todoList}
        ifDisplayTodoItems={ifDisplayTodoItems}
        setIfDisplayTodoItems={setIfDisplayTodoItems}
      />

      {ifDisplayTodoItems && (
        <div className={classes.todoItems}>
          {sortedTodoItems.map((todoItem) => (
            <TodoItem key={todoItem.todoItemId} todoItem={todoItem} />
          ))}
        </div>
      )}
    </div>
  );
}
export default TodoList;
