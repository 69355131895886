import React, { createContext, useState, useMemo } from 'react';
import { useFetch, usePathVariable } from 'src/common/customHooks';
import { fetchTodoLists, fetchProjectMemberList, getProjectInfo } from 'src/utils/apiUtils';
import ITodoList from 'src/common/interfaces/Dto/Todo/TodoList/ITodoList';
import ITodoItem from 'src/common/interfaces/Dto/Todo/TodoItem/ITodoItem';
import IProjectMember from 'src/common/interfaces/IProjectMember';
import IProjectInfo from 'src/common/interfaces/IProjectInfo';

interface ITodoContext {
  companyId: string
  projectId: string
  projectInfo: IProjectInfo
  todoLists: ITodoList[]
  isAddingTodoItem: boolean
  setTodoLists: React.Dispatch<React.SetStateAction<ITodoList[]>>
  setUpdate: React.Dispatch<React.SetStateAction<number>>
  setAddingTodoItem: React.Dispatch<React.SetStateAction<boolean>>
  addTodoItem:(todoListId: string) => void
  projectMembers: IProjectMember[]
  loading: boolean
}

const initialTodoItemValue:ITodoItem = {
  description: '',
  priority: 'Medium',
  subscribersIds: '',
  dueDate: '',
  notes: '',
  completed: false,
  projectId: '',
  companyId: '',
  todoItemId: 'new',
  createdTime: '',
  todoListId: '',
};

export const TodoContext = createContext({} as ITodoContext);

export function TodoContextProvider({ children } :{ children: JSX.Element }) {
  const [update, setUpdate] = useState(0);
  const [isAddingTodoItem, setAddingTodoItem] = useState(false);
  const { companyId, projectId } = usePathVariable();
  const { data: todoLists, setData: setTodoLists, loading } = useFetch(fetchTodoLists, [companyId, projectId, 50], [] as ITodoList[], [update]);

  const { data: projectMembers } = useFetch(fetchProjectMemberList, [companyId, projectId], [] as IProjectMember[], [companyId, projectId]);
  const { data: projectInfo } = useFetch(getProjectInfo, [companyId, projectId], {} as IProjectInfo);

  const addTodoItem = (todoListId: string) => {
    setAddingTodoItem(true);
    const newTodoItem = { ...initialTodoItemValue, projectId, companyId, todoListId };
    todoLists.forEach(({ id, todoItems }, index) => {
      if (todoListId === id) {
        todoLists[index].todoItems = [newTodoItem, ...todoItems];
      }
    });
    const newTodoLists = [...todoLists];
    setTodoLists(newTodoLists);
  };

  const todoContext = useMemo(
    () => ({ companyId, projectId, todoLists, setTodoLists, addTodoItem, setUpdate, isAddingTodoItem, setAddingTodoItem, loading, projectMembers, projectInfo }),
    [companyId, projectId, todoLists, setTodoLists, addTodoItem, setUpdate, isAddingTodoItem, setAddingTodoItem, loading, projectMembers, projectInfo],
  );

  return (
    <TodoContext.Provider value={todoContext}>
      {children}
    </TodoContext.Provider>
  );
}
