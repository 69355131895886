import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fetchMessageList } from 'src/utils/apiUtils';
import IMessageItem from 'src/common/interfaces/IMessageItem';
import { useFetch } from 'src/common/customHooks';
import Loading from 'src/components/Loading/Loading';
import { IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddNewMessage from './components/AddNewMessage/AddNewMessage';
import useStyles from './MessageList.style';
import MessageHeader from './components/MessageHeader/MessageHeader';
import MessageItem from './components/MessageItem/MessageItem';

interface Props {
  projectId: string;
  companyId: string;
  isProjectMember: boolean;
  isCompanyManager: boolean;
}

const sortByPostTime = (messageList: IMessageItem[]) => messageList.sort((a, b) => Date.parse(b.postTime) - Date.parse(a.postTime));

function MessageList({ projectId, companyId, isProjectMember, isCompanyManager }: Props) {
  const history = useHistory();
  const classes = useStyles();
  const [addNewMessage, setAddNewMessage] = useState(false);
  const { data: messageList, loading } = useFetch(
    fetchMessageList,
    [companyId, projectId],
    [] as IMessageItem[],
    [companyId, projectId, history, isProjectMember],
  );

  const sortedMessageList = useMemo(() => sortByPostTime(messageList), [messageList]);

  return (
    <div className={classes.messageList}>
      <MessageHeader />
      <div className={classes.newMessageSection}>
        <Typography
          className={classes.newMessageText}
          component="span"
          noWrap
        >
          New Message
        </Typography>
        {isProjectMember || isCompanyManager ? (
          <IconButton
            className={classes.newMessageButton}
            size="small"
            onClick={() => {
              setAddNewMessage(true);
            }}
            data-testid="btn-add-item"
            disabled={addNewMessage}
          >
            <AddIcon />
          </IconButton>
        ) : null}
      </div>
      {loading ? <Loading />
        : (
          <div className={classes.messageItems}>
            {addNewMessage ? <AddNewMessage /> : null}
            {sortedMessageList.map((item) => (
              <MessageItem
                key={item.id}
                messageCategoryEmoji={item.messageCategoryEmoji}
                messageCategoryName={item.messageCategoryName}
                messageTitle={item.messageTitle}
                content={item.content}
                posterUser={item.posterUser}
                postTime={item.postTime}
              />
            ))}
          </div>
        )}
    </div>
  );
}

export default MessageList;
