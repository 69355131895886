import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  fab: {
    width: '30px',
    height: '30px',
    minHeight: 'initial',
    backgroundColor: 'transparent',
    border: '1px dashed #a3aed0',
    boxShadow: 'none',
    textAlign: 'right',
  },
  assignees: {
    display: 'flex',
    justifyContent: 'left',
    position: 'relative',
  },
  assigneesBtn: {
    backgroundColor: 'transparent',
    border: 'transparent',
    cursor: 'pointer',
  },
  assigneesWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: '30px',
    height: '30px',
    flexGrow: 0,
    fontSize: '14px',
    fontFamily: 'Poppins-medium',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2,
    letterSpacing: '-0.28px',
  },
  add: {
    width: '20px',
    color: '#a3aed0',
    boxShadow: '0',
    textAlign: 'right',
  },
}));

export default useStyles;
